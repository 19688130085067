import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LoginRequest, PasswordResetRequest } from 'src/app/models/global.request';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { login } from 'src/app/stores/auth/authentication.actions';
import { AuthState } from 'src/app/stores/auth/authentication.reducer';
import { ROUTES } from 'src/app/utils/constants';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

  authState$: Observable<AuthState>;

  userId: number = 0;
  token: string = '';

  confirmPasswordForm = this.fb.group({
    password: ["", [Validators.required]],
    confirmPassword: ["", [Validators.required]],
  }, { validator: this.checkPasswords });

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private store: Store<{ authState: any }>
  ) {
    this.authState$ = store.select('authState');
  }

  ngOnInit() {
    this.authState$.subscribe(authS => {
      if (authS.isAuthenticated) {
        this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
      } else {
        this.route.queryParams.subscribe(params => {
          if (params.token && params.id) {
            this.token = params.token;
            this.userId = Number(params.id);
          } else {
            this.router.navigate([ROUTES.ROUTES_REQUEST_PASSWORD_RESET]);
            this.toastr.error("Token and/or ID missing", "Password reset");
          }
        });
      }
    })
  }

  checkPasswords(group: FormGroup) {
    const password = group.controls.password.value;
    const confirmPassword = group.controls.confirmPassword.value;

    return password === confirmPassword ? null : { notSame: true }
  }

  onSubmitConfirmPassword() {
    const password = this.confirmPasswordForm.value.password || "";
    const confirmPassword = this.confirmPasswordForm.value.confirmPassword || "";

    if(password !== confirmPassword) {
      this.toastr.error("Passwords are different", "Validation");
    }else{
      const passwordResetData = new PasswordResetRequest(this.userId, this.token, password);
      this.authService.passwordReset(passwordResetData).subscribe(data => {
        if (data.message === 'success') {
          this.toastr.success("Password changed", "Success");

          if (data.cred?.email) {
            const signInData = new LoginRequest(data.cred?.email, password)
            this.store.dispatch(login({ request: signInData }));
          } else {
            this.router.navigate([ROUTES.ROUTES_LOGIN]);
          }
        } else {
          this.toastr.error(data.error, "Error");
          this.router.navigate([ROUTES.ROUTES_REQUEST_PASSWORD_RESET]);
        }
      });
    }
  }

}
