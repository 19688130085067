export class Period {
  public id: number;
  public startDate: Date;
  public endDate: Date;

  constructor (id: number, startDate: Date, endDate: Date) {
    this.id = id;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
