import { Component } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  bgColor: string | undefined;

  constructor(private router: Router) {

  }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.changeHeaderTheme(event.url);
      }
    });

  }

  changeHeaderTheme( currentUrl : string) {
    switch (currentUrl)
    {
      case "/select-survey":
        this.bgColor = 'bg-default';
        break;
      case "/create-survey":
        this.bgColor = 'bg-purple';
        break;
      case "/select-survey":
          this.bgColor = 'bg-purple';
          break;
      case "/create-ecommerce":
        this.bgColor = 'bg-purple';
        break;
      default:
        this.bgColor = 'bg-default';
        break;
    }
}

}
