import { User } from '../../models/User';

import { CreateSurveyRequest, DeleteSurveyRequest, GetSurveyListRequest, GetSurveyRequest} from '../../models/global.request';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AuthState, getIsAuthenticated } from '../../stores/auth/authentication.reducer';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  authState$: Observable<AuthState>;
  isAuthenticated !: boolean;
  reqHeader!:HttpHeaders;
  private serviceContainer = "survey/";
  constructor(
    private http: HttpClient,
    private store: Store<{ authState: AuthState }>,
    ) {
      this.authState$ = store.select('authState');
  }

  public createSurvey(request: CreateSurveyRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}create`, request);
  }
  public getSurveyById(request: GetSurveyRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}get`, request);
  }

  public getSurveyList( request: GetSurveyListRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}get-list`, request);
  }

  public deleteSurvey(request: DeleteSurveyRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}delete`, request);
  }
}
