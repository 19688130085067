import { CreateSurveyRequest, GetSurveyRequest, LoginRequest, RegisterRequest } from './../../models/global.request';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  createSurvey,
  createSurveySuccess,
  createSurveyFailure,
  getSurveyById,
  getSurveyByIdSuccess,
  getSurveyByIdFailure,
} from './survey.actions';

import { SurveyService } from '../../services/survey/survey.service';

import { ROUTES } from '../../utils/constants'
import  Formatter  from 'src/app/utils/formatters';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class SurveyEffects {

  formatter = new Formatter();

  createSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSurvey),
      map((action) => action.request),
      exhaustMap((request: CreateSurveyRequest)=>
        this.surveyService.createSurvey(request).pipe(
            map((response) => createSurveySuccess({ request, response })),
            catchError((error) => of(createSurveyFailure({ error })))
        )
      )
    )
  );

  createSurveySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSurveySuccess),
        tap((params) => {
          this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
          if (params.request.isEdit) {
            this.toastr.success("Successfully edited", "Edit Survey");
          } else {
            this.toastr.success("Successfully created", "New Survey");
          }
        })
      ),
    { dispatch: false }
  );

  createSurveyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSurveyFailure),
        tap((error) => {
          this.toastr.error("Problem", "Survey not created");
        })
      ),
    { dispatch: false }
  );

  getSurveyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSurveyById),
      map((action) => action.request),
      exhaustMap((req: GetSurveyRequest) =>
        this.surveyService.getSurveyById(req).pipe(
            map((response) => getSurveyByIdSuccess(
              {
              filterQuestions: this.formatter.formatResponseFilterQuestions(response.filter_questions),
              touchpoints: response.questions_by_touchpoints,
              questions: response.survey.questions
              }
              )),
            catchError((error) => of(getSurveyByIdFailure({ error })))
          )
      )
    )
  );

  getSurveyByIdSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSurveyByIdSuccess),
        tap()
      ),
    { dispatch: false }
  );

  getSurveyByIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSurveyByIdFailure),
        tap((error) => console.log("errore", error))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private surveyService: SurveyService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
