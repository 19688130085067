export const ROUTES_DASHBOARD = [
  "/dashboard",
  "/questions-overview",
  "/satisfaction",
  "/polarization",
  "/nps",
  "/aggregation",
  "/text-boxes",
  "/overview",
]
export const ROUTES_HOMES = [
  "/",
  "/login",
  "/register",
  "/reset-password",
  "/confirm-password",
  "/select-survey",
]


export const ROUTES = {
  ROUTES_HOME:"/",
  ROUTES_LOGIN:"/login",
  ROUTES_REQUEST_PASSWORD_RESET:"/reset-password",
  ROUTES_SELECT_SURVEY:"/select-survey",
  ROUTES_SELECT_PERIOD:"/select-period",
  ROUTE_DASHBOARD: "/dashboard",
  ROUTE_QUESTIONS_OVERVIEW: "/questions-overview",
  ROUTE_SATISFACTION: "/satisfaction",
  ROUTE_POLARIZATION: "/polarization",
  ROUTE_NPS: "/nps",
  ROUTE_AGGREGATION: "/aggregation",
  ROUTE_TEXT_BOXES: "/text-boxes",
  ROUTE_EMOTIONAL_OVERVIEW: "/overview",
  ROUTE_CREATE_ECOMMERCE: "/create-ecommerce",
  ROUTE_EDIT_ECOMMERCE: "/edit-ecommerce",
  ROUTE_EDIT_SURVEY: "/edit-survey",
}
