import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { RequestPasswordResetRequest } from 'src/app/models/global.request';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { AuthState } from 'src/app/stores/auth/authentication.reducer';
import { ROUTES } from 'src/app/utils/constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent  {

  authState$: Observable<AuthState>;

  resetPasswordForm = this.fb.group({
    email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$")]],
  });

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private store: Store<{ authState: any }>
  ) {
    this.authState$ = store.select('authState');
  }

  ngOnInit() {
    this.authState$.subscribe(authS => {
      if (authS.isAuthenticated) {
        this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
      }
    })
  }

  onSubmitResetPassword() {
    const email = this.resetPasswordForm.value.email || "";

    if(!this.validateEmail(email)) {
      this.toastr.error("Email not valid", "Validation");
    }else{
      const passwordResetData = new RequestPasswordResetRequest(email)
      this.authService.requestPasswordReset(passwordResetData).subscribe(data => {
        if (data.message === 'success') {
          this.toastr.success("Check your email", "Success");
        } else {
          this.toastr.error(data.error, "Error");
        }
      });
    }
  }

  validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

}
