<div id="reset-password-wrapper">
  <form class="base--form-generic row" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()">
    <div class="form-group col-md-3">
      <label for="email">Account password</label>
      <input type="email"
      email="true"
      class="form-control" formControlName="email" placeholder="Enter email" required autofocus>
    </div>
    <button type="submit" class="base--btn-generic col-md-3" [disabled]="!this.resetPasswordForm.valid">Reset password </button>
  </form>
</div>
