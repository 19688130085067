
import { CreateEcommerceComponent } from './components/create-ecommerce/create-ecommerce.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../services/auth/authorization.guards';

const routes: Routes = [
  {path: 'create-ecommerce', component: CreateEcommerceComponent,
  canActivate: [AuthGuard], data: {
    role: 'admin'
  }},
  {path: 'edit-ecommerce/:id', component: CreateEcommerceComponent,
  canActivate: [AuthGuard], data: {
    role: 'admin'
  }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]

})
export class EcommerceRoutingModule { }
