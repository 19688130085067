import {
  IfStmt
} from "@angular/compiler";
import {
  Answer, AnswerRange
} from "../models/Answer";
import {
  Question
} from "../models/Question";
import {
  Survey
} from "../models/Survey";
import { CloudData } from "angular-tag-cloud-module";
import { Ecommerce } from "../models/Ecommerce";
import { Observable } from "rxjs";

export default class Formatter {


  formatAnswers(data: any) {
      const answers = [];
      for (let index = 0; index < data.length; index++) {
        if (data[index].range_end || data[index].range_start || data[index].x_start || data[index].y_start) {
          answers.push(
            new AnswerRange(
                data[index].answer_id,
                data[index].description,
                data[index].image_path,
                data[index].color,
                data[index]._count_partial,
                data[index].count_perc,
                data[index].range_start,
                data[index].x_start,
                data[index].y_start,
                data[index].range_end,
                data[index].image_path_2,
            ))
        } else {
          answers.push(
              new Answer(
                  data[index].answer_id,
                  data[index].description,
                  data[index].image_path,
                  data[index].color,
                  data[index]._count_partial,
                  data[index].count_perc,
              ))
        }
      }

      return answers
  }

  formatResponseFilterQuestions(data: any) {
      const questions = [];
      for (let index = 0; index < data.length; index++) {
          questions.push(
              new Question(
                  data[index].question_id,
                  data[index].domanda_id,
                  data[index].description,
                  data[index].touch_point,
                  data[index].type,
                  this.formatAnswers(data[index].answers),
                  [],
                  data[index].is_filter_question,
                  data[index].is_question_overview,
                  data[index].is_satisfaction,
                  data[index].is_nps,
                  data[index].is_end_point,
                  data[index].is_emotional,
              ))
      }

      return questions
  }

  formatResponseQuestionsOverview(data: any) {
      const questions: Question[] = [];
      for (let index = 0; index < data.length; index++) {
          if (data[index].question.type !== 'open') {
            if (!questions.find(obj => obj.domanda_id === data[index].question.domanda_id)) {
              questions.push(
                  new Question(
                      data[index].question.question_id,
                      data[index].question.domanda_id,
                      data[index].question.description,
                      data[index].question.touch_point,
                      data[index].question.type,
                      this.formatAnswers(data[index].answers),
                      data[index].counters,
                      data[index].question.is_filter_question,
                      data[index].question.is_question_overview,
                      data[index].question.is_satisfaction,
                      data[index].question.is_nps,
                      data[index].question.is_end_point,
                      data[index].question.is_emotional,
                      data[index].media
                  ))
            }
          }
      }

      questions.sort((ques1, ques2) => (ques1.domanda_id > ques2.domanda_id) ? 1 : ((ques2.domanda_id > ques1.domanda_id) ? -1 : 0));

      return questions
  }


  formatResponseCrossTab(data: any) {
      const crossTab: any = {};
      const crossAnswers: any = [];
      const selectedAnswers: any = [];
      const allTempRows: any = [];

      for (let firstIndex = 0; firstIndex < data.message.length; firstIndex++) {
          for (let secondIndex = 0; secondIndex < data.message[firstIndex].gridPeriod.length; secondIndex++) {
              for (let thirdIndex = 0; thirdIndex < data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers.length; thirdIndex++) {
                  const imagePath = data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers[thirdIndex].cross_question_answer_detail.image_path;
                  const imagePath2 = data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers[thirdIndex].cross_question_answer_detail.image_path_2;
                  const description = data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers[thirdIndex].cross_question_answer_detail.description;
                  const color = data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers[thirdIndex].cross_question_answer_detail.color;
                  const type = data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers[thirdIndex].cross_question_type;
                  if (crossAnswers.filter((answer: any) => {return answer.imagePath === imagePath && answer.description === description && answer.color === color}).length === 0) {
                      crossAnswers.push({
                        imagePath: imagePath,
                        imagePath2: imagePath2,
                        description: description,
                        color: color,
                        type: type,
                      });
                  }
              }
          }
      }

      for (let firstIndex = 0; firstIndex < data.message.length; firstIndex++) {
          for (let secondIndex = 0; secondIndex < data.message[firstIndex].gridPeriod.length; secondIndex++) {
              const imagePath = data.message[firstIndex].gridPeriod[secondIndex].answer_detail.image_path;
              const imagePath2 = data.message[firstIndex].gridPeriod[secondIndex].answer_detail.image_path_2;
              const description = data.message[firstIndex].gridPeriod[secondIndex].answer_detail.description;
              const color = data.message[firstIndex].gridPeriod[secondIndex].answer_detail.color;
              const type = data.message[firstIndex].gridPeriod[secondIndex].current_question_type;

              const rowAnswer: any = {};
              rowAnswer['imagePath'] = imagePath;
              rowAnswer['imagePath2'] = imagePath2;
              rowAnswer['description'] = description;
              rowAnswer['color'] = color;
              rowAnswer['type'] = type;
              const selectedAnswers = [];
              for (let thirdIndex = 0; thirdIndex < data.message[firstIndex].gridPeriod[secondIndex].cross_question_answers.length; thirdIndex++) {
                  selectedAnswers.push({
                      percentCross1: data.message[0].gridPeriod[secondIndex].cross_question_answers[thirdIndex].percent,
                      percentCross2: data.message[1].gridPeriod[secondIndex].cross_question_answers[thirdIndex].percent,

                  })
              }
              rowAnswer['percentuals'] = selectedAnswers;
              let sumPercentCross1 = 0;
              let sumPercentCross2 = 0;
              selectedAnswers.forEach(percent => {
                  sumPercentCross1 += percent.percentCross1;
                  sumPercentCross2 += percent.percentCross2;
              });

              rowAnswer['totalPercentuals'] = {
                  sumPercentCross1: sumPercentCross1,
                  sumPercentCross2: sumPercentCross2,
              };
              allTempRows.push(rowAnswer);
          }
      }

      crossTab['crossAnswers'] = crossAnswers;
      const idsRows = allTempRows.map((answer:any) => answer.imagePath + answer.description + answer.color)
      const allRows = allTempRows.filter(({imagePath, description, color}: any, index: number) => !idsRows.includes(imagePath + description + color, index + 1))

      crossTab['allRows'] = allRows;

      return crossTab;

  }

  formatResponseSatisfaction(data: any): any {
      const answers = data.answers;
      const periodOneAnswers = answers[0].arr_graph;
      const periodTwoAnswers = answers[1].arr_graph;
      const answersCounters = [];
      for (let index = 0; index < periodOneAnswers.length; index++) {
          const countPerc: any = [];
          countPerc.push(periodOneAnswers[index].perc);
          countPerc.push(periodTwoAnswers[index].perc);
          answersCounters.push(
              countPerc
          );
      }
      const averagePerc = [];
      averagePerc.push(answers[0].media_period);
      averagePerc.push(answers[1].media_period);

      return {
          answersCounters: answersCounters,
          averagePerc: averagePerc
      };
  }


  formatResponsePolarization(data: any) {
      const polarizationAnswers: any = {};
      const scores: any = [];

      polarizationAnswers['headersPolarization'] = data.questions_analyzed;

      const questionsTemp = [];
      for (let index = 0; index < data.questions_analyzed.length; index++) {
        const item = {
          id: data.questions_analyzed[index][0],
          text: data.questions_analyzed[index][1]
        }
        questionsTemp.push(item);
      }
      questionsTemp.sort((obj_a: any, obj_b: any) => (obj_a.id > obj_b.id ? 1 : -1));
      polarizationAnswers['headersPolarization'] = questionsTemp;

      for (let index = 0; index < data.answers_filled.length; index++) {
          const answersTemp = [];
          for (const key in data.answers_filled[index].answers) {
              const item = {
                  question: key,
                  question_type: data.answers_filled[index].answers[key].tipologia[2].type,
                  risposta: data.answers_filled[index].answers[key].polarization.risposta,
                  testo_risposta: data.answers_filled[index].answers[key].polarization.testo_risposta,
                  image_path: data.answers_filled[index].answers[key].tipologia[2].image_path,
                  image_path_2: data.answers_filled[index].answers[key].tipologia[2].image_path_2,
              }
              answersTemp.push(item)
          }

          answersTemp.sort((obj_a: any, obj_b: any) => (parseInt(obj_a.question) > parseInt(obj_b.question) ? 1 : -1));
          scores.push({
              score: data.answers_filled[index].score,
              answers: answersTemp,
          })
      }
      polarizationAnswers['answersFilled'] = scores;

      return polarizationAnswers;
  }

  formatResponseNPS(data: any) {
      const npsAnswers: any = {};
      const answers: any[] = [];
      for (let index = 0; index < data.data[0].score_perc_period.length; index++) {
          answers.push([data.data[0].score_perc_period[index], data.data[1].score_perc_period[index]])

      }

      npsAnswers['data'] = data.data;
      npsAnswers['answers'] = answers;

      return npsAnswers;
  }
  formatResponseOpenTextBoxes(data: any) {

      return data.answers || [];
  }
  formatResponseGetSurveyList(data: any) {
      const surveyList = [];

      for (let index = 0; index < data.length; index++) {
          surveyList.push(
              new Survey(
                  data[index].survey_id,
                  data[index].name,
                  data[index].custom_name,
                  data[index].description,
                  new Date(data[index].createdAt),
                  data[index].image_base64 + "",
                  false,
                  data[index].is_new,
              ))
      }

      return surveyList;
  }
  formatResponseAnalysisByAggregation(data: any) {
      const aggregationData: any = {};
      const touchpoints: any[] = [];
      const period1Values: any[] = [];
      const period2Values: any[] = [];
      for (let index = 0; index < data.allTouchpointsData.length; index++) {
          touchpoints.push(data.allTouchpointsData[index]?.touchpoint)
          period1Values.push((data.allTouchpointsData[index]?.colorsCounterObj?.colorsPerf?.performanceNormalizzata[0]).toFixed(2))
          period2Values.push((data.allTouchpointsData[index]?.colorsCounterObj?.colorsPerf?.performanceNormalizzata[1]).toFixed(2))
      }
      aggregationData['touchpoints'] = touchpoints;
      aggregationData['period1Values'] = period1Values;
      aggregationData['period2Values'] = period2Values;

      return aggregationData;
  }

  formatWordCloud(data: any) {
    const words: CloudData[] = []
    Object.keys(data).forEach((key) => {
      words.push({text: key, weight: data[key]});
    });

    return words;
  }

  formatResponseEmotionalMeter(data: any) {
    const emotionalMeter: any[] = [];
    for (const [answer, value] of Object.entries(data[0].emotionalAnswers)) {
      emotionalMeter.push({
        period1: isNaN(parseInt(data[0].emotionalAnswers[answer].count) / parseInt(data[0].total)) ? 0 : ((parseInt(data[0].emotionalAnswers[answer].count) / parseInt(data[0].total)) * 100).toFixed(2),
        period2: isNaN(parseInt(data[1].emotionalAnswers[answer].count) / parseInt(data[1].total)) ? 0 : ((parseInt(data[1].emotionalAnswers[answer].count) / parseInt(data[1].total)) * 100).toFixed(2),
        image: answer,
        color: data[0].emotionalAnswers[answer].color,
      });
    }

    return emotionalMeter;
  }

  formatResponseGetEcommerceList(data: any) {
    const ecommerceList = [];

    for (let index = 0; index < data.length; index++) {
      const config = JSON.parse(data[index].config_json);

      const triggers = [];
      for (let index = 0; index < config.triggers.length; index++) {
        triggers.push({
          url: config.triggers[index].url,
          delay: config.triggers[index].value,
          showInSubpages: config.triggers[index].showInSubpages ? config.triggers[index].showInSubpages : false,
        });
      }

      ecommerceList.push(
        new Ecommerce(
          data[index].config_id,
          data[index].users,
          data[index].user_proj,
          triggers,
          config.ecommerceType,
          config.surveyLink,
          `https://epathos.fra1.cdn.digitaloceanspaces.com/${data[index].hash_file}.desktop.jpg`,
          `https://epathos.fra1.cdn.digitaloceanspaces.com/${data[index].hash_file}.mobile.jpg`,
          config.surveyDimension,
          config.backgroundColor,
          config.closeColor,
          config.maxDisplay,
          data[index].hash_file,
          data[index].createdAt,
          data[index].stat,
        )
      )
    }

    return ecommerceList;
  }

  async formatResponseGetEcommerce(data: any) {
    const config = JSON.parse(data.config_json);

    const triggers = [];
    for (let index = 0; index < config.triggers.length; index++) {
      triggers.push({
        url: config.triggers[index].url,
        delay: config.triggers[index].value,
        showInSubpages: config.triggers[index].showInSubpages ? config.triggers[index].showInSubpages : false,
      });
    }

    return new Ecommerce(
      data.config_id,
      data.users,
      data.user_proj,
      triggers,
      config.ecommerceType,
      config.surveyLink,
      (await this.getBlobFromUrl(`https://epathos.fra1.cdn.digitaloceanspaces.com/${data.hash_file}.desktop.jpg`)),
      (await this.getBlobFromUrl(`https://epathos.fra1.cdn.digitaloceanspaces.com/${data.hash_file}.mobile.jpg`)),
      config.surveyDimension,
      config.backgroundColor,
      config.closeColor,
      config.maxDisplay,
      data.hash_file,
      data.createdAt,
      data.stat,
    );
  }


  async getBlobFromUrl(url: string) {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };
        reader.onload = () => {
          let base64data = reader.result as string;
          base64data = base64data?.replace('application/octet-stream', 'image/jpg');
          resolve(base64data);
        }
        reader.readAsDataURL(blob);
      });
    } catch (err) {
      console.error(err);

      return url;
    }
  }

}
