export class Survey {
  public id: number;
  public name: string;
  public customName: string;
  public description: string;
  public publicationDate: Date;
  public urlImage: string;
  public isSelected: boolean;
  public isNew: boolean;

  constructor (id: number, name: string,customName: string, description: string, publicationDate: Date, urlImage: string , isSelected: boolean, isNew: boolean) {
    this.id = id;
    this.name = name;
    this.customName = customName;
    this.description = description;
    this.publicationDate = publicationDate;
    this.urlImage = urlImage;
    this.isSelected = isSelected;
    this.isNew = isNew;
  }
}
