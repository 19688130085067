<app-header>
</app-header>
<div class="d-flex" id="wrapper">
  <!-- Sidebar -->
  <app-sidebar>
  </app-sidebar>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <app-navbar>
    </app-navbar>
    <div class="container-fluid" style="margin-top: 110px">
        <app-spinner></app-spinner>
        <router-outlet></router-outlet>
    </div>
  </div>
  <!-- /#page-content-wrapper -->
</div>
<!-- /#wrapper -->
