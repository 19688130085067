
import { 
    Component, 
    OnInit 
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-tabella-domande',
  templateUrl: './tabella-domande.component.html',
  styleUrls: ['./tabella-domande.component.scss'],
  providers: [MessageService]
})
export class TabellaDomandeComponent implements OnInit {
  version = packageJson.version;

  questions: any[] = [];

  constructor( 
    private _dashboardService:DashboardService
) {

  }

    ngOnInit(): void {
    this._dashboardService.currentQuestions.subscribe( data => {
      this.questions = data;
    });
  }

  onClose(){
    this._dashboardService.updateVisibilityQuestions(false);
  }
}
