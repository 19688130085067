<div *ngIf="(sidebarState$ | async); let sidebarState;">
  <div id="create-ecommerce">
    <span class="step-subtitle">{{sidebarState?.currentCreateEcommerceStep?.subtitle}}</span>
    <ng-container [ngSwitch]="sidebarState?.currentCreateEcommerceStep?.id">
      <ng-container *ngSwitchCase="'0'">
        <form class="base--form-generic" [formGroup]="stepZeroForm" (ngSubmit)="onSubmitNextStep(0)">
          <div class="form-group">
            <ng-container *ngIf="isEdit; else newName">
              <span class="text"><strong>Nome progetto: </strong>{{ stepZeroForm.value.newName }}</span>
            </ng-container>
            <ng-template #newName>
              <div class="error-msg-wrapper">
                <input type="text" formControlName="newName" class="size-medium" placeholder="Inserisci nome progetto" required>
                <div class="error-msg" *ngIf="!isUserProjectValid.valid">
                  {{isUserProjectValid.error_msg}}
                </div>
              </div>
            </ng-template>
          </div>

          <div formArrayName="users">
            <div class="form-group horizontal new-element-group" *ngFor="let user of users.controls; let i = index">
              <div [formGroupName]="i" class="form-group horizontal">
                <select formControlName="userId" required>
                  <option [defaultSelected]="true" value="">Scegli il cliente dalla lista</option>
                  <option *ngFor="let user of existingUsers" [value]="user.user_id">{{ user.name ? user.name + ' ' + user.surname : user.email }}</option>
                </select>
                <span class="text">oppure</span>
                <button type="button" class="base--btn-type-a bg-azure" (click)="onClickAddNewUser()" [disabled]="isVisibleAddNewUser">
                  <span class="icon left">+</span>aggiungi nuovo utente
                </button>

                <div class="form-group" style="width: 100%" *ngIf="isVisibleAddNewUser">
                  <label class="group-label">Informazioni cliente</label>
                  <div formGroupName="newUser">
                    <div class="form-group horizontal">
                      <label>Credenziali</label>
                      <div class="error-msg-wrapper">
                        <input type="email" formControlName="newMail" placeholder="Inserisci email" required email>
                        <div *ngIf="emailAlreadyExists" class="error-msg">Email già registrata</div>
                      </div>
                      <div class="input-wrapper">
                        <input id="newUserPassword" [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" placeholder="Inserisci password" autocomplete="new-password" required>
                        <div class="input-icon"  (click)='showHidePassword()'>
                          <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8263 5.81072C16.1987 6.29048 16.1987 6.93816 15.8263 7.41715C14.6532 8.9253 11.8388 12.0306 8.55292 12.0306C5.26706 12.0306 2.45263 8.9253 1.27956 7.41715C1.09836 7.18743 1 6.90482 1 6.61393C1 6.32304 1.09836 6.04044 1.27956 5.81072C2.45263 4.30256 5.26706 1.19727 8.55292 1.19727C11.8388 1.19727 14.6532 4.30256 15.8263 5.81072V5.81072Z" stroke="#A8AAB5" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.55294 8.93583C9.85476 8.93583 10.9101 7.89649 10.9101 6.6144C10.9101 5.33231 9.85476 4.29297 8.55294 4.29297C7.25113 4.29297 6.1958 5.33231 6.1958 6.6144C6.1958 7.89649 7.25113 8.93583 8.55294 8.93583Z" stroke="#A8AAB5" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <ng-container *ngIf="users.at(i).get('newUser')?.get('newPassword')?.dirty && users.at(i).get('newUser')?.get('newPassword')?.invalid">
                          <div class="error-msg-wrapper">
                            <div class="error-msg">
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('^(?=.*[A-Z])')">At least one uppercase letter.<br></span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('(?=.*[a-z])')">At least one lowercase letter.<br></span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('(.*[0-9].*)')">At least one digit.<br></span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('.{8,}')">At least 8 characters long.</span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="cancel-element" (click)="onClickCancelUser()">cancel</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn-next" [ngClass]="[!isStepZeroValid() ? 'invalid' : '']" [disabled]="!isStepZeroValid()">Avanti</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="'1'">
        <form class="base--form-generic" [formGroup]="stepOneForm" (ngSubmit)="onSubmitNextStep(1)">
          <div class="form-group">
            <label class="group-label">Seleziona il tipo di ecommerce</label>
            <select formControlName="ecommerceType" required>
              <option value="" disabled>Scegli la tipologia dalla lista</option>
              <option value="Prestashop">Prestashop</option>
              <option value="Woocommerce">Woocommerce</option>
              <option value="Shopify">Shopify</option>
              <option value="Custom">Custom</option>
            </select>
          </div>

          <div class="separator fadeIn"></div>

          <div formArrayName="triggers">
            <div class="form-group horizontal new-element-group" *ngFor="let trigger of triggers.controls; let i = index">
              <div [formGroupName]="i" class="form-group horizontal">
                <input type="url" formControlName="url" class="size-medium" placeholder="Inserisci il link del sito web" required>
                <div class="separator-vertical"></div>
                <div class="form-group horizontal">
                  <label style="margin-right: 0;">Ritardo apparizione popup</label>
                  <input type="number" formControlName="delay" class="size-small" required>
                  <span class="input-unit">sec</span>
                </div>
                <div *ngIf="i !== 0" class="cancel-element" style="margin-left: 50px;" (click)="onClickCancelUrl(i)">cancel</div>
                <label class="checkbox-wrapper">
                  <input type="checkbox" formControlName="showInSubpages">
                  <span>Mostra il modulo nelle sottopagine</span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group horizontal">
            <button type="button" class="base--btn-type-a bg-azure" (click)="onClickAddNewUrl()" [disabled]="!triggers.valid">
              <span class="icon left">+</span>aggiungi nuovo link
            </button>
          </div>

          <div class="form-actions">
            <div (click)="onSubmitBackStep(1)" class="btn-back">Indietro</div>
            <button type="submit" class="btn-next" [ngClass]="[(!stepOneForm.valid) ? 'invalid' : '']" [disabled]="!stepOneForm.valid">Avanti</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="'2'">
        <form class="base--form-generic" [formGroup]="stepTwoForm" (ngSubmit)="onSubmitNextStep(2)">
          <div class="row form-group horizontal">
            <div class="col-md-8">
              <input type="url" formControlName="surveyLink" class="size-large" placeholder="Inserisci il link della survey" url required>
            </div>
            <div class="col-md-3">
              <button type="button" class="base--btn-type-a bg-azure" (click)="validSurveyLink = true" [disabled]="!stepTwoForm.controls.surveyLink.valid">conferma link survey</button>
            </div>
            <div class="col-md-12">
              <div class="form-group horizontal">
                <input type="number" formControlName="maxDisplay" class="size-small">
                <label>Max numero di apparizioni</label>
              </div>
            </div>
          </div>

          <div *ngIf="validSurveyLink" class="row form-group horizontal module-appearance">
            <div class="col-12 preview-cover">
              <div class="form-group horizontal upload-file">
                <label>Cover desktop</label>
                <label for="cover-image-desktop" class="input-file-wrapper">
                  <div class="input-wrapper">
                    <input type="file" id="cover-image-desktop" (change)="onFileChange($event, 'desktop')" accept=".jpg, .jpeg, .png" required>
                    <div class="button">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.41514 1.87044C9.30858 1.75389 9.15793 1.6875 9 1.6875C8.84208 1.6875 8.69142 1.75389 8.58486 1.87044L5.58486 5.15169C5.37524 5.38097 5.39117 5.73677 5.62044 5.94639C5.84972 6.15602 6.20552 6.14008 6.41514 5.91081L8.4375 3.69885V12C8.4375 12.3107 8.68934 12.5625 9 12.5625C9.31066 12.5625 9.5625 12.3107 9.5625 12V3.69885L11.5849 5.91081C11.7945 6.14008 12.1503 6.15602 12.3796 5.94639C12.6088 5.73677 12.6248 5.38097 12.4151 5.15169L9.41514 1.87044Z" fill="#374957"/>
                        <path d="M2.8125 11.25C2.8125 10.9393 2.56066 10.6875 2.25 10.6875C1.93934 10.6875 1.6875 10.9393 1.6875 11.25V11.2912C1.68749 12.3168 1.68747 13.1436 1.77489 13.7938C1.86566 14.4689 2.05983 15.0373 2.51126 15.4887C2.9627 15.9402 3.53111 16.1343 4.20619 16.2251C4.85641 16.3125 5.68316 16.3125 6.70885 16.3125H11.2912C12.3168 16.3125 13.1436 16.3125 13.7938 16.2251C14.4689 16.1343 15.0373 15.9402 15.4887 15.4887C15.9402 15.0373 16.1343 14.4689 16.2251 13.7938C16.3125 13.1436 16.3125 12.3168 16.3125 11.2912V11.25C16.3125 10.9393 16.0607 10.6875 15.75 10.6875C15.4393 10.6875 15.1875 10.9393 15.1875 11.25C15.1875 12.3266 15.1863 13.0774 15.1101 13.6439C15.0361 14.1942 14.9008 14.4857 14.6932 14.6932C14.4857 14.9008 14.1942 15.0361 13.6439 15.1101C13.0774 15.1863 12.3266 15.1875 11.25 15.1875H6.75C5.67344 15.1875 4.9226 15.1863 4.35609 15.1101C3.80576 15.0361 3.51433 14.9008 3.30676 14.6932C3.09919 14.4857 2.96385 14.1942 2.88986 13.6439C2.8137 13.0774 2.8125 12.3266 2.8125 11.25Z" fill="#374957"/>
                      </svg>
                      seleziona file
                    </div>
                  </div>
                </label>
                <span class="filename" [ngClass]="fileNameDesktop ? '' : 'empty'">{{fileNameDesktop ? fileNameDesktop : 'Nessun file selezionato'}}</span>

                <div class="separator-vertical"></div>

                <label>Cover mobile</label>
                <label for="cover-image-mobile" class="input-file-wrapper">
                  <div class="input-wrapper">
                    <input type="file" id="cover-image-mobile" (change)="onFileChange($event, 'mobile')" accept=".jpg, .jpeg, .png" required>
                    <div class="button">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.41514 1.87044C9.30858 1.75389 9.15793 1.6875 9 1.6875C8.84208 1.6875 8.69142 1.75389 8.58486 1.87044L5.58486 5.15169C5.37524 5.38097 5.39117 5.73677 5.62044 5.94639C5.84972 6.15602 6.20552 6.14008 6.41514 5.91081L8.4375 3.69885V12C8.4375 12.3107 8.68934 12.5625 9 12.5625C9.31066 12.5625 9.5625 12.3107 9.5625 12V3.69885L11.5849 5.91081C11.7945 6.14008 12.1503 6.15602 12.3796 5.94639C12.6088 5.73677 12.6248 5.38097 12.4151 5.15169L9.41514 1.87044Z" fill="#374957"/>
                        <path d="M2.8125 11.25C2.8125 10.9393 2.56066 10.6875 2.25 10.6875C1.93934 10.6875 1.6875 10.9393 1.6875 11.25V11.2912C1.68749 12.3168 1.68747 13.1436 1.77489 13.7938C1.86566 14.4689 2.05983 15.0373 2.51126 15.4887C2.9627 15.9402 3.53111 16.1343 4.20619 16.2251C4.85641 16.3125 5.68316 16.3125 6.70885 16.3125H11.2912C12.3168 16.3125 13.1436 16.3125 13.7938 16.2251C14.4689 16.1343 15.0373 15.9402 15.4887 15.4887C15.9402 15.0373 16.1343 14.4689 16.2251 13.7938C16.3125 13.1436 16.3125 12.3168 16.3125 11.2912V11.25C16.3125 10.9393 16.0607 10.6875 15.75 10.6875C15.4393 10.6875 15.1875 10.9393 15.1875 11.25C15.1875 12.3266 15.1863 13.0774 15.1101 13.6439C15.0361 14.1942 14.9008 14.4857 14.6932 14.6932C14.4857 14.9008 14.1942 15.0361 13.6439 15.1101C13.0774 15.1863 12.3266 15.1875 11.25 15.1875H6.75C5.67344 15.1875 4.9226 15.1863 4.35609 15.1101C3.80576 15.0361 3.51433 14.9008 3.30676 14.6932C3.09919 14.4857 2.96385 14.1942 2.88986 13.6439C2.8137 13.0774 2.8125 12.3266 2.8125 11.25Z" fill="#374957"/>
                      </svg>
                      seleziona file
                    </div>
                  </div>
                </label>
                <span class="filename" [ngClass]="fileNameMobile ? '' : 'empty'">{{fileNameMobile ? fileNameMobile : 'Nessun file selezionato'}}</span>
              </div>
            </div>
            <div class="col-md-8">
              <div class="preview-img not-interactive">
                <img *ngIf="previewDevice === 'desktop'" class="site-preview desktop" src="../../../../../assets/images/site-preview-fake.jpg">
                <img *ngIf="previewDevice === 'mobile'" class="site-preview mobile" src="../../../../../assets/images/site-preview-fake-mobile.jpg">

                <ng-container *ngIf="previewDevice === 'desktop' && stepTwoForm.get('coverDesktop')?.valid">
                  <div class="overlay-bg desktop" [ngStyle]="{'background': stepTwoForm.controls.backgroundColor.value ? stepTwoForm.controls.backgroundColor.value : color}">
                    <div class="close-popup">
                      <svg [attr.fill]="stepTwoForm.controls.closeColor.value" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector" d="M12.892 0.302021C12.7995 0.209317 12.6896 0.135769 12.5686 0.085588C12.4477 0.0354065 12.318 0.00957632 12.187 0.00957632C12.0561 0.00957632 11.9264 0.0354065 11.8054 0.085588C11.6844 0.135769 11.5745 0.209317 11.482 0.302021L6.59202 5.18202L1.70202 0.29202C1.60944 0.199438 1.49953 0.125998 1.37856 0.0758934C1.2576 0.0257884 1.12795 9.75509e-10 0.997021 0C0.86609 -9.75509e-10 0.736441 0.0257884 0.615477 0.0758934C0.494513 0.125998 0.384602 0.199438 0.29202 0.29202C0.199438 0.384602 0.125998 0.494513 0.0758934 0.615477C0.0257884 0.736441 -9.75509e-10 0.86609 0 0.997021C9.75509e-10 1.12795 0.0257884 1.2576 0.0758934 1.37856C0.125998 1.49953 0.199438 1.60944 0.29202 1.70202L5.18202 6.59202L0.29202 11.482C0.199438 11.5746 0.125998 11.6845 0.0758934 11.8055C0.0257884 11.9264 0 12.0561 0 12.187C0 12.3179 0.0257884 12.4476 0.0758934 12.5686C0.125998 12.6895 0.199438 12.7994 0.29202 12.892C0.384602 12.9846 0.494513 13.058 0.615477 13.1081C0.736441 13.1583 0.86609 13.184 0.997021 13.184C1.12795 13.184 1.2576 13.1583 1.37856 13.1081C1.49953 13.058 1.60944 12.9846 1.70202 12.892L6.59202 8.00202L11.482 12.892C11.5746 12.9846 11.6845 13.058 11.8055 13.1081C11.9264 13.1583 12.0561 13.184 12.187 13.184C12.3179 13.184 12.4476 13.1583 12.5686 13.1081C12.6895 13.058 12.7994 12.9846 12.892 12.892C12.9846 12.7994 13.058 12.6895 13.1081 12.5686C13.1583 12.4476 13.184 12.3179 13.184 12.187C13.184 12.0561 13.1583 11.9264 13.1081 11.8055C13.058 11.6845 12.9846 11.5746 12.892 11.482L8.00202 6.59202L12.892 1.70202C13.272 1.32202 13.272 0.682021 12.892 0.302021Z"/>
                      </svg>
                    </div>
                    <img class="cover-preview" [ngClass]="stepTwoForm.controls.surveyDimension.value ? stepTwoForm.controls.surveyDimension.value : 'fullscreen'" src="{{ previewImageDesktop }}">
                  </div>
                </ng-container>

                <ng-container *ngIf="previewDevice === 'mobile' && stepTwoForm.get('coverMobile')?.valid">
                  <div class="overlay-bg mobile" [ngStyle]="{'background': stepTwoForm.controls.backgroundColor.value ? stepTwoForm.controls.backgroundColor.value : color}">
                    <div class="close-popup">
                      <svg [attr.fill]="stepTwoForm.controls.closeColor.value" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector" d="M12.892 0.302021C12.7995 0.209317 12.6896 0.135769 12.5686 0.085588C12.4477 0.0354065 12.318 0.00957632 12.187 0.00957632C12.0561 0.00957632 11.9264 0.0354065 11.8054 0.085588C11.6844 0.135769 11.5745 0.209317 11.482 0.302021L6.59202 5.18202L1.70202 0.29202C1.60944 0.199438 1.49953 0.125998 1.37856 0.0758934C1.2576 0.0257884 1.12795 9.75509e-10 0.997021 0C0.86609 -9.75509e-10 0.736441 0.0257884 0.615477 0.0758934C0.494513 0.125998 0.384602 0.199438 0.29202 0.29202C0.199438 0.384602 0.125998 0.494513 0.0758934 0.615477C0.0257884 0.736441 -9.75509e-10 0.86609 0 0.997021C9.75509e-10 1.12795 0.0257884 1.2576 0.0758934 1.37856C0.125998 1.49953 0.199438 1.60944 0.29202 1.70202L5.18202 6.59202L0.29202 11.482C0.199438 11.5746 0.125998 11.6845 0.0758934 11.8055C0.0257884 11.9264 0 12.0561 0 12.187C0 12.3179 0.0257884 12.4476 0.0758934 12.5686C0.125998 12.6895 0.199438 12.7994 0.29202 12.892C0.384602 12.9846 0.494513 13.058 0.615477 13.1081C0.736441 13.1583 0.86609 13.184 0.997021 13.184C1.12795 13.184 1.2576 13.1583 1.37856 13.1081C1.49953 13.058 1.60944 12.9846 1.70202 12.892L6.59202 8.00202L11.482 12.892C11.5746 12.9846 11.6845 13.058 11.8055 13.1081C11.9264 13.1583 12.0561 13.184 12.187 13.184C12.3179 13.184 12.4476 13.1583 12.5686 13.1081C12.6895 13.058 12.7994 12.9846 12.892 12.892C12.9846 12.7994 13.058 12.6895 13.1081 12.5686C13.1583 12.4476 13.184 12.3179 13.184 12.187C13.184 12.0561 13.1583 11.9264 13.1081 11.8055C13.058 11.6845 12.9846 11.5746 12.892 11.482L8.00202 6.59202L12.892 1.70202C13.272 1.32202 13.272 0.682021 12.892 0.302021Z"/>
                      </svg>
                    </div>
                    <img class="cover-preview" [ngClass]="stepTwoForm.controls.surveyDimension.value ? stepTwoForm.controls.surveyDimension.value : 'fullscreen'" src="{{ previewImageMobile }}">
                  </div>
                </ng-container>
              </div>
              <div class="device-selector">
                <div class="form-group horizontal">
                  <label [ngClass]="previewDevice === 'desktop' ? 'selected' : ''">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Linear / Electronic, Devices / Laptop Minimalistic">
                        <path id="Vector" d="M3.22021 9.16076C3.22021 6.36036 3.22021 4.96016 4.09019 4.09019C4.96016 3.22021 6.36036 3.22021 9.16076 3.22021H15.1013C17.9017 3.22021 19.3019 3.22021 20.1719 4.09019C21.0418 4.96016 21.0418 6.36036 21.0418 9.16076V14.1112C21.0418 15.9781 21.0418 16.9116 20.4619 17.4916C19.8819 18.0716 18.9484 18.0716 17.0815 18.0716H7.18057C5.31364 18.0716 4.38018 18.0716 3.8002 17.4916C3.22021 16.9116 3.22021 15.9781 3.22021 14.1112V9.16076Z" stroke="#1C274C" stroke-width="1.28049"/>
                        <path id="Vector_2" d="M22.0313 21.0415H2.22949" stroke="#1C274C" stroke-width="1.28049" stroke-linecap="round"/>
                        <path id="Vector_3" d="M15.1007 15.1006H9.16016" stroke="#1C274C" stroke-width="1.28049" stroke-linecap="round"/>
                      </g>
                    </svg>
                    <input type="radio" value="desktop" name="preview-device" (click)="previewDevice = 'desktop'" checked>
                  </label>
                  <label [ngClass]="previewDevice === 'mobile' ? 'selected' : ''">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Linear / Electronic, Devices / Smartphone">
                        <path id="Vector" d="M3.5 8.75C3.5 5.45017 3.5 3.80025 4.52513 2.77513C5.55025 1.75 7.20017 1.75 10.5 1.75C13.7998 1.75 15.4497 1.75 16.4749 2.77513C17.5 3.80025 17.5 5.45017 17.5 8.75V12.25C17.5 15.5498 17.5 17.1997 16.4749 18.2249C15.4497 19.25 13.7998 19.25 10.5 19.25C7.20017 19.25 5.55025 19.25 4.52513 18.2249C3.5 17.1997 3.5 15.5498 3.5 12.25V8.75Z" stroke="#1C274C" stroke-width="1.28049"/>
                        <path id="Vector_2" d="M13.125 16.6255H7.875" stroke="#1C274C" stroke-width="1.28049" stroke-linecap="round"/>
                      </g>
                    </svg>
                    <input type="radio" value="mobile" name="preview-device" (click)="previewDevice = 'mobile'">
                  </label>
                </div>
                <span>Preview view</span>
              </div>
            </div>

            <div class="col-md-4 appearance-settings">
              <div class="accordion">
                <div class="accordion-btn" [ngClass]="expandDimension ? 'expanded' : ''" (click)="expandDimension = !expandDimension; expandColor = false; expandCloseColor = false;">
                  <div class="title" [ngClass]="selectedDimension ? 'selected' : ''">Dimensione survey</div>
                  <img src="../../../../../assets/images/svg/select-arrow.svg">
                </div>
                <div class="accordion-content" *ngIf="expandDimension">
                  <div class="form-group horizontal radio-group">
                    <label for="fullscreen">
                      <input type="radio" id="fullscreen" value="fullscreen" formControlName="surveyDimension" (click)="selectedDimension = true" required>
                      <div class="custom-radio"></div>
                      <span>Full screen</span>
                    </label>
                    <label for="medium">
                      <input type="radio" id="medium" value="medium" formControlName="surveyDimension" (click)="selectedDimension = true" required>
                      <div class="custom-radio"></div>
                      <span>Medio</span>
                    </label>
                  </div>
                </div>

                <div class="accordion-btn" [ngClass]="expandColor ? 'expanded' : ''" (click)="expandColor = !expandColor; expandDimension = false; expandCloseColor = false;">
                  <div class="title" [ngClass]="selectedColor ? 'selected' : ''">Colore background</div>
                  <img src="../../../../../assets/images/svg/select-arrow.svg">
                </div>
                <div class="accordion-content" *ngIf="expandColor">
                  <input class="color-picker-input"
                    [style.background]="color"
                    [cpToggle]="true"
                    [cpDialogDisplay]="'inline'"
                    [cpAlphaChannel]="'always'"
                    [cpOutputFormat]="'rgba'"
                    [(colorPicker)]="color"
                    (colorPickerChange)="onColorChange($event)"
                    formControlName="backgroundColor">
                </div>


                <div class="accordion-btn" [ngClass]="expandCloseColor ? 'expanded' : ''" (click)="expandCloseColor = !expandCloseColor; expandDimension = false; expandColor = false;">
                  <div class="title" [ngClass]="selectedCloseColor ? 'selected' : ''">Colore chiusura popup</div>
                  <img src="../../../../../assets/images/svg/select-arrow.svg">
                </div>
                <div class="accordion-content" *ngIf="expandCloseColor">
                  <input class="color-picker-input"
                    [style.background]="closeColor"
                    [cpToggle]="true"
                    [cpDialogDisplay]="'inline'"
                    [cpAlphaChannel]="'always'"
                    [cpOutputFormat]="'rgba'"
                    [(colorPicker)]="closeColor"
                    (colorPickerChange)="onCloseColorChange($event)"
                    formControlName="closeColor">
                </div>

              </div>
            </div>
          </div>

          <div class="form-actions">
            <div (click)="onSubmitBackStep(2)" class="btn-back">Indietro</div>
            <button type="submit" class="btn-next" [ngClass]="[(!stepTwoForm.valid) ? 'invalid' : '']" [disabled]="!stepTwoForm.valid">Avanti</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="'3'">
        <form class="base--form-generic" [formGroup]="stepFourForm" (ngSubmit)="onSubmitNextStep(3)">
          <div class="code-editor-container">
            <ngs-code-editor
              style="height: 100%"
              [theme]="codeEditorTheme"
              [codeModel]="codeEditorModel"
              [options]="codeEditorOptions"
            >
            </ngs-code-editor>
            <div class="copy-code" (click)="onClickCopyCode()">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Linear / Essentional, UI / Copy">
                  <path id="Vector" d="M5.14258 9.4285C5.14258 7.00414 5.14258 5.79195 5.89573 5.0388C6.64889 4.28564 7.86107 4.28564 10.2854 4.28564H12.8569C15.2812 4.28564 16.4934 4.28564 17.2466 5.0388C17.9997 5.79195 17.9997 7.00414 17.9997 9.4285V13.7142C17.9997 16.1386 17.9997 17.3508 17.2466 18.1039C16.4934 18.8571 15.2812 18.8571 12.8569 18.8571H10.2854C7.86107 18.8571 6.64889 18.8571 5.89573 18.1039C5.14258 17.3508 5.14258 16.1386 5.14258 13.7142V9.4285Z" stroke="white" stroke-width="1.28571"/>
                  <path id="Vector_2" d="M5.14272 16.2858C3.72256 16.2858 2.57129 15.1345 2.57129 13.7144V8.5715C2.57129 5.33901 2.57129 3.72277 3.57549 2.71856C4.5797 1.71436 6.19594 1.71436 9.42843 1.71436H12.857C14.2772 1.71436 15.4284 2.86562 15.4284 4.28578" stroke="white" stroke-width="1.28571"/>
                </g>
              </svg>
            </div>
          </div>

          <div class="form-group">
            <label class="label-grey">Inserisci la mail e invia il codice generato</label>
            <div class="form-group horizontal">
              <input type="email" formControlName="email" class="size-medium" placeholder="Inserisci la mail" email>
              <button type="button" class="base--btn-type-a bg-light-grey" (click)="onClickSendEmail()" [disabled]="!stepFourForm.controls.email.valid || !stepFourForm.controls.email.value">invia codice</button>
            </div>
            <div *ngIf="emailResult.complete" class="email-result {{emailResult.result}}">
              <div class="icon">
                <svg *ngIf="emailResult.result === 'success'" width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector 1" d="M1 3.22222L3.54545 6L8 1" stroke="#75C478" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <span *ngIf="emailResult.result === 'error'" style="font-size:15px;">×</span>
              </div>
              {{emailResult.message}}
            </div>
          </div>

          <div class="form-group horizontal">
            <button type="button" class="base--btn-type-a bg-azure" (click)="onClickShowPopupPreview()">Anteprima popup</button>

            <div *ngIf="showPopupPreview" class="module-appearance fullscreen">
              <div class="preview-img">
                <div class="overlay-bg" [ngStyle]="{'background': stepTwoForm.controls.backgroundColor.value}">
                  <div class="close-popup" (click)="onClickHidePopupPreview()">
                    <svg width="14" [attr.fill]="stepTwoForm.controls.closeColor.value" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M12.892 0.302021C12.7995 0.209317 12.6896 0.135769 12.5686 0.085588C12.4477 0.0354065 12.318 0.00957632 12.187 0.00957632C12.0561 0.00957632 11.9264 0.0354065 11.8054 0.085588C11.6844 0.135769 11.5745 0.209317 11.482 0.302021L6.59202 5.18202L1.70202 0.29202C1.60944 0.199438 1.49953 0.125998 1.37856 0.0758934C1.2576 0.0257884 1.12795 9.75509e-10 0.997021 0C0.86609 -9.75509e-10 0.736441 0.0257884 0.615477 0.0758934C0.494513 0.125998 0.384602 0.199438 0.29202 0.29202C0.199438 0.384602 0.125998 0.494513 0.0758934 0.615477C0.0257884 0.736441 -9.75509e-10 0.86609 0 0.997021C9.75509e-10 1.12795 0.0257884 1.2576 0.0758934 1.37856C0.125998 1.49953 0.199438 1.60944 0.29202 1.70202L5.18202 6.59202L0.29202 11.482C0.199438 11.5746 0.125998 11.6845 0.0758934 11.8055C0.0257884 11.9264 0 12.0561 0 12.187C0 12.3179 0.0257884 12.4476 0.0758934 12.5686C0.125998 12.6895 0.199438 12.7994 0.29202 12.892C0.384602 12.9846 0.494513 13.058 0.615477 13.1081C0.736441 13.1583 0.86609 13.184 0.997021 13.184C1.12795 13.184 1.2576 13.1583 1.37856 13.1081C1.49953 13.058 1.60944 12.9846 1.70202 12.892L6.59202 8.00202L11.482 12.892C11.5746 12.9846 11.6845 13.058 11.8055 13.1081C11.9264 13.1583 12.0561 13.184 12.187 13.184C12.3179 13.184 12.4476 13.1583 12.5686 13.1081C12.6895 13.058 12.7994 12.9846 12.892 12.892C12.9846 12.7994 13.058 12.6895 13.1081 12.5686C13.1583 12.4476 13.184 12.3179 13.184 12.187C13.184 12.0561 13.1583 11.9264 13.1081 11.8055C13.058 11.6845 12.9846 11.5746 12.892 11.482L8.00202 6.59202L12.892 1.70202C13.272 1.32202 13.272 0.682021 12.892 0.302021Z"/>
                    </svg>
                  </div>
                  <img *ngIf="!showVideo" class="cover-preview" [ngClass]="stepTwoForm.controls.surveyDimension.value" src="{{ previewImageDesktop }}" (click)="showVideo = true;">
                  <iframe *ngIf="showVideo" [ngClass]="stepTwoForm.controls.surveyDimension.value" [src]="stepTwoForm.controls.surveyLink.value | safe"></iframe>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="showAlertConfirmClose" class="alert-modal">
            <div class="body">
              <p>Non hai copiato o inviato il codice via mail.</p>
              <p>Sei sicuro di voler lasciare questa pagina?</p>
            </div>
            <div class="actions">
              <button class="bg-transparent" (click)="onClickClosePage()">Lascia la pagina</button>
              <button class="bg-azure" (click)="showAlertConfirmClose = false">Rimani qui</button>
            </div>
          </div>

          <div class="form-actions">
            <div (click)="onSubmitBackStep(3)" class="btn-back">Indietro</div>
            <button type="submit" class="btn-next">Chiudi</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchDefault> </ng-container>
    </ng-container>
  </div>
</div>
