import { createReducer, on } from "@ngrx/store";
import { Ecommerce } from "src/app/models/Ecommerce";
import { setEcommerceList } from "./ecommerce.actions";

export const initialState: EcommerceState = {
  ecommerceList: [],
};

export interface EcommerceState {
  ecommerceList: Ecommerce[];
}

export const EcommerceReducer = createReducer(
  initialState,
  on(setEcommerceList, (state, { ecommerceList }) => ({
    ...state,
    ecommerceList,
  })),
);
