import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

//States
import { AuthState } from '../../../stores/auth/authentication.reducer';
import { SurveyState } from '../../../stores/survey/survey.reducer';
import { NavbarState } from '../../../stores/navbar/navbar.reducer';
import { SidebarState } from '../../../stores/sidebar/sidebar.reducer';

//Material
import {MatDatepickerModule} from '@angular/material/datepicker';

//Router
import { Router,NavigationEnd  } from '@angular/router';
import { Survey } from 'src/app/models/Survey';
import { CssSelector } from '@angular/compiler';

//Actions
import { switchSelect, toggleMenu } from "../../../stores/navbar/navbar.actions";
import { logout } from "../../../stores/auth/authentication.actions";
import { setPeriods } from '../../../stores/survey/survey.actions';



//Form Builders
import {FormGroup, FormControl} from '@angular/forms';


//Utils
import  Utils  from 'src/app/utils/utils';
import { Period } from 'src/app/models/Period';

//Constants
import { ROUTES, ROUTES_DASHBOARD, ROUTES_HOMES } from '../../../utils/constants'
import { GetSurveyListRequest } from 'src/app/models/global.request';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {


  ROUTES_DASHBOARD = ROUTES_DASHBOARD;

  authState$: Observable<AuthState>;
  surveyState$ : Observable<SurveyState>;
  navbarState$ : Observable<NavbarState>;
  sidebarState$: Observable<SidebarState>;

  pageTitle: string | undefined;
  currentUrl: string | undefined;
  currentSurvey!: Survey | undefined;
  utils!:Utils;
  periodList!: Period[] | null;

  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;

  countSurvey = 0;
  countEcommerce = 0;
  currentUser: any;

  onePeriod = false;

  constructor(
    private surveyService: SurveyService,
    private ecommerceService: EcommerceService,
    private router: Router,
    private store: Store<{authState: AuthState, surveyState: SurveyState , sidebarState: SidebarState, navbarState: NavbarState}>,
  ) {
    this.authState$ = store.select('authState');
    this.sidebarState$ = store.select('sidebarState');
    this.surveyState$ = store.select('surveyState');
    this.navbarState$ = store.select('navbarState');
    this.utils = new Utils();

    this.authState$.subscribe(authS => this.currentUser = authS.user);

  }

  periodOne = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  });

  periodTwo = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  });

  ngOnInit(): void {

    this.surveyState$.subscribe(surveyS => {
      // eslint-disable-next-line no-undefined
      this.currentSurvey = surveyS.currentSurvey || undefined;
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if( period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
          this.periodOne.controls['startDate'].setValue(new Date(period.startDate));
          this.periodOne.controls['endDate'].setValue(new Date(period.endDate));
        }
        if( period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
          this.periodTwo.controls['startDate'].setValue(new Date(period.startDate));
          this.periodTwo.controls['endDate'].setValue(new Date(period.endDate));
        }
      });

      if (this.periodOne.value.startDate?.toISOString() === this.periodTwo.value.startDate?.toISOString() && this.periodOne.value.endDate?.toISOString() === this.periodTwo.value.endDate?.toISOString()) {
        this.onePeriod = true;
      } else {
        this.onePeriod = false;
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.pageTitle = this.getTitlePage(event.url);

        if (this.currentUrl === '/select-survey') {
          const request = {
            user_id: this.currentUser.user_id,
            offset: 0,
            limit: 10,
            order : ['createdAt','DESC'],
          }

          this.surveyService.getSurveyList(request).subscribe((data) => {
            this.countSurvey = data.length;
            if (this.countSurvey === 0) {
              this.store.dispatch(switchSelect({selectContentType: 'ecommerce'}));
            }
          });
          this.ecommerceService.getEcommerceList(request).subscribe((data) => {
            this.countEcommerce = data.length;
            if (this.countEcommerce === 0) {
              this.store.dispatch(switchSelect({selectContentType: 'survey'}));
            }
          });
        }
      }
    });
  }


  onChangeDate(): void {
    this.periodOne.valueChanges.subscribe(selectedDate  => {
        if(selectedDate?.startDate && selectedDate?.endDate) {
          const periodOne = {
            id: 0,
            startDate: selectedDate?.startDate,
            endDate: selectedDate.endDate,
          }
          const periodTwo = {
            id: 1,
            startDate: this.periodTwoStartDate,
            endDate: this.periodTwoEndDate,
          }
          // this.store.dispatch(setPeriods({ periodList: [periodOne, periodTwo]}));
        }
    })

    this.periodTwo.valueChanges.subscribe(selectedDate  => {
        if(selectedDate?.startDate && selectedDate?.endDate) {
          const periodOne = {
            id: 0,
            startDate: this.periodOneStartDate,
            endDate: this.periodOneEndDate,
          }
          const periodTwo = {
            id: 1,
            startDate: selectedDate?.startDate,
            endDate: selectedDate.endDate,
          }
          // this.store.dispatch(setPeriods({ periodList: [periodOne, periodTwo]}));

        }
    })

  }
  getTitlePage(currentUrl: string): string | undefined {
    let titlePage="";
    switch (currentUrl)
      {
        case "/login":
            titlePage = 'Login';
            break;
        case "/register":
            titlePage = 'Register';
            break;
        case "/":
        case "/select-survey":
          titlePage = 'Select survey';
          break;
        case "/create-survey":
          titlePage = 'Create survey';
          break;
        case "/select-period":
            titlePage = 'Select period';
            break;
        case "/import-survey-filled":
              titlePage = 'Upload file';
              break;
        case "/create-ecommerce":
          titlePage = 'Creazione modulo per ecommerce';
          break;
        default:
          titlePage ="";
          break;
      }
      if( ROUTES_DASHBOARD.includes(currentUrl)) {
        titlePage = (this.currentSurvey?.customName? this.currentSurvey?.customName : this.currentSurvey?.name) || "";
      }
      if (currentUrl.includes("/edit-ecommerce")) {
        titlePage = 'Modifica modulo per ecommerce';
      }
      if (currentUrl.includes("/edit-survey")) {
        titlePage = 'Edit survey';
      }

    return titlePage;

  }

  updatePeriodsDates() {
    console.log("periodOne", this.periodOne?.value);
    console.log("periodTwo", this.periodTwo?.value);

    throw new Error('Method not implemented.');
  }

  OnClickLogout() {
    this.store.dispatch(logout());
    window.location.reload();
  }

  toggleMenuAvatar() {
    this.store.dispatch(toggleMenu());
  }

  redirectToHome() {
    this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
  }

  switchSelect(content: string, event: any) {
    if (content) {
      this.store.dispatch(switchSelect({selectContentType: content}));
    } else if (event) {
      if (event.currentTarget.checked) {
        this.store.dispatch(switchSelect({selectContentType: 'ecommerce'}));
      } else {
        this.store.dispatch(switchSelect({selectContentType: 'survey'}));
      }
    }
  }

}



