import { createReducer, on } from '@ngrx/store';
import { toggleMenu, switchSelect } from './navbar.actions';

export const initialState: NavbarState = {
  isOpenMenuAvatar: false,
  selectContentType: 'survey',
};
export interface NavbarState {
  isOpenMenuAvatar: boolean;
  selectContentType: string;
}

export const NavbarReducer= createReducer(
  initialState,
  on(toggleMenu, (state) => ({
    ...state,
    isOpenMenuAvatar: !state.isOpenMenuAvatar,
  })),
  on(switchSelect, (state, { selectContentType }) => ({
    ...state,
    selectContentType,
  }))
);
