
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../services/auth/authorization.guards';

import { DashboardLandingComponent } from './components/dash-landing/dash-landing.component';
import { DashboardQuestionsOverviewComponent } from './components/dash-questions-overview/dash-questions-overview.component';
import { DashboardSatisfactionComponent } from './components/dash-satisfaction/dash-satisfaction.component';
import { DashboardPolarizationComponent } from './components/dash-polarization/dash-polarization.component';
import { DashboardNPSComponent } from './components/dash-nps/dash-nps.component';
import { DashboardAggregationComponent } from './components/dash-aggregation/dash-aggregation.component';
import { DashboardTextBoxesComponent } from './components/dash-text-boxes/dash-text-boxes.component';
import { DashboardEmotionalOverviewComponent } from './components/dash-emotional-overview/dash-emotional-overview.component';

const routes: Routes = [
  {path: 'dashboard', component: DashboardLandingComponent, canActivate: [AuthGuard]},
  {path: 'questions-overview', component: DashboardQuestionsOverviewComponent, canActivate: [AuthGuard]},
  {path: 'satisfaction', component: DashboardSatisfactionComponent, canActivate: [AuthGuard]},
  {path: 'polarization', component: DashboardPolarizationComponent, canActivate: [AuthGuard]},
  {path: 'nps', component: DashboardNPSComponent, canActivate: [AuthGuard]},
  {path: 'aggregation', component: DashboardAggregationComponent, canActivate: [AuthGuard]},
  {path: 'text-boxes', component: DashboardTextBoxesComponent, canActivate: [AuthGuard]},
  {path: 'overview', component: DashboardEmotionalOverviewComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]

})
export class SurveyRoutingModule { }
