import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, mergeMap, retry, retryWhen } from 'rxjs/operators';
import { BackendError, ErrorSeverity } from '../models/Errors';
import { Toast, ToastrService } from "ngx-toastr";
import { LoaderService } from '../services/loader/loader.service';
import { AuthState } from '../stores/auth/authentication.reducer';
import { logout } from '../stores/auth/authentication.actions';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  authState$: Observable<AuthState>;

  constructor(
    private toastr: ToastrService,
    private loadingService: LoaderService,
    private store: Store<{ authState: AuthState }>)
    {
      this.authState$ = store.select('authState');
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((errorResponse: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          let errorMessage = '';
          errorMessage = errorResponse?.error?.message;
          // if (errorResponse.error instanceof ErrorEvent) {
          //   // client-side error
          //   console.log("client");
          //   errorMessage = `Error: ${errorResponse.error.message}`;
          // } else {
          //   // server-side error
          //   console.log("server");
          //   errorMessage = `Error Code: ${errorResponse.status}\nMessage: ${errorResponse.message}`;
          // }
          // eslint-disable-next-line no-alert

          if (errorMessage === 'Unauthorized!' || errorMessage === 'No token provided!') {
            this.store.dispatch(logout());
          }

          if(errorMessage)
            this.toastr.error(errorMessage);

          return throwError(() => new Error(errorMessage?errorMessage:"Error Server"));
        })
      )
  }
}
