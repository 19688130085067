import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  public uploadFileCsv(req: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}import-csv`, req);
  }
}
