import { UploadFileRequest } from './../../../../models/global.request';
import { setPeriods } from '../../../../stores/survey/survey.actions';
import { ChangeDetectorRef, Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
// import { setSurvey } from 'src/app/@core/state/survey/survey.actions';
import { Survey } from 'src/app/models/Survey';
import  Utils  from 'src/app/utils/utils';
import { Router  } from '@angular/router';

//Form builders
import { FormArray, FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from "@angular/forms";

//States
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//constants
import { ROUTES } from '../../../../utils/constants'

//Models
import { Period } from 'src/app/models/Period';
import { FileUploadService } from 'src/app/services/fileUpload/fileupload.service';


@Component({
  selector: 'app-import-survey-filled',
  templateUrl: './import-survey-filled.component.html',
  styleUrls: ['./import-survey-filled.component.scss'],
})
export class ImportSurveyFilledComponent {

  surveyState$: Observable<SurveyState>;
  fileToUpload: FileList | undefined;
  file!: File;

  utils!: Utils;
  constructor(
    private cd: ChangeDetectorRef,
    public fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private store: Store<{ surveyState: SurveyState }>) {
    this.surveyState$ = store.select('surveyState');
  }

  uploadFileForm = this.fb.group({
    file: ['', Validators.required]

  });

  get fileUploaded() {
    return this.uploadFileForm.get("file");
  }

  ngOnInit(): void {

  }



  onFileChange(event: any) {
    this.file = event.target.files[0];
  }

  onSubmitUploadFile() {
    const  formData = new FormData();
    formData.append("import-csv", this.file);

    if (this.file) {
      this.fileUploadService.uploadFileCsv(formData).subscribe(res => {
        // eslint-disable-next-line no-alert
        alert("Uploaded");
      })
    } else {
      // eslint-disable-next-line no-alert
      alert("Please select a file first");
    }

  }

}
