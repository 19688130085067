import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from '../../../../services/auth/authentication.service'
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { register } from '../../../../stores/auth/authentication.actions'
import { RegisterRequest } from 'src/app/models/global.request';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/utils/constants';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  authState$: Observable<any>;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private store: Store<{ authState: any }>) {
    this.authState$ = store.select('authState');
  }

  signUpForm = this.fb.group({
    name: ["", [Validators.required]],
    surname: ["", [Validators.required]],
    email: ["", [Validators.required]],
    password: ["", [Validators.required]],
  });

  get name() {
    return this.signUpForm.get("name");
  }

  get surname() {
    return this.signUpForm.get("surname");
  }

  get email() {
    return this.signUpForm.get("email");
  }

  get password() {
    return this.signUpForm.get("password");
  }

  ngOnInit() {
    this.authState$.subscribe(authS => {
      if (authS.isAuthenticated) {
        this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
      }
    })
  }

  onSubmitSignUp() {
    const name = this.signUpForm.value.name || "";
    const surname = this.signUpForm.value.surname || "";
    const email = this.signUpForm.value.email || "";
    const password = this.signUpForm.value.password|| "";
    const signUpData = new RegisterRequest(name, surname, email, password)
    this.store.dispatch(register({ request: signUpData }));
  }
}
