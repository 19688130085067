<div class="tabella-domande-keys">

    <table>
        <thead>
          <tr>
            <th align="center" style="width: 25%;">TOUCHPOINT</th>
            <th align="center" style="width: 15%;">ID</th>
            <th align="center" style="width: 60%;">
              <div class="top-wrapper">
                DOMANDA
                <div (click)="onClose()">×</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let question of questions" >
                <tr>
                  <td style="width: 25%;" align="center" >{{question.touch_point}}</td>
                  <td style="width: 15%;" align="center" >{{question.domanda_id}}</td>
                  <td style="width: 60%;" align="center" >{{question.description}}</td>
                </tr>
              </ng-container>
        </tbody>
    </table>
</div>
