import { DeleteEcommerceRequest, DeleteSurveyRequest, GetEcommerceListRequest, GetSurveyRequest } from './../../../../models/global.request';
import { SurveyService } from './../../../../services/survey/survey.service';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getSurveyById, setSurvey, setSurveyList } from '../../../../stores/survey/survey.actions';
import { Survey } from 'src/app/models/Survey';
import { Router  } from '@angular/router';

//States
import { SurveyState } from '../../../../stores/survey/survey.reducer';
import { AuthState } from './../../../../stores/auth/authentication.reducer';

import { DomSanitizer } from '@angular/platform-browser';


//constants
import { ROUTES } from '../../../../utils/constants'
import { GetSurveyListRequest } from 'src/app/models/global.request';

//Utils
import Formatter from 'src/app/utils/formatters';
import  Utils  from 'src/app/utils/utils';
import { NavbarState } from 'src/app/stores/navbar/navbar.reducer';
import { Ecommerce } from 'src/app/models/Ecommerce';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';
import { setEcommerceList } from 'src/app/stores/ecommerce/ecommerce.actions';
import { EcommerceState } from 'src/app/stores/ecommerce/ecommerce.reducer';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-survey',
  templateUrl: './select-survey.component.html',
  styleUrls: ['./select-survey.component.scss'],
})
export class SelectSurveyComponent {

  surveyState$: Observable<SurveyState>;
  ecommerceState$: Observable<EcommerceState>;
  authState$: Observable<AuthState>;
  navbarState$ : Observable<NavbarState>;
  currentUser: any;
  currentSurveyList!: Survey[];
  currentEcommerceList!: Ecommerce[];
  lastSurveyList!: Survey[];
  lastEcommerceList!: Ecommerce[];

  surveyList$: any = [];
  ecommerceList$: any = [];
  utils!: Utils;
  formatter!: Formatter;
  paginationLastSurveysLimit: number = 4;
  paginationSurveysLimit: number = 4;
  paginationSurveysOrder =  ['name','ASC'];
  paginationLastEcommerceLimit: number = 4;
  paginationEcommerceLimit: number = 4;
  paginationEcommerceOrder =  ['user_proj','ASC'];
  pageNumber: number = 1;
  constructor(
    private router: Router,
    private surveyService: SurveyService,
    private ecommerceService: EcommerceService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private store: Store<{ surveyState: SurveyState, ecommerceState: EcommerceState, authState: AuthState, navbarState: NavbarState }>) {
      this.surveyState$ = store.select('surveyState');
      this.ecommerceState$ = store.select('ecommerceState');
      this.authState$ = store.select('authState');
      this.navbarState$ = store.select('navbarState');
      this.utils = new Utils();
      this.formatter = new Formatter();

  }

  ngOnInit(): void {
    // this.surveyState$.subscribe(surveyS => this.currentSurveyList = surveyS.surveyList);

    this.authState$.subscribe(authS => this.currentUser = authS.user);


    this.getLastSurveyList();
    this.getSurveyList();

    this.getLastEcommerceList();
    this.getEcommerceList();

    // this.surveyService.getSurveyList()
  }

  getLastSurveyList (): void {
    const request: GetSurveyListRequest = {
      user_id: this.currentUser.user_id,
      offset: 0,
      limit: this.paginationLastSurveysLimit,
      order : ['createdAt','DESC'],
    }

    this.surveyService.getSurveyList(request).subscribe((data) =>
      {
        const formattedResponse = this.formatter.formatResponseGetSurveyList(data);
        this.lastSurveyList =  formattedResponse;

      });
  }
  getLastEcommerceList (): void {
    const request: GetEcommerceListRequest = {
      user_id: this.currentUser.user_id,
      offset: 0,
      limit: this.paginationLastEcommerceLimit,
      order : ['createdAt','DESC'],
    }

    this.ecommerceService.getEcommerceList(request).subscribe((data) => {
      const formattedResponse = this.formatter.formatResponseGetEcommerceList(data);
      this.lastEcommerceList =  formattedResponse;
    });
  }
  getSurveyList (): void {
    const request: GetSurveyListRequest = {
      user_id: this.currentUser.user_id,
      offset: 0,
      limit: 1000,
      order : this.paginationSurveysOrder,
    }

    this.surveyService.getSurveyList(request).subscribe((data) =>
      {
        const formattedResponse = this.formatter.formatResponseGetSurveyList(data);
        this.surveyList$ = formattedResponse;
        this.currentSurveyList =  formattedResponse;
        this.store.dispatch(setSurveyList({ surveyList: this.surveyList$}));

      });
  }
  getEcommerceList (): void {
    const request: GetEcommerceListRequest = {
      user_id: this.currentUser.user_id,
      offset: 0,
      limit: 1000,
      order : this.paginationEcommerceOrder,
    }

    this.ecommerceService.getEcommerceList(request).subscribe((data) => {
      const formattedResponse = this.formatter.formatResponseGetEcommerceList(data);
      this.ecommerceList$ = formattedResponse;
      this.currentEcommerceList = formattedResponse;
      this.store.dispatch(setEcommerceList({ ecommerceList: this.ecommerceList$}));
    });
  }
  getLabelOrder(orderBy: string[], type: string) {
    let orderLabel = "";
    if(orderBy[0]=="user_proj" || orderBy[0]=="name") {
        if(orderBy[1]=="ASC") {
          orderLabel = "A-Z";
        }else{
          orderLabel = "Z-A";
        }
    }
    if(orderBy[0]=="createdAt") {
      if(orderBy[1]=="DESC") {
        orderLabel = type === "survey" ? "last surveys" : "last modules";
      }
    }

    return orderLabel;
  }

  selectSurveyItem (survey: Survey) {
    const tempSurveyList =  this.currentSurveyList.map(tSurvey=> {
      tSurvey = {...tSurvey, isSelected: false};

      return tSurvey;
    });
    const tempSurvey = Object.assign({},survey);

    const index = tempSurveyList.map(tSurvey => tSurvey.id).indexOf(tempSurvey.id);
    tempSurvey.isSelected = true;
    tempSurveyList[index] = tempSurvey;

    this.store.dispatch(setSurvey({ currentSurvey:  tempSurvey, surveyList: tempSurveyList }));

    const getSurveyRequest: GetSurveyRequest ={
      survey_id: tempSurvey.id,

    }
    this.store.dispatch(getSurveyById({ request: getSurveyRequest}));

    this.router.navigate([ROUTES.ROUTES_SELECT_PERIOD]);

  }

  selectEcommerceItem (ecommerce: any) {
    this.router.navigate([ROUTES.ROUTE_EDIT_ECOMMERCE + '/' + ecommerce.configId]);
  }

  // delete ecommerce
  deleteEcommerceCandidate: Ecommerce | null = null;
  showDeleteEcommerceConfirmModal = false;
  onClickOpenModalDeleteEcommerce(ecommerce: Ecommerce) {
    this.deleteEcommerceCandidate = ecommerce;
    this.showDeleteEcommerceConfirmModal = true;
  }
  dismissDeleteEcommerce() {
    this.deleteEcommerceCandidate = null;
    this.showDeleteEcommerceConfirmModal = false;
  }
  confirmDeleteEcommerce() {
    if (this.deleteEcommerceCandidate) {
      const request = new DeleteEcommerceRequest(this.deleteEcommerceCandidate.configId);
      this.ecommerceService.deleteEcommerce(request).subscribe((data) => {
        this.getLastEcommerceList();
        this.getEcommerceList();
        this.deleteEcommerceCandidate = null;
        this.showDeleteEcommerceConfirmModal = false;
        this.toastr.success("Ecommerce module deleted", "Success");
      });
    }
  }


  // delete survey
  deleteSurveyCandidate: Survey | null = null;
  showDeleteSurveyConfirmModal = false;
  onClickOpenModalDeleteSurvey(survey: Survey) {
    this.deleteSurveyCandidate = survey;
    this.showDeleteSurveyConfirmModal = true;
  }
  dismissDeleteSurvey() {
    this.deleteSurveyCandidate = null;
    this.showDeleteSurveyConfirmModal = false;
  }
  confirmDeleteSurvey() {
    if (this.deleteSurveyCandidate) {
      const request = new DeleteSurveyRequest(this.deleteSurveyCandidate.id);
      this.surveyService.deleteSurvey(request).subscribe((data) => {
        this.getLastSurveyList();
        this.getSurveyList();
        this.deleteSurveyCandidate = null;
        this.showDeleteSurveyConfirmModal = false;
        this.toastr.success("Survey deleted", "Success");
      });
    }
  }


  // edit survey
  onClickEditSurvey(survey: Survey) {
    this.router.navigate([ROUTES.ROUTE_EDIT_SURVEY + '/' + survey.id]);
  }
}
