import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateEcommerceRequest, DeleteEcommerceRequest, GetEcommerceListRequest, GetEcommerceRequest, SendEcommerceEmailRequest } from "src/app/models/global.request";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {
  private serviceContainer = "ecomm/";
  constructor(
    private http: HttpClient,
  ) {}

  public createEcommerce(request: CreateEcommerceRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}create`, request);
  }

  public getEcommerceList(request: GetEcommerceListRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}get-list`, request);
  }

  public getEcommerceById(request: GetEcommerceRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}get`, request);
  }

  public sendEcommerceEmail(request: SendEcommerceEmailRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}send-email`, request);
  }

  public deleteEcommerce(request: DeleteEcommerceRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}${this.serviceContainer}delete`, request);
  }
}
