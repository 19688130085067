import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {OverlayModule} from '@angular/cdk/overlay';

//Components
import { CreateSurveyComponent } from './components/create-survey/create-survey.component';
import { SelectSurveyComponent } from './components/select-survey/select-survey.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';
import { ImportSurveyFilledComponent } from './components/import-survey-filled/import-survey-filled.component';

//Routing
import { SurveyRoutingModule } from './survey-routing.module';



// Forms
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Material Modules
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

//Others libraries
import { ToastrModule } from 'ngx-toastr';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditSurveyComponent } from './components/edit-survey/edit-survey.component';
@NgModule({
  declarations: [
    CreateSurveyComponent,
    EditSurveyComponent,
    SelectSurveyComponent,
    SelectPeriodComponent,
    ImportSurveyFilledComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    SurveyRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPaginationModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MomentModule,
    SharedModule
  ],
  exports:[
    CreateSurveyComponent,
    EditSurveyComponent,
    SelectSurveyComponent,
    ImportSurveyFilledComponent,
  ]
})
export class SurveyModule { }
