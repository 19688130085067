import { CreateUserRequest } from 'src/app/models/global.request';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { CreateUserResponse } from 'src/app/models/global.response';

//Users

export const createUser = createAction(
  '[Create User] Create User',
  props<{ params: CreateUserRequest }>(
  ),
);

export const createUserSuccess = createAction(
  '[Create User] Create User Success',
  props<{ userIds: [], createSurveyParams : any}>()
);

export const createUserFailure = createAction(
  '[Auth/API] Create User Failure',
  props<{ error: any }>()
);
