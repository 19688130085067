import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";
import { SurveyState } from '../../../../stores/survey/survey.reducer';

import { Period } from "src/app/models/Period";
import { setMenuItem } from "src/app/stores/sidebar/sidebar.actions";
import { MenuItem } from 'src/app/models/MenuItem';
import { ROUTES } from "src/app/utils/constants";
import { EmotionalRequest } from "src/app/models/global.request";
import { setPeriodsCounters } from "src/app/stores/survey/survey.actions";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";

import * as htmlToImage from 'html-to-image';
import { EChartsOption } from "echarts";
import Utils from "src/app/utils/utils";
import Formatter from "src/app/utils/formatters";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Question } from "src/app/models/Question";
import { Answer, AnswerRange } from "src/app/models/Answer";

import { LoaderService } from '../../../../services/loader/loader.service';

@Component({
  selector: "app-dash-emotional-overview",
  templateUrl: "./dash-emotional-overview.component.html",
  styleUrls: ["./dash-emotional-overview.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardEmotionalOverviewComponent implements OnInit {

  utils!: Utils;
  formatter!: Formatter;

  questions: any;
  currentSurvey: any;

  periodsCounters: any = {
    views: [0,0],
    completed: [0,0],
  };
  periodList!: Period[] | null;
  periodOneStartDate!: Date;
  periodOneEndDate!: Date;
  periodTwoStartDate!: Date;
  periodTwoEndDate!: Date;
  surveyName!: string;
  isOnePeriod = false;

  sidebarState$: Observable<SidebarState>;
  surveyState$ : Observable<SurveyState>;
  dashboardData$: Observable<any[]> | undefined;
  currentMenuItemList!: MenuItem[];

  emotionalMeter: any[] = [];
  emotionalOverview = [
    // {grey: 0, green: 0, orange: 0, red: 0, total: null, score: null},
    // {grey: 0, green: 0, orange: 0, red: 0, total: null, score: null}
    {green: 0, orange: 0, red: 0, total: null, score: null},
    {green: 0, orange: 0, red: 0, total: null, score: null}
  ];
  emotionalAnswers: any[] = [];
  colors = {
    // grey: ['#CACCD2', '#A8AAB5', '#75767E'],
    red: ['#EB8484', '#EC2E21', '#C42423'],
    orange: ['#EBEBA5', '#EDEC53', '#EED954'],
    green: ['#86BA85', '#378F23', '#2F7B21'],
  };

  chartOverview1Option!: EChartsOption;
  chartOverview2Option!: EChartsOption;

  filterQuestions: any;
  answersOptions!: AnswerRange[];
  domandaId: any;
  filteredQuestionType = "";
  showOptionsContainer!: boolean;
  showFiltersListFormVisible: boolean = true;

  filterEmotionalForm = this.fb.group({
    filterQuestionValue:  [ "", [Validators.required]],
    filterQuestionType:  [ "", [Validators.required]],
    filterAnswerValue: ["", [Validators.required]],
    filterAnswerRangeStart: ["", [Validators.required]],
    filterAnswerRangeEnd: ["", [Validators.required]],
    filterCrossQuestionValue: ["",[Validators.required]],
    selectedFiltersListForm: this.fb.array([],) ,
  });

  get filterQuestionValue() {
    return this.filterEmotionalForm.get("filterQuestionValue");
  }
  get filterQuestionType() {
    return this.filterEmotionalForm.get("filterQuestionType");
  }
  get filterCrossQuestionValue() {
    return this.filterEmotionalForm.get("filterCrossQuestionValue");
  }

  get filterAnswerValue() {
    return this.filterEmotionalForm.get("filterAnswerValue");
  }
  get filterAnswerRangeStart() {
    return this.filterEmotionalForm.get("filterAnswerRangeStart");
  }

  get filterAnswerRangeEnd() {
    return this.filterEmotionalForm.get("filterAnswerRangeEnd");
  }

  get selectedFiltersListForm() : FormArray {
    return this.filterEmotionalForm.get("selectedFiltersListForm") as FormArray
  }

  constructor(
    private store: Store<{ sidebarState: SidebarState, surveyState: SurveyState  }>,
    private dashboardService: DashboardService,
    public fb: FormBuilder,
    private loaderService: LoaderService,
  ) {
    this.utils = new Utils();
    this.formatter = new Formatter();
    this.sidebarState$ = store.select("sidebarState");
    this.surveyState$ = store.select('surveyState');
  }

  ngOnInit(): void {
    this.sidebarState$.subscribe(sidebarS => {
      this.currentMenuItemList = sidebarS.menuItemList;
    });
    this.setDashboardMenuItem();

    this.surveyState$.subscribe(surveyS => this.currentSurvey = surveyS.currentSurvey);
    this.surveyState$.subscribe(surveyS => {
      this.periodList = surveyS.periodList;
      this.periodList?.forEach((period, index) => {
        if (period.id == 0) {
          this.periodOneStartDate = new Date(period.startDate);
          this.periodOneEndDate = new Date(period.endDate);
        }
        if (period.id == 1) {
          this.periodTwoStartDate = new Date(period.startDate);
          this.periodTwoEndDate = new Date(period.endDate);
        }
      });
      if (this.periodOneStartDate.toISOString() === this.periodTwoStartDate.toISOString() && this.periodOneEndDate.toISOString() === this.periodTwoEndDate.toISOString()) {
        this.isOnePeriod = true;
      }

      const nameSurvey = surveyS?.currentSurvey?.customName ? surveyS?.currentSurvey?.customName : surveyS?.currentSurvey?.name;
      this.surveyName = nameSurvey || "";
      this.questions = surveyS.questions;
      this.filterQuestions = surveyS.filterQuestions;
    });

    this.loadChartsData();
  }

  loadChartsData() {
    const periodOne = {
      startDate: this.periodOneStartDate.toISOString().split("T")[0],
      endDate: this.periodOneEndDate.toISOString().split("T")[0],
    }

    const periodTwo = {
      startDate: this.periodTwoStartDate.toISOString().split("T")[0],
      endDate: this.periodTwoEndDate.toISOString().split("T")[0],
    }

    const requestEmotionalCharts: EmotionalRequest = {
      survey_id: this.currentSurvey?.id,
      periods:[periodOne, periodTwo],
      filterQuestions: this.getFormatedFilters() || [],
    }

    this.dashboardService.getEmotionalCharts(requestEmotionalCharts).subscribe((data) => {
      this.periodsCounters = {
        completed: data.completed
      };
      this.emotionalOverview = data.emotionalOverview;
      this.store.dispatch(setPeriodsCounters({ periodsCounters: this.periodsCounters }));

      this.chartOverview1Option = {
        backgroundColor: '#ffffff',
        height: '200px',
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)',
        },
        visualMap: {
          show: false,
          min: 0,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '90%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[0]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[0]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[0]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[0]},
              },
            ],
            label: {
              show: true,
              formatter: function(params) {
                var percentage = params.percent?.toFixed(2);
                var value = params.value.toLocaleString();
                return `${value} (${percentage}%)`;
              }
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '50%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[1]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[1]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[1]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[1]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '20%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[0].grey,
              //   name: this.emotionalOverview[0].grey,
              //   itemStyle: {color: this.colors.grey[2]},
              // },
              {
                value: this.emotionalOverview[0].red,
                name: this.emotionalOverview[0].red,
                itemStyle: {color: this.colors.red[2]},
              },
              {
                value: this.emotionalOverview[0].orange,
                name: this.emotionalOverview[0].orange,
                itemStyle: {color: this.colors.orange[2]},
              },
              {
                value: this.emotionalOverview[0].green,
                name: this.emotionalOverview[0].green,
                itemStyle: {color: this.colors.green[2]},
              },
            ],
            label: {
              show: false,
            },
          },
        ]
      };

      this.chartOverview2Option = {
        backgroundColor: '#ffffff',
        height: '200px',
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)',
        },
        visualMap: {
          show: false,
          min: 0,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '90%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[0]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[0]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[0]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[0]},
              },
            ],
            label: {
              show: true,
              formatter: function(params) {
                var percentage = params.percent?.toFixed(2);
                var value = params.value.toLocaleString();
                return `${value} (${percentage}%)`;
              }
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '50%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[1]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[1]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[1]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[1]},
              },
            ],
            label: {
              show: false,
            },
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '20%',
            emphasis: {
              disabled: true,
            },
            data: [
              // {
              //   value: this.emotionalOverview[1].grey,
              //   name: this.emotionalOverview[1].grey,
              //   itemStyle: {color: this.colors.grey[2]},
              // },
              {
                value: this.emotionalOverview[1].red,
                name: this.emotionalOverview[1].red,
                itemStyle: {color: this.colors.red[2]},
              },
              {
                value: this.emotionalOverview[1].orange,
                name: this.emotionalOverview[1].orange,
                itemStyle: {color: this.colors.orange[2]},
              },
              {
                value: this.emotionalOverview[1].green,
                name: this.emotionalOverview[1].green,
                itemStyle: {color: this.colors.green[2]},
              },
            ],
            label: {
              show: false,
            },
          },
        ]
      };

      this.emotionalMeter = this.formatter.formatResponseEmotionalMeter(data.emotionalMeter);

      const emotionalQuestion = this.questions.filter((question: any) => question.question_id == data.emotionalQuestion.question_id);
      if (emotionalQuestion.length) {
        const _answers = emotionalQuestion.pop().answers;
        this.emotionalMeter.forEach((meter) => {
          const currentAnswer = _answers.filter((_ans: any) => _ans.image_path == meter.image);
          if (currentAnswer) {
            meter.description = currentAnswer.pop().description;
          }
        });
      }
    });
  }

  getLabelQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        return tempSelectedQuestion.description
      }
    }

    return " "
  }

  getLabelAnswerById(filterQuestionId: any, filterAnswerId: any, allowEmpty: boolean = false) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if( tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if( tempSelectedAnswer) {
          return tempSelectedAnswer.description
        }
      }
    }

    if (allowEmpty) {
      return "";
    }

    return "Select answer"
  }

  onChangeSelectQuestion() {
    if(this.filterQuestionValue?.value) {
      const selectedIndexQuestionId = parseInt(this.filterQuestionValue?.value, 10);
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id === selectedIndexQuestionId));
      this.answersOptions = tempSelectedQuestion.answers;
      this.domandaId = tempSelectedQuestion.domanda_id;
      this.filteredQuestionType = tempSelectedQuestion.type;
      this.filterQuestionType?.patchValue(tempSelectedQuestion.type);
    }

    // this.setFilterAnswerValue("");
  }

  setAnswerOption(idAnswer: string) {
    this.filterEmotionalForm.controls['filterAnswerValue'].setValue(idAnswer);
  }

  onClickCloseOptionsContainer() {
    this.showOptionsContainer = false;

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();
  }

  onClickShowFiltersListForm () {
    this.showFiltersListFormVisible = true;
  }

  onClickRemoveLastFilter() {
    if(this.selectedFiltersListForm.value.length == 1) {
      this.showFiltersListFormVisible = true;
    }
    const lastIndex = (this.selectedFiltersListForm.value.length) -1;
    this.selectedFiltersListForm.removeAt(lastIndex);

    if (lastIndex > 0) {
      this.filterQuestionValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionId);
      this.filterQuestionType?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterQuestionType);
      this.filterAnswerValue?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeStart?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
      this.filterAnswerRangeEnd?.patchValue(this.selectedFiltersListForm.at(lastIndex - 1).value.filterAnswerId);
    } else {
      this.filterQuestionValue?.patchValue('');
      this.filterQuestionType?.patchValue('');
      this.filterAnswerValue?.patchValue('');
      this.filterAnswerRangeStart?.patchValue('');
      this.filterAnswerRangeEnd?.patchValue('');
      this.filterQuestionValue?.markAsPristine();
      this.filterQuestionValue?.markAsUntouched();
      this.filterQuestionType?.markAsPristine();
      this.filterQuestionType?.markAsUntouched();
      this.filterAnswerValue?.markAsPristine();
      this.filterAnswerValue?.markAsUntouched();
      this.filterAnswerRangeStart?.markAsPristine();
      this.filterAnswerRangeStart?.markAsUntouched();
      this.filterAnswerRangeEnd?.markAsPristine();
      this.filterAnswerRangeEnd?.markAsUntouched();
    }

    this.loadChartsData();
  }

  onClickAddNewFilter() {
    const filterQuestionValue = this.filterQuestionValue?.value;
    const filterQuestionType = this.filterQuestionType?.value;
    const filterAnswerValue = this.filterAnswerValue?.value;
    const filterAnswerRangeStart = this.filterAnswerRangeStart?.value;
    const filterAnswerRangeEnd = this.filterAnswerRangeEnd?.value;

    if(filterQuestionValue && filterAnswerValue) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerValue});
    } else if (filterQuestionValue && filterAnswerRangeStart !== null && filterAnswerRangeEnd !== null) {
      this.addSelectedFilterList({filterQuestionValue, filterQuestionType, filterAnswerRangeStart, filterAnswerRangeEnd});
    }

    this.filterQuestionValue?.patchValue('');
    this.filterQuestionType?.patchValue('');
    this.filterAnswerValue?.patchValue('');
    this.filterAnswerRangeStart?.patchValue('');
    this.filterAnswerRangeEnd?.patchValue('');
    this.filterQuestionValue?.markAsPristine();
    this.filterQuestionValue?.markAsUntouched();
    this.filterQuestionType?.markAsPristine();
    this.filterQuestionType?.markAsUntouched();
    this.filterAnswerValue?.markAsPristine();
    this.filterAnswerValue?.markAsUntouched();
    this.filterAnswerRangeStart?.markAsPristine();
    this.filterAnswerRangeStart?.markAsUntouched();
    this.filterAnswerRangeEnd?.markAsPristine();
    this.filterAnswerRangeEnd?.markAsUntouched();

    this.showOptionsContainer = false;
    this.showFiltersListFormVisible = false;
    this.loadChartsData();
  }

  addSelectedFilterList(filter: any) {
    this.selectedFiltersListForm.push(this.newFilter(filter));
  }

  newFilter(filter: any): FormGroup {
    return this.fb.group({
      filterQuestionId:  filter.filterQuestionValue,
      filterQuestionType:  filter.filterQuestionType,
      filterAnswerId: filter.filterAnswerValue,
      filterAnswerRangeStart: filter.filterAnswerRangeStart,
      filterAnswerRangeEnd: filter.filterAnswerRangeEnd,
    })
  }

  validNewFilter(type: string, range_start: number, range_end: number) {
    if (type === 'knob' || type === 'slider') {
      return this.filterAnswerRangeStart?.valid &&
        this.filterAnswerRangeEnd?.valid &&
        this.filterAnswerRangeStart?.value !== null &&
        this.filterAnswerRangeEnd?.value !== null &&
        parseInt(this.filterAnswerRangeStart?.value) < parseInt(this.filterAnswerRangeEnd?.value) &&
        parseInt(this.filterAnswerRangeStart?.value) >= range_start &&
        parseInt(this.filterAnswerRangeStart?.value) <= range_end &&
        parseInt(this.filterAnswerRangeEnd?.value) >= range_start &&
        parseInt(this.filterAnswerRangeEnd?.value) <= range_end;
    } else {
      return this.filterAnswerValue?.valid;
    }
  }

  getFormatedFilters () {
    const formattedFilters: any = [];

    this.selectedFiltersListForm.controls.forEach( (filterElement) =>{
      const domandaId = this.getDomandaIdQuestionById(filterElement.value.filterQuestionId);
      const imagePath = this.getImageAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId);
      const description = this.getLabelAnswerById(filterElement.value.filterQuestionId, filterElement.value.filterAnswerId, true);
      const interval_start = filterElement.value.filterAnswerRangeStart;
      const interval_end = filterElement.value.filterAnswerRangeEnd;
      const question_type = filterElement.value.filterQuestionType;
      formattedFilters.push({question_id: filterElement.value.filterQuestionId, domanda_id: domandaId, answer_image: imagePath, description: description, interval_start: interval_start, interval_end: interval_end, question_type: question_type })
    });

    return formattedFilters;
  }

  getDomandaIdQuestionById(filterQuestionId: any) {
    if(filterQuestionId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if (tempSelectedQuestion) {
        return tempSelectedQuestion.domanda_id
      }
    }

    return " "
  }

  getImageAnswerById(filterQuestionId: any, filterAnswerId: any) {
    if(filterQuestionId && filterAnswerId) {
      const tempSelectedQuestion = this.filterQuestions.find(((tQuestion : Question)  => tQuestion.id == filterQuestionId));
      if (tempSelectedQuestion) {
        const answersTemp = tempSelectedQuestion.answers;
        const tempSelectedAnswer = answersTemp.find(((tAnswer : Answer)  => tAnswer.id == filterAnswerId));
        if (tempSelectedAnswer) {
          return tempSelectedAnswer.urlImage
        }
      }
    }

    return null
  }

  setDashboardMenuItem() {
    const menuItem = new MenuItem(1,"Overview", ROUTES.ROUTE_EMOTIONAL_OVERVIEW, true);
    const tempMenuItemList =  this.currentMenuItemList.map(tMenuItem=> {
      tMenuItem = {...tMenuItem, isActive: false};

      return tMenuItem;
    });
    const tempMenuItem = Object.assign({},menuItem);;
    const index = tempMenuItemList.map(tMenuItem => tMenuItem.id).indexOf(tempMenuItem.id);
    tempMenuItem.isActive = true;
    tempMenuItemList[index] = tempMenuItem;
    this.store.dispatch(setMenuItem({ currentMenuItem:  tempMenuItem, menuItemList: tempMenuItemList }));
  }

  onClickDownloadImage(elementID: string) {
    const node = document.getElementById(elementID) as HTMLElement;
    const exportBtns = document.getElementsByClassName('export-wrapper');
    for (let index = 0; index < exportBtns.length; index ++) {
      (exportBtns[index] as HTMLElement).style.display = 'none';
    }
    this.loaderService.setLoading(true);
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = elementID + '-chart-image.png';
        link.href = dataUrl;
        link.click();
        for (let index = 0; index < exportBtns.length; index ++) {
          (exportBtns[index] as HTMLElement).style.display = 'flex';
        }
        this.loaderService.setLoading(false);
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
        for (let index = 0; index < exportBtns.length; index ++) {
          (exportBtns[index] as HTMLElement).style.display = 'flex';
        }
      });
  }

}
