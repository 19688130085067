export class Step {
  public id: number;
  public label: string;
  public subtitle: string;
  public isActive: boolean;
  public isCompleted: boolean;

  constructor (id: number, label: string, subtitle: string, isActive: boolean, isCompleted: boolean) {
    this.id = id;
    this.label = label;
    this.subtitle = subtitle;
    this.isActive = isActive;
    this.isCompleted = isCompleted;

  }
}
