import { Survey } from 'src/app/models/Survey';
import { createReducer, on } from '@ngrx/store';
import { setSurvey, setSurveyList, setPeriods, createSurvey, createSurveySuccess, createSurveyFailure, saveSurveyParams, getSurveyById, getSurveyByIdSuccess, getSurveyByIdFailure, setPeriodsCounters } from './survey.actions';
import { Period } from 'src/app/models/Period';
import { Question } from 'src/app/models/Question';


export const initialState: SurveyState = {
  currentSurvey: null,
  surveyList : [],
  periodList: null,
  createSurveyParams: null,
  filterQuestions: null,
  periodsCounters: null,
  touchpoints: null,
  questions: null,
};

export interface SurveyState {
  touchpoints: any;
  currentSurvey: Survey| null;
  surveyList: Survey[];
  periodList: Period[] | null;
  createSurveyParams: any;
  filterQuestions: any;
  periodsCounters: any;
  questions: any;
}

export const SurveyReducer = createReducer(
  initialState,
  on(createSurvey, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),
  on(saveSurveyParams, (state, {createSurveyParams }) => ({
    ...state,
    createSurveyParams,
    error: null,
    pending: true,
  })),
  on(createSurveySuccess, (state, { response }) => ({
    ...state,
    response,
    error: null,
    pending: false,
  })),
  on(createSurveyFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
  on(setSurvey, (state, { currentSurvey, surveyList }) => ({
    ...state,
    currentSurvey,
    surveyList,
  })),
  on(setSurveyList, (state, { surveyList }) => ({
    ...state,
    surveyList,
  })),
  on(setPeriods, (state, { periodList }) => ({
    ...state,
    periodList,
  })),
  on(setPeriodsCounters, (state, { periodsCounters }) => ({
    ...state,
    periodsCounters,
  })),
  on(getSurveyById, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),
  on(getSurveyByIdSuccess, (state, { filterQuestions, touchpoints, questions }) => ({
    ...state,
    filterQuestions,
    touchpoints,
    questions,
    error: null,
    pending: false,
  })),
  on(getSurveyByIdFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
);
