<div id="register-wrapper" >
    <form class="base--form-generic row" [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUp()" >
      <div class="form-group col-md-3">
        <label for="name">Name</label>
        <input type="text" class="form-control" formControlName="name" placeholder="Enter name">
      </div>
      <div class="form-group col-md-3">
        <label for="surname">Surname</label>
        <input type="text" class="form-control" formControlName="surname" placeholder="Enter surname">
      </div>
      <div class="form-group col-md-3">
        <label for="email">Email</label>
        <input type="email" class="form-control" formControlName="email" placeholder="Enter email">
      </div>
      <div class="form-group col-md-3">
        <label for="password">Password</label>
        <input type="password" class="form-control" formControlName="password" placeholder="Password">
      </div>

      <button type="submit" class="base--btn-generic col-md-3">Register </button>
    </form>
</div>
