export class MenuItem {
  public id: number;
  public label: string;
  public path: string;
  public isActive: boolean;

  constructor (id: number, label: string, path: string,isActive: boolean) {
    this.id = id;
    this.label = label;
    this.path = path;
    this.isActive = isActive;
  }
}
