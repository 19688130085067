import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { FormArray, FormBuilder, Validators, FormControl, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SidebarState } from "../../../../stores/sidebar/sidebar.reducer";

import Utils  from '../../../../utils/utils'
import { Step } from "src/app/models/Step";
import { refreshSidebar, setStepEcommerce } from "src/app/stores/sidebar/sidebar.actions";
import { ColorPickerService } from "ngx-color-picker";
import { CodeModel } from "@ngstack/code-editor";
import { ROUTES } from 'src/app/utils/constants';
import { SHA1 } from 'crypto-js';
import { switchSelect } from "src/app/stores/navbar/navbar.actions";
import { CreateEcommerceRequest, GetEcommerceRequest, SendEcommerceEmailRequest } from "src/app/models/global.request";
import { EcommerceService } from "../../../../services/ecommerce/ecommerce.service";
import { EcommerceState } from "src/app/stores/ecommerce/ecommerce.reducer";
import { Ecommerce } from "src/app/models/Ecommerce";
import { ToastrService } from "ngx-toastr";
import Formatter from "src/app/utils/formatters";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-create-ecommerce",
  templateUrl: "./create-ecommerce.component.html",
  styleUrls: ["./create-ecommerce.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateEcommerceComponent implements OnInit {

  isEdit = false;

  ecommerceState$: Observable<EcommerceState>;
  sidebarState$: Observable<SidebarState>;
  utils: Utils = new Utils();
  formatter!: Formatter;
  emailResult = {
    complete: false,
    result: '',   // success or error
    message: '',
  };

  existingUsers: any[] = [];
  existingUsersAll: any[] = [];
  emailAlreadyExists = false;
  passwordRegex: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  isVisibleAddNewUser = false;
  showPassword = false;
  isUserProjectValid = {
    valid: true,
    error_msg: '',
  };
  validSurveyLink = false;

  urlRegex = /^https:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  previewDevice: string = 'desktop';
  fileNameDesktop: string = '';
  fileNameMobile: string = '';
  previewImageDesktop: any = '';
  previewImageMobile: any = '';
  expandDimension = false;
  selectedDimension = false;
  expandColor = false;
  expandCloseColor = false;
  selectedColor = false;
  selectedCloseColor = false;
  color = 'rgba(0,0,0,0.5)';
  closeColor = 'rgba(0,0,0,1)';
  maxDisplay = 1;

  showPopupPreview = false;
  showVideo = false;
  isCodeCopiedOrSent = false;
  showAlertConfirmClose = false;

  fileNameHash: string = '';
  codeEditorTheme = 'vs-dark';
  codeEditorModel: CodeModel = {
    language: 'javascript',
    uri: 'main.js',
    value: `<script defer="defer" src="https://epathos.fra1.cdn.digitaloceanspaces.com/${this.fileNameHash}.js"></script>`,
  };
  codeEditorOptions = {
    lineNumbers: true,
    contextmenu: true,
    readOnly: true,
    minimap: {
      enabled: true,
    },
  };

  currentStepList!: Step[];

  stepZeroForm = this.fb.group({
    newName: ["", [Validators.required, this.validateUserProject()]],
    users: this.fb.array([
      this.fb.group({
        userId: ["", [Validators.required]],
        newUser: this.fb.group({
          newMail: ["", [Validators.required, Validators.email, this.validateUserDoesNotExist()]],
          newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
        }, {validator: [this.validateUserProject()]})
      })
    ]),
  });
  stepOneForm = this.fb.group({
    ecommerceType: ["", [Validators.required]],
    triggers: this.fb.array([
      this.fb.group({
        url: ["", [Validators.required, Validators.pattern(this.urlRegex)]],
        delay: [2, [Validators.required, Validators.min(0), Validators.max(1000)]],
        showInSubpages: [false],
      }),
    ]),
  });
  stepTwoForm = this.fb.group({
    surveyLink: ["", [Validators.required, Validators.pattern(this.urlRegex)]],
    coverDesktop: ["", [Validators.required]],
    coverMobile: ["", [Validators.required]],
    surveyDimension: ["", [Validators.required]],
    backgroundColor: ["", [Validators.required]],
    closeColor: ["", [Validators.required]],
    maxDisplay: [1, [Validators.required, Validators.min(1), Validators.max(100)]],
  });
  stepFourForm = this.fb.group({
    email: ["", [Validators.email]],
  });

  ecommerceModule: Ecommerce | null = null;

  constructor(
    private store: Store<{ ecommerceState: EcommerceState, sidebarState: SidebarState }>,
    private ecommerceService: EcommerceService,
    private cpService: ColorPickerService,
    public fb: FormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
  ) {
    this.ecommerceState$ = store.select("ecommerceState");
    this.sidebarState$ = store.select("sidebarState");
    this.formatter = new Formatter();

    this.route.params.subscribe(params => {
      this.userService.getUsers().subscribe(data => {
        this.existingUsersAll = data;
        this.existingUsersAll.sort((user1: any, user2: any) => {
          const aValue = user1.name ? user1.name + ' ' + user1.surname : user1.email;
          const bValue = user2.name ? user2.name + ' ' + user2.surname : user2.email;

          return aValue.localeCompare(bValue);
        });
        this.existingUsers = data.filter((user: any) => user.role_name !== 'admin');
      });

      if (params['id']) {
        this.isEdit = true;
        const request = new GetEcommerceRequest(params['id']);
        this.ecommerceService.getEcommerceById(request).subscribe(data => {
          this.formatter.formatResponseGetEcommerce(data.message).then(data => {
            this.ecommerceModule = data;

            this.isVisibleAddNewUser = false;
            this.stepZeroForm.patchValue({
              newName: this.ecommerceModule.userProject,
            });
            this.users.removeAt(0);
            for (let index = 0; index < this.ecommerceModule.users.length; index++) {
              this.users.push(this.fb.group({
                userId: [this.ecommerceModule.users[index].user_id, [Validators.required]],
                newUser: this.fb.group({
                  newMail: ["", [Validators.required, Validators.email, this.validateUserDoesNotExist()]],
                  newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
                })
              }));
            }

            this.stepOneForm.patchValue({
              ecommerceType: this.ecommerceModule.ecommerceType,
            });
            this.triggers.controls[0].patchValue({
              url: this.ecommerceModule.triggers[0].url,
              delay: ''+this.ecommerceModule.triggers[0].delay,
              showInSubpages: this.ecommerceModule.triggers[0].showInSubpages,
            });
            for (let index = 1; index < this.ecommerceModule.triggers.length; index++) {
              this.triggers.push(this.fb.group({
                url: [this.ecommerceModule.triggers[index].url, [Validators.required, Validators.pattern(this.urlRegex)]],
                delay: [''+this.ecommerceModule.triggers[index].delay, [Validators.required, Validators.min(0), Validators.max(1000)]],
                showInSubpages: [this.ecommerceModule.triggers[index].showInSubpages],
              }));
            }

            this.validSurveyLink = true;
            this.fileNameDesktop = ' ';
            this.fileNameMobile = ' ';
            this.previewImageDesktop = this.ecommerceModule.coverDesktop;
            this.previewImageMobile = this.ecommerceModule.coverMobile;
            this.selectedDimension = true;
            this.selectedColor = true;
            this.selectedCloseColor = true;
            this.color = this.ecommerceModule.backgroundColor;
            this.closeColor = this.ecommerceModule.closeColor;
            this.maxDisplay = this.ecommerceModule.maxDisplay;
            this.stepTwoForm.patchValue({
              surveyLink: this.ecommerceModule.surveyLink,
              coverDesktop: this.ecommerceModule.coverDesktop,
              coverMobile: this.ecommerceModule.coverMobile,
              surveyDimension: this.ecommerceModule.surveyDimension,
              backgroundColor: this.ecommerceModule.backgroundColor,
              closeColor: this.ecommerceModule.closeColor,
              maxDisplay: this.ecommerceModule.maxDisplay,
            });

            this.fileNameHash = this.ecommerceModule.hashFile;
          });
        });
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(refreshSidebar());
    this.sidebarState$.subscribe((sidebarS) => {
      this.currentStepList = sidebarS.createEcommerceStepList;
    });
    this.changeStep(0);
  }

  get triggers() {
    return this.stepOneForm.get('triggers') as FormArray;
  }

  get users() {
    return this.stepZeroForm.get('users') as FormArray;
  }

  onSubmitBackStep(idStep: number): void {
    this.changeStep(idStep - 1);
  }

  onSubmitNextStep(idStep: number): void {
    let isValid = false;
    switch (idStep) {
      case 0:
        if (this.stepZeroForm.controls.users.at(0).controls.userId.valid || this.stepZeroForm.controls.users.at(0).controls.newUser.valid) {
          isValid =  true;
        }
        break;
      case 1:
        if (this.stepOneForm.valid) {
          isValid = true;
        }
        break;
      case 2:
        if (this.stepTwoForm.valid) {
          isValid = true;
          this.previewDevice = 'desktop';
        }
        break;
      case 3:
        if (this.stepFourForm.valid) {
          isValid = true;
        }
        break;
      default:
        break;
    }
    if (isValid) {
      if (idStep == 2) {
        const _triggers: any[] = [];
        Object.keys(this.triggers.controls).forEach(key => {
          const formGroup = this.triggers.get(key) as FormGroup;
          _triggers.push({url: formGroup.controls.url.value, value: formGroup.controls.delay.value, showInSubpages: formGroup.controls.showInSubpages.value});
        });

        this.fileNameHash = SHA1(`${this.stepZeroForm.controls.newName.value}$$${+ new Date()}$$${this.stepTwoForm.controls.surveyLink.value}`).toString();

        this.codeEditorModel.value = `<script defer="defer" src="https://epathos.fra1.cdn.digitaloceanspaces.com/${this.fileNameHash}.js"></script>`;

        const userIds_full = this.stepZeroForm.controls.users.value.filter((user: any) => user.userId);
        const newUsers_full = this.stepZeroForm.controls.users.value.filter((user: any) => user.newUser.newMail !== '' && user.newUser.newPassword !== '');
        const userIds = userIds_full.map((user: any) => parseInt(user.userId));
        const newUsers = newUsers_full.map((user: any) => ({newMail: user.newUser.newMail, newPassword: user.newUser.newPassword}));
        const users = {
          userIds: userIds,
          newUsers: newUsers,
        }

        const payload = new CreateEcommerceRequest(
          users,
          this.stepZeroForm.controls.newName.value!,
          this.fileNameHash,
          {
            triggers: _triggers,
            ecommerceType: this.stepOneForm.controls.ecommerceType.value!,
            surveyLink: this.stepTwoForm.controls.surveyLink.value!,
            coverDesktop: this.previewImageDesktop.includes('epathos.fra1.cdn.digitaloceanspaces') ? '' : this.previewImageDesktop,
            coverMobile: this.previewImageMobile.includes('epathos.fra1.cdn.digitaloceanspaces') ? '' : this.previewImageMobile,
            surveyDimension: this.stepTwoForm.controls.surveyDimension.value!,
            backgroundColor: this.stepTwoForm.controls.backgroundColor.value!,
            closeColor: this.stepTwoForm.controls.closeColor.value!,
            maxDisplay: this.stepTwoForm.controls.maxDisplay.value!,
          }
        );

        this.ecommerceService.createEcommerce(payload).subscribe(data => {
          if (data.message === 'success') {
            let sendEmail = '';
            if (userIds.length > 0) {
              const selectedUser = this.existingUsers.filter((user: any) => user.user_id === userIds[0]);
              sendEmail = selectedUser.length ? selectedUser[0].email : '';
            } else if (newUsers.length > 0) {
              sendEmail = newUsers[0].newMail;
            }
            this.stepFourForm.patchValue({email: sendEmail});
            this.changeStep(idStep + 1);
          } else {
            this.toastr.error("Errore nella creazione del modulo ecommerce", "Errore!");
          }
        });

      } else if (idStep == 3) {
        if (!this.isCodeCopiedOrSent) {
          this.showAlertConfirmClose = true;
        } else {
          this.store.dispatch(switchSelect({selectContentType: 'ecommerce'}));
          this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
        }
      } else {
        this.changeStep(idStep + 1);
      }
    }
  }

  changeStep(idStep: number): void {
    const tempStepsList = this.currentStepList.map((tStep) => {
      tStep = { ...tStep, isActive: false, isCompleted : (idStep >tStep.id)? true : false};

      return tStep;
    });

    const nextStep = tempStepsList.find((tStep) => tStep.id === idStep);
    let index = 0;
    if (nextStep) {
      index = tempStepsList.map((tStep) => tStep.id).indexOf(nextStep?.id);
    }

    if (nextStep) {
      nextStep.isActive = true;
      tempStepsList[index] = nextStep;
      this.store.dispatch(
        setStepEcommerce({ currentCreateEcommerceStep: nextStep, createEcommerceStepList: tempStepsList })
      );
    }
  }

  isStepZeroValid() {
    if ((this.stepZeroForm.controls.users.at(0).controls.userId.valid || this.stepZeroForm.controls.users.at(0).controls.newUser.valid) && this.stepZeroForm.controls.newName.valid) {
      return true;
    } else {
      return false;
    }
  }

  onClickAddNewUser() {
    this.stepZeroForm.controls.users.at(0).controls.userId.patchValue('');
    this.stepZeroForm.controls.users.at(0).controls.userId.disable();
    this.isVisibleAddNewUser = true;
  }

  onClickCancelUser() {
    this.stepZeroForm.controls.users.at(0).controls.newUser.controls.newMail.patchValue('');
    this.stepZeroForm.controls.users.at(0).controls.newUser.controls.newPassword.patchValue('');
    this.stepZeroForm.controls.users.at(0).controls.userId.enable();
    this.isVisibleAddNewUser = false;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  onClickAddNewUrl() {
    this.triggers.push(this.fb.group({
      url: ["", [Validators.required, Validators.pattern(this.urlRegex)]],
      delay: [2, [Validators.required, Validators.min(0), Validators.max(1000)]],
      showInSubpages: [false],
    }));
  }

  onClickCancelUrl(index: number) {
    this.triggers.removeAt(index);
  }

  onFileChange(event: any, deviceType: string) {
    if (event.target.files.length > 0) {
      const filereader = new FileReader();
      filereader.readAsDataURL(event.target.files[0]);
      filereader.onload = (evt) => {
        const base64 = evt.target?.result;
        if (deviceType === 'desktop') {
          this.fileNameDesktop = event.target.files[0].name;
          this.stepTwoForm.get('coverDesktop')?.setValue(event.target.files[0]);
          this.previewImageDesktop = base64;
        } else if (deviceType === 'mobile') {
          this.fileNameMobile = event.target.files[0].name;
          this.stepTwoForm.get('coverMobile')?.setValue(event.target.files[0]);
          this.previewImageMobile = base64;
        }
      }
    }
  }

  onColorChange(color: string) {
    this.selectedColor = true;
    this.stepTwoForm.controls.backgroundColor.patchValue(color);
  }

  onCloseColorChange(color: string) {
    this.selectedCloseColor = true;
    this.stepTwoForm.controls.closeColor.patchValue(color);
  }

  onClickCopyCode() {
    this.isCodeCopiedOrSent = true;
    navigator.clipboard.writeText(this.codeEditorModel.value);
  }

  onClickSendEmail() {
    const request = new SendEcommerceEmailRequest(
      this.stepFourForm.controls.email.value!,
      this.codeEditorModel.value,
      this.stepZeroForm.controls.newName.value!,
    )
    this.ecommerceService.sendEcommerceEmail(request).subscribe(data => {
      this.isCodeCopiedOrSent = true;
      this.emailResult = {
        complete: true,
        result: 'success',
        message: 'Il codice è stato inviato con successo',
      }
    })
  }

  onClickShowPopupPreview() {
    this.showPopupPreview = true;
  }

  onClickHidePopupPreview() {
    this.showVideo = false;
    this.showPopupPreview = false;
  }

  onClickClosePage() {
    this.store.dispatch(switchSelect({selectContentType: 'ecommerce'}));
    this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
  }

  validateUserProject(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isNameUsed = false;
      const newName = control.value;

      if (this.ecommerceState$) {
        this.ecommerceState$.subscribe(ecommS => {
          if (ecommS) {
            isNameUsed = ecommS.ecommerceList.find(obj => obj.userProject === newName && obj.configId !== this.ecommerceModule?.configId) ? true : isNameUsed;
          }
        });
      }

      this.isUserProjectValid = {
        valid: isNameUsed ? false : true,
        error_msg: isNameUsed ? "Nome progetto già utilizzato" : "",
      }

      return isNameUsed ? { forbidden: { message: "Nome progetto già utilizzato" } } : null;
    };
  }

  validateUserDoesNotExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newEmail = control.value;
      const emailExists = this.existingUsersAll.filter((user: any) => user.email === newEmail);
      this.emailAlreadyExists = emailExists.length > 0 ? true : false;

      return emailExists.length > 0 ? { forbidden: { message: "Esiste già un utente con questa email" } } : null;
    };
  }

}

