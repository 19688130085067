<div *ngIf="(sidebarState$ | async); let sidebarState;">
  <div id="create-survey">
    <span class="step-subtitle">{{sidebarState?.currentStep?.subtitle}}</span>
    <div [ngSwitch]="sidebarState?.currentStep?.id">
      <div *ngSwitchCase="'0'">
        <form class="base--form-generic" (ngSubmit)="onSubmitNextStep(0)">
          <div class="code-editor-container">
            <ngs-code-editor
            style="height: 100%"
            [theme]="theme"
            [codeModel]="model"
            [options]="options"
            (valueChanged)="onCodeChanged($event)"
            >
            </ngs-code-editor>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn-next" [ngClass]="[(!this.isValidJSON)? 'invalid' : '']" [disabled]="!this.isValidJSON"> Avanti </button>
          </div>
        </form>
      </div>
      <div *ngSwitchCase="'1'">
        <form class="base--form-generic" [formGroup]="stepOneForm" (ngSubmit)="onSubmitNextStep(1)">

          <div class="form-group ">
            <label for="customerName">Nome cliente </label>
            <div class="form-row">
              <div class="form-group" >
                <div class="input-wrapper">
                  <input
                    id="customerName"
                    type='text'
                    formControlName="customerName"
                    readonly
                    >
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngFor="let item of users.value; let i = index; trackBy: trackByFn">
            <label *ngIf="i === 0">Users</label>
            <div formArrayName="users">
              <div [formGroupName]="i" class="users">
                <div class="form-row horizontal" *ngIf="!isVisibleAddNewUser[i]">
                  <select formControlName="userId" required>
                    <option [defaultSelected]="true" value="">Choose user from list</option>
                    <option *ngFor="let user of existingUsers" [value]="user.user_id">{{ user.name ? user.name + ' ' + user.surname : user.email }}</option>
                  </select>
                  <span class="text">or</span>
                  <button type="button" class="btn-default" (click)="onClickCreateNewUser(i)">create new</button>
                  <span *ngIf="i > 0" (click)="onClickRemoveUser(item)"><a>cancel</a></span>
                </div>

                <div class="form-group" *ngIf="isVisibleAddNewUser[i]">
                  <div formGroupName="newUser">
                    <div class="form-row horizontal">
                      <div class="error-msg-wrapper">
                        <input type="email" formControlName="newMail" placeholder="Email" required email>
                        <div *ngIf="emailAlreadyExists[i]" class="error-msg">Email già registrata</div>
                      </div>
                      <div class="input-wrapper">
                        <input id="newUserPassword" [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" placeholder="Password" autocomplete="new-password" required>
                        <div class="input-icon"  (click)='showHidePassword()'>
                          <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8263 5.81072C16.1987 6.29048 16.1987 6.93816 15.8263 7.41715C14.6532 8.9253 11.8388 12.0306 8.55292 12.0306C5.26706 12.0306 2.45263 8.9253 1.27956 7.41715C1.09836 7.18743 1 6.90482 1 6.61393C1 6.32304 1.09836 6.04044 1.27956 5.81072C2.45263 4.30256 5.26706 1.19727 8.55292 1.19727C11.8388 1.19727 14.6532 4.30256 15.8263 5.81072V5.81072Z" stroke="#A8AAB5" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.55294 8.93583C9.85476 8.93583 10.9101 7.89649 10.9101 6.6144C10.9101 5.33231 9.85476 4.29297 8.55294 4.29297C7.25113 4.29297 6.1958 5.33231 6.1958 6.6144C6.1958 7.89649 7.25113 8.93583 8.55294 8.93583Z" stroke="#A8AAB5" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <ng-container *ngIf="users.at(i).get('newUser')?.get('newPassword')?.dirty && users.at(i).get('newUser')?.get('newPassword')?.invalid">
                          <div class="error-msg-wrapper">
                            <div class="error-msg">
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('^(?=.*[A-Z])')">At least one uppercase letter.</span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('(?=.*[a-z])')">At least one lowercase letter.</span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('(.*[0-9].*)')">At least one digit.</span>
                              <span *ngIf="!users.at(i).get('newUser')?.get('newPassword')?.value?.match('.{8,}')">At least 8 characters long.</span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <span class="text">or</span>
                      <button type="button" class="btn-default" (click)="onClickSelectUserFromList(i)">select from list</button>
                      <span *ngIf="i > 0" (click)="onClickRemoveUser(item)"><a>cancel</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button type="button" class="btn-add-user" (click)="onClickAddUser()" [disabled]="!isLastUserValid()">+ add another user</button>

          <div class="form-actions">
            <div (click)="onSubmitBackStep(1)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[!isStepOneValid() ? 'invalid' : '']" [disabled]="!isStepOneValid()"> Avanti </button>
          </div>
        </form>
      </div>
      <div *ngSwitchCase="'2'">
        <form class="base--form-generic" [formGroup]="stepTwoForm" (ngSubmit)="onSubmitNextStep(2)">
          <div class="form-group ">
            <div class="form-row">
              <div class="form-group">
                <label for="surveyName">Select survey</label>
                <div class="input-wrapper">
                  <input
                    id="surveyName"
                    type='text'
                    formControlName="surveyName"
                    readonly
                    >
                </div>
              </div>
              <div class="form-group">
                <label for="surveyNewName">Name visible in dashboard</label>
                <div class="input-wrapper">
                  <input
                    id="surveyNewName"
                    type='text'
                    formControlName="surveyNewName">
                </div>
                <div class="error" *ngIf="surveyNewName?.invalid && (surveyNewName?.dirty ||surveyNewName?.touched)">
                  <sup>*</sup>Please provide new name
                </div>
              </div>
            </div>
          </div>
          <div class="form-group ">
            <div class="form-row">
              <div class="form-group">
                <label for="surveyDescription">Description</label>
                <div class="input-wrapper">
                  <input
                    id="surveyDescription"
                    type='text'
                    formControlName="surveyDescription"
                    >
                </div>
              </div>
            </div>
          </div>
          <div class="form-group ">
            <label for="customerName">Cover</label>
            <div class="form-group">
              <div class="base--dropzone">
                <div class="row">
                  <div class="col">
                    <div class="form-row">
                      <div class="image-preview">
                        <img *ngIf="croppedImage" [src]="croppedImage"
                        />
                        <svg *ngIf="!croppedImage"  width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="137" height="137" rx="17" fill="#A8AAB5"/>
                          <g clip-path="url(#clip0_648_2462)">
                          <path d="M75.2083 57H61.7917C60.5213 57.0015 59.3034 57.5068 58.4051 58.4051C57.5068 59.3034 57.0015 60.5213 57 61.7917V75.2083C57.0015 76.4787 57.5068 77.6966 58.4051 78.5949C59.3034 79.4932 60.5213 79.9985 61.7917 80H75.2083C76.4787 79.9985 77.6966 79.4932 78.5949 78.5949C79.4932 77.6966 79.9985 76.4787 80 75.2083V61.7917C79.9985 60.5213 79.4932 59.3034 78.5949 58.4051C77.6966 57.5068 76.4787 57.0015 75.2083 57V57ZM61.7917 58.9167H75.2083C75.9708 58.9167 76.7021 59.2196 77.2413 59.7587C77.7804 60.2979 78.0833 61.0292 78.0833 61.7917V75.2083C78.0817 75.6353 77.9834 76.0563 77.7958 76.4398L69.0146 67.6586C68.5697 67.2135 68.0414 66.8604 67.4599 66.6196C66.8785 66.3787 66.2553 66.2547 65.626 66.2547C64.9966 66.2547 64.3734 66.3787 63.792 66.6196C63.2105 66.8604 62.6823 67.2135 62.2373 67.6586L58.9167 70.9783V61.7917C58.9167 61.0292 59.2196 60.2979 59.7587 59.7587C60.2979 59.2196 61.0292 58.9167 61.7917 58.9167V58.9167ZM61.7917 78.0833C61.0292 78.0833 60.2979 77.7804 59.7587 77.2413C59.2196 76.7021 58.9167 75.9708 58.9167 75.2083V73.6884L63.5914 69.0137C63.8584 68.7465 64.1754 68.5346 64.5244 68.39C64.8733 68.2454 65.2473 68.1709 65.625 68.1709C66.0027 68.1709 66.3767 68.2454 66.7256 68.39C67.0746 68.5346 67.3916 68.7465 67.6586 69.0137L76.4398 77.7958C76.0563 77.9834 75.6353 78.0817 75.2083 78.0833H61.7917Z" fill="white"/>
                          <path d="M72.3334 67.0623C72.9968 67.0623 73.6453 66.8656 74.1969 66.4971C74.7485 66.1285 75.1784 65.6047 75.4323 64.9918C75.6861 64.3789 75.7526 63.7045 75.6231 63.0538C75.4937 62.4032 75.1743 61.8055 74.7052 61.3364C74.2361 60.8673 73.6384 60.5479 72.9878 60.4185C72.3371 60.289 71.6627 60.3555 71.0498 60.6093C70.4369 60.8632 69.9131 61.2931 69.5445 61.8447C69.176 62.3963 68.9792 63.0448 68.9792 63.7082C68.9792 64.5978 69.3326 65.4509 69.9617 66.0799C70.5907 66.709 71.4438 67.0623 72.3334 67.0623V67.0623ZM72.3334 62.2707C72.6177 62.2707 72.8957 62.355 73.132 62.5129C73.3684 62.6709 73.5527 62.8954 73.6615 63.1581C73.7703 63.4207 73.7988 63.7098 73.7433 63.9886C73.6878 64.2675 73.5509 64.5236 73.3499 64.7246C73.1488 64.9257 72.8927 65.0626 72.6139 65.1181C72.335 65.1735 72.046 65.1451 71.7833 65.0362C71.5206 64.9274 71.2961 64.7432 71.1382 64.5068C70.9802 64.2704 70.8959 63.9925 70.8959 63.7082C70.8959 63.3269 71.0474 62.9613 71.3169 62.6917C71.5865 62.4221 71.9522 62.2707 72.3334 62.2707V62.2707Z" fill="white"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_648_2462">
                          <rect width="23" height="23" fill="white" transform="translate(57 57)"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="image-controls">
                        <div class="base--btn-type-a" *ngIf="croppedImage" (click)="toggleCropImage()" >
                          Crop image
                        </div>
                        <div *ngIf="croppedImage" (click)="clearSurveyCover()" >
                          <span>Delete</span>
                        </div>
                      </div>
                    </div>
                    <div class="drag-area">
                      <div class="icon">
                        <i class="fas fa-cloud-upload-alt"></i>
                      </div>
                      <div class="title">Drop your image here, or <span class="azure">browse</span></div>
                      <div class="file-types">JPG, GIF or PNG. Max size of 800K</div>
                      <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
                      <input class="drag-zone"
                      formControlName="surveyFileSourceCover"
                      type="file" accept=".jpg, .jpeg, .png, .gif"
                      (change)="fileChangeEvent($event)">
                    </div>
                  </div>
                  <div class="col">
                    <image-cropper
                      class="image-cropper"
                      [ngClass]="[(isVisibleCropImage)? 'show' : 'hidden']"
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="4 / 4"
                      [resizeToWidth]="300"
                      format="png" (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                      (loadImageFailed)="loadImageFailed()"
                      >
                    </image-cropper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <div (click)="onSubmitBackStep(2)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.stepTwoForm.valid)? 'invalid' : '']" [disabled]="!this.stepTwoForm.valid"> Avanti </button>
          </div>
        </form>
      </div>
      <div *ngSwitchCase="'3'">
        <form class="base--form-generic" [formGroup]="stepThreeForm" (ngSubmit)="onSubmitNextStep(3)">

          <div class="fields-wrapper">
            <mat-checkbox
              class="example-margin"
              [checked]="this.isAllSelected"
              (change)="setAllSelected($event.checked)">
              Select All
            </mat-checkbox>
            <mat-form-field class="d-flex justify-content-end" appearance="standard">
              <div class="form-group">
                <div class="form-row">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                  </svg>
                  <input matInput (keyup)="applyFilterThreeForm($event)" placeholder="Search" #input>
                </div>
              </div>
            </mat-form-field>
          </div>



          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSourceQuestions" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  ID list </th>
                  <td mat-cell *matCellDef="let row">
                  <input type="checkbox" [checked]="hasBeenCheckedQuestion(row)" (click)="onCheckboxChangeQuestion($event, row)">
                  {{(row.id + 1)}}
                </td>
              </ng-container>

              <!-- Progress Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row"> {{row.description}}</td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedSourceColumns"></tr>
              <tr [ngClass]="[(hasBeenCheckedQuestion(row))? 'checked' : '']" mat-row *matRowDef="let row; columns: displayedSourceColumns;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
          <!-- <div >
            <div *ngFor="let question of this.filterQuestionsListForm.value; let index=index">
              <div >
                {{question.id}}
                {{question.description}}
                {{question.answers}}
              </div>
            </div>
          </div> -->
        <div class="form-actions">
          <div (click)="onSubmitBackStep(3)" class="btn-back"> Indietro </div>
          <button type="submit" class="btn-next" [ngClass]="[(!this.stepThreeForm.valid)? 'invalid' : '']" [disabled]="!this.stepThreeForm.valid"> Avanti </button>
        </div>
        </form>
      </div>
      <!-- Question Overview -->
      <div *ngSwitchCase="'4'">
        <form class="base--form-generic" [formGroup]="stepFourForm" (ngSubmit)="onSubmitNextStep(4)">
          <div class="d-flex justify-content-between" >
            <div class="d-flex justify-content-center  gap-3">
              <!-- <select id="optionsTouchPointAvaible" size="5">
                <option *ngFor="let touchpoint of touchPointList" >
                  {{touchpoint.viewValue}}
                </option>
              </select> -->
              <div class="form-group ">
                <div class="form-row">
                  <input type="text" style="width: inherit;" class="form-control" formControlName="newTouchPoint" id="newTouchPoint" placeholder="Inserisci Touch Point">
                  <div (click)="onClickAddTouchPoint()" class="btn-default slim">
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_509_2721)">
                        <path d="M9.65117 11.312C9.33584 11.312 9.09217 11.226 8.92017 11.054C8.74817 10.8677 8.66217 10.6168 8.66217 10.3015V6.7755H5.20067C4.89967 6.7755 4.66317 6.69667 4.49117 6.539C4.31917 6.367 4.23317 6.1305 4.23317 5.8295C4.23317 5.5285 4.31917 5.29917 4.49117 5.1415C4.66317 4.9695 4.89967 4.8835 5.20067 4.8835H8.66217V1.465C8.66217 1.14967 8.74817 0.905999 8.92017 0.733999C9.09217 0.561999 9.34301 0.476 9.67267 0.476C9.98801 0.476 10.2245 0.561999 10.3822 0.733999C10.5542 0.905999 10.6402 1.14967 10.6402 1.465V4.8835H14.1017C14.417 4.8835 14.6535 4.9695 14.8112 5.1415C14.9832 5.29917 15.0692 5.5285 15.0692 5.8295C15.0692 6.1305 14.9832 6.367 14.8112 6.539C14.6535 6.69667 14.417 6.7755 14.1017 6.7755H10.6402V10.3015C10.6402 10.6168 10.5542 10.8677 10.3822 11.054C10.2245 11.226 9.98084 11.312 9.65117 11.312Z" fill="white" />
                      </g>
                      <defs>
                        <filter id="filter0_d_509_2721" x="0.233154" y="0.476074" width="18.8359" height="18.8359" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_2721" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_2721" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                    Add Touch Point </div>
                </div>
              </div>

            </div>

            <mat-form-field class="d-flex justify-content-end" appearance="standard">
              <div class="form-group">
                <div class="form-row">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                  </svg>
                  <input matInput (keyup)="applyFilterFourForm($event)" placeholder="Search" #input>
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSelectedQuestions" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID list</th>
                <td mat-cell *matCellDef="let row">
                  {{(row.id + 1)}}
                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="touchPoint">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Touch point </th>
                <td mat-cell *matCellDef="let row">
                  <!-- {{row.description}} -->
                  <mat-form-field appearance="fill">
                    <mat-label>Seleziona touch point</mat-label>
                    <mat-select (selectionChange)="onSelectChangePropertyValue($event, 'touchPoint', row)" [value]="returnPropertyValueIfExist('touchPoint',row)">
                      <mat-option *ngFor="let touchpoint of touchPointList" [value]="touchpoint.value">
                        {{touchpoint.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container><!-- description Column -->
              <ng-container matColumnDef="questionType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo di domanda </th>
                <td mat-cell *matCellDef="let row">
                  <mat-form-field appearance="fill">
                    <mat-select (selectionChange)="onSelectChangePropertyValue($event, 'type', row)" [value]="row.type? row.type :returnPropertyValueIfExist('type',row)">
                      <mat-option *ngFor="let questiontype of questionTypeList" [value]="questiontype.value">
                        {{questiontype.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row">
                    <div class="answer-toggle" [ngClass]="row.expanded?'expanded':''" (click)="row.expanded = ! row.expanded">
                      <span>
                        {{row.description}}
                      </span>
                      <div >
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                    <div class="answers-container" [ngClass]="row.expanded?'expanded':''" *ngIf="row.expanded">
                      <ng-container *ngIf="row.type === 'knob' || row.type === 'slider'; else listColor">
                        <ng-container *ngFor="let answer of row.answers; let index = index">
                          <div class="answers-row justify-content-between">
                            <div class="answer-label">Minimo</div>
                            <div class="answers-dots">{{ answer.range_start }}</div>
                          </div>
                          <div class="answers-row justify-content-between">
                            <div class="answer-label">Soglia rosso-giallo</div>
                            <div class="answers-dots">
                              <input type="number" name="x_start" class="form-control text-end" [value]="answer.x_start" (change)="onChangeUpdateRangeValue($event, index, row, 'x_start')" required>
                            </div>
                          </div>
                          <div class="answers-row justify-content-between">
                            <div class="answer-label">Soglia giallo-verde</div>
                            <div class="answers-dots">
                              <input type="number" name="y_start" class="form-control text-end" [value]="answer.y_start" (change)="onChangeUpdateRangeValue($event, index, row, 'y_start')" required>
                            </div>
                          </div>
                          <div class="answers-row justify-content-between">
                            <div class="answer-label">Massimo</div>
                            <div class="answers-dots">{{ answer.range_end }}</div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-template #listColor>
                        <div class="answers-row" *ngFor="let answer of row.answers; let index=index">
                            <div *ngIf="answer.urlImage && (answer.urlImage.includes('.png') || answer.urlImage.includes('.jpg') || answer.urlImage.includes('.jpeg') || answer.urlImage.includes('.gif') || answer.urlImage.includes('.svg'))" class="answers-image"><img [src]="'https://epathoscdn.it/TEST/img/'+answer?.urlImage" alt="Answer Image" loading="lazy"/></div>
                            <div class="answers-label"><span>{{answer.description}}</span></div>
                            <div class="answers-dots">
                              <span class="dot grey" [ngClass]="answer.color === 'grey'?'selected':''" (click)="onSelectChangeAnswerColor('grey',index, row)"></span>
                              <span class="dot green" [ngClass]="answer.color === 'green'?'selected':''" (click)="onSelectChangeAnswerColor('green',index, row)"></span>
                              <span class="dot orange" [ngClass]="answer.color === 'orange'?'selected':''"  (click)="onSelectChangeAnswerColor('orange',index, row)"></span>
                              <span class="dot red" [ngClass]="answer.color === 'red'?'selected':''" (click)="onSelectChangeAnswerColor('red',index, row)"></span>
                            </div>
                        </div>
                      </ng-template>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>

          <div *ngIf="this.isValidStepFour && !isValidKnobAndSlider()" class="form-error text-end" style="font-size: 14px; color: #DE6067;">
            Compila correttamente i campi delle domande knob e/o slider
          </div>
          <div class="form-actions">
            <div (click)="onSubmitBackStep(4)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.isValidStepFour || !isValidKnobAndSlider())? 'invalid' : '']" [disabled]="!this.isValidStepFour || !isValidKnobAndSlider()"> Avanti </button>
          </div>
        </form>
      </div>
      <!-- Satisfaction -->
      <div *ngSwitchCase="'5'">
        <form class="base--form-generic" [formGroup]="stepFiveForm" (ngSubmit)="onSubmitNextStep(5)">
          <mat-form-field class="d-flex justify-content-end" appearance="standard">
            <div class="form-group">
              <div class="form-row">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                </svg>
                <input matInput (keyup)="applyFilterFiveForm($event)" placeholder="Search" #input>
              </div>
            </div>
          </mat-form-field>
          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSelectedSatisfaction" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID list</th>
                <td mat-cell *matCellDef="let row">
                  <input type="checkbox" [checked]="hasBeenCheckedSatisfaction(row)" (click)="onCheckboxChangeSatisfaction($event, row)">
                  {{(row.id + 1)}}
                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="touchPoint">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Touch point </th>
                <td mat-cell *matCellDef="let row">
                  <!-- {{row.description}} -->
                  <mat-form-field appearance="fill">
                    <mat-select [disabled]="true"  [value]="returnPropertyValueIfExist('touchPoint',row)">
                      <mat-option *ngFor="let touchpoint of touchPointList" [value]="touchpoint.value">
                        {{touchpoint.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container><!-- description Column -->

              <!-- description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row">
                    <div>
                      <span>
                        {{row.description}}
                      </span>
                      <div >
                        <!-- <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                      </div>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedSatisfactionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedSatisfactionColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
          <!-- <div >
            <div *ngFor="let question of satisfactionListForm.value; let index=index">
              <div >
                {{question.id}}
                {{question.description}}
                {{question.answers}}
              </div>
            </div>
          </div> -->
          <div class="form-actions">
            <div (click)="onSubmitBackStep(5)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.stepFiveForm.valid)? 'invalid' : '']" [disabled]="!this.stepFiveForm.valid"> Avanti </button>
          </div>
        </form>
      </div>
      <div *ngSwitchCase="'6'">
        <form class="base--form-generic" [formGroup]="stepSixForm" (ngSubmit)="onSubmitNextStep(6)">
          <mat-form-field class="d-flex justify-content-end" appearance="standard">
            <div class="form-group">
              <div class="form-row">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                </svg>
                <input matInput (keyup)="applyFilterSixForm($event)" placeholder="Search" #input>
              </div>
            </div>
          </mat-form-field>
          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSelectedNPS" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID list</th>
                <td mat-cell *matCellDef="let row">
                  <input type="checkbox" [checked]="hasBeenCheckedNPS(row)" (click)="onCheckboxChangeNPS($event, row)">
                  {{(row.id + 1)}}
                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="touchPoint">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Touch point </th>
                <td mat-cell *matCellDef="let row">
                  <!-- {{row.description}} -->
                  <mat-form-field appearance="fill">
                    <mat-select [disabled]="true"  [value]="returnPropertyValueIfExist('touchPoint',row)">
                      <mat-option *ngFor="let touchpoint of touchPointList" [value]="touchpoint.value">
                        {{touchpoint.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container><!-- description Column -->

              <!-- description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row">
                    <div>
                      <span>
                        {{row.description}}
                      </span>
                      <div >
                        <!-- <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                      </div>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedNPSColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedNPSColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
          <!-- <div >
            <div *ngFor="let question of satisfactionListForm.value; let index=index">
              <div >
                {{question.id}}
                {{question.description}}
                {{question.answers}}
              </div>
            </div>
          </div> -->
          <div class="form-actions">
            <div (click)="onSubmitBackStep(6)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.stepSixForm.valid)? 'invalid' : '']" [disabled]="!this.stepSixForm.valid"> Avanti </button>
          </div>
        </form>
      </div>
      <!-- End Point -->
      <div *ngSwitchCase="'7'">
        <form class="base--form-generic" [formGroup]="stepSevenForm" (ngSubmit)="onSubmitNextStep(7)">
          <mat-form-field class="d-flex justify-content-end" appearance="standard">
            <div class="form-group">
              <div class="form-row">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                </svg>
                <input matInput (keyup)="applyFilterSevenForm($event)" placeholder="Search" #input>
              </div>
            </div>
          </mat-form-field>
          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSelectedEndPoint" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID list</th>
                <td mat-cell *matCellDef="let row">
                  <input type="checkbox" [checked]="hasBeenCheckedEndPoint(row)" (click)="onCheckboxChangeEndPoint($event, row)">
                  {{(row.id + 1)}}
                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="touchPoint">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Touch point </th>
                <td mat-cell *matCellDef="let row">
                  <!-- {{row.description}} -->
                  <mat-form-field appearance="fill">
                    <mat-select [disabled]="true"  [value]="returnPropertyValueIfExist('touchPoint',row)">
                      <mat-option *ngFor="let touchpoint of touchPointList" [value]="touchpoint.value">
                        {{touchpoint.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container><!-- description Column -->

              <!-- description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row">
                    <div>
                      <span>
                        {{row.description}}
                      </span>
                      <div >
                        <!-- <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                      </div>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedEndPointColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedEndPointColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
          <!-- <div >
            <div *ngFor="let question of satisfactionListForm.value; let index=index">
              <div >
                {{question.id}}
                {{question.description}}
                {{question.answers}}
              </div>
            </div>
          </div> -->
          <div class="form-actions">
            <div (click)="onSubmitBackStep(7)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.stepSevenForm.valid)? 'invalid' : '']" [disabled]="!this.stepSevenForm.valid"> Avanti </button>
          </div>
        </form>
      </div>
      <!-- Emotional question -->
      <div *ngSwitchCase="'8'">
        <form class="base--form-generic" [formGroup]="stepEightForm" (ngSubmit)="onSubmitNextStep(8)">
          <mat-form-field class="d-flex justify-content-end" appearance="standard">
            <div class="form-group">
              <div class="form-row">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                </svg>
                <input matInput (keyup)="applyFilterEightForm($event)" placeholder="Search" #input>
              </div>
            </div>
          </mat-form-field>
          <div class="mat-elevation-z8">
            <table class="base--table-survey" mat-table [dataSource]="dataSelectedEmotionalQuestion" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID list</th>
                <td mat-cell *matCellDef="let row">
                  <input type="checkbox" [checked]="hasBeenCheckedEmotionalQuestion(row)" (click)="onCheckboxChangeEmotionalQuestion($event, row)">
                  {{(row.id + 1)}}
                </td>
              </ng-container>
              <!-- description Column -->
              <ng-container matColumnDef="touchPoint">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Touch point </th>
                <td mat-cell *matCellDef="let row">
                  <!-- {{row.description}} -->
                  <mat-form-field appearance="fill">
                    <mat-select [disabled]="true"  [value]="returnPropertyValueIfExist('touchPoint',row)">
                      <mat-option *ngFor="let touchpoint of touchPointList" [value]="touchpoint.value">
                        {{touchpoint.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container><!-- description Column -->

              <!-- description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row">
                    <div>
                      <span>
                        {{row.description}}
                      </span>
                      <div >
                      </div>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedEmotionalQuestionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedEmotionalQuestionColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
          <div class="form-actions">
            <div (click)="onSubmitBackStep(8)" class="btn-back"> Indietro </div>
            <button type="submit" class="btn-next" [ngClass]="[(!this.stepEightForm.valid)? 'invalid' : '']" [disabled]="!this.stepEightForm.valid"> Crea survey </button>
          </div>
        </form>
      </div>
      <div *ngSwitchDefault> </div>
    </div>
  </div>
</div>
