import { Step } from 'src/app/models/Step';
import { MenuItem } from 'src/app/models/MenuItem';


import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const toggle = createAction('[Sidebar Component] Toggle');

export const setStep = createAction('[Sidebar Component] Change Step and Step List',
    props<{ currentStep: Step , stepList: Step[]}>()
);
export const setMenuItem = createAction('[Sidebar Component] Change Menu and Menu List',
    props<{ currentMenuItem: MenuItem , menuItemList: MenuItem[]}>()
);

export const setStepEcommerce = createAction('[Sidebar Component] Change Step and Step List for Ecommerce Module creation',
    props<{ currentCreateEcommerceStep: Step , createEcommerceStepList: Step[]}>()
);

export const setStepEditSurvey = createAction('[Sidebar Component] Change Step and Step List for edit Survey',
    props<{ currentEditSurveyStep: Step , editSurveyStepList: Step[]}>()
);

export const refreshSidebar = createAction('[Sidebar Component] Refresh Sidebar');

