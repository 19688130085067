import { Step } from 'src/app/models/Step';
import { MenuItem } from 'src/app/models/MenuItem';

import { createReducer, on } from '@ngrx/store';
import { toggle, setStep, setMenuItem, setStepEcommerce, refreshSidebar, setStepEditSurvey } from './sidebar.actions';

import { ROUTES } from '../../utils/constants'
export const initialState: SidebarState = {
  isOpenSidebar: true,
  currentStep: new Step(0, "Insert JSON","Incolla qui sotto il JSON generato", true, false),
  stepList : [
    {
      id: 0,
      label: "Insert JSON",
      subtitle:"Incolla qui sotto il JSON generato",
      isActive: true,
      isCompleted: false,
    },
    {
      id: 1,
      label: "Client",
      subtitle:"Seleziona i clienti ai quali vuoi assegnare la survey e/o crea gli utenti",
      isActive: false,
      isCompleted: false,
    },
    {
      id: 2,
      label: "Select survey",
      subtitle:"Inserisci il nome della survey e carica l’immagine della cover",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 3,
      label: "Domande filtro",
      subtitle:"Seleziona le domande che devono diventare filtri",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 4,
      label: "Questions overview",
      subtitle:"Seleziona il colore per ogni risposta",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 5,
      label: "Satisfaction",
      subtitle:"Seleziona la domanda satisfaction",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 6,
      label: "N.P.S",
      subtitle:"Seleziona la domanda N.P.S.",
      isActive: false,
      isCompleted: false,

    },
    {
      id: 7,
      label: "End point",
      subtitle:"Seleziona la domanda di end point",
      isActive: false,
      isCompleted: false,

    },
    {
      id: 8,
      label: "Emotional question",
      subtitle:"Seleziona la domanda di emotional",
      isActive: false,
      isCompleted: false,

    }
  ],
  currentMenuItem: new MenuItem(1,"Overview", ROUTES.ROUTE_EMOTIONAL_OVERVIEW, true),
  menuItemList :[
    new MenuItem(1,"Overview", ROUTES.ROUTE_EMOTIONAL_OVERVIEW, true),
    new MenuItem(2,"Dashboard", ROUTES.ROUTE_DASHBOARD, false),
    new MenuItem(3,"Questions overview", ROUTES.ROUTE_QUESTIONS_OVERVIEW,false),
    new MenuItem(4,"Satisfaction", ROUTES.ROUTE_SATISFACTION, false),
    new MenuItem(5,"Polarization",ROUTES.ROUTE_POLARIZATION,false),
    new MenuItem(6,"N.P.S",ROUTES.ROUTE_NPS,false),
    new MenuItem(7,"Analysis by aggregation", ROUTES.ROUTE_AGGREGATION,false),
    new MenuItem(8,"Open text boxes",ROUTES.ROUTE_TEXT_BOXES,false),
  ],
  currentCreateEcommerceStep: new Step(0, "Cliente", "inserisci il cliente", true, false),
  createEcommerceStepList : [
    {
      id: 0,
      label: "Cliente",
      subtitle:"Inserisci il cliente",
      isActive: true,
      isCompleted: false,
    },
    {
      id: 1,
      label: "Link website",
      subtitle:"Inserisci il link del sito web, la tipologia e il delay della comparsa del pop-up",
      isActive: false,
      isCompleted: false,
    },
    {
      id: 2,
      label: "Personalizza survey",
      subtitle:"Inserisci il link della survey",
      isActive: false,
      isCompleted: false,
    },
    {
      id: 3,
      label: "Codice embed",
      subtitle:"Copia il codice Javascript generato e incollalo nel footer pagina html del sito di ecommerce",
      isActive: false,
      isCompleted: false,
    },
  ],
  currentEditSurveyStep: new Step(0, "Client", "Seleziona i clienti ai quali vuoi assegnare la survey e/o crea gli utenti", true, false),
  editSurveyStepList: [
    {
      id: 0,
      label: "Client",
      subtitle:"Seleziona i clienti ai quali vuoi assegnare la survey e/o crea gli utenti",
      isActive: true,
      isCompleted: false,
    },
    {
      id: 1,
      label: "Select survey",
      subtitle:"Inserisci il nome della survey e carica l’immagine della cover",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 2,
      label: "Domande filtro",
      subtitle:"Seleziona le domande che devono diventare filtri",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 3,
      label: "Questions overview",
      subtitle:"Seleziona il colore per ogni risposta",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 4,
      label: "Satisfaction",
      subtitle:"Seleziona la domanda satisfaction",
      isActive: false,
      isCompleted: false,

    }
    ,
    {
      id: 5,
      label: "N.P.S",
      subtitle:"Seleziona la domanda N.P.S.",
      isActive: false,
      isCompleted: false,

    },
    {
      id: 6,
      label: "End point",
      subtitle:"Seleziona la domanda di end point",
      isActive: false,
      isCompleted: false,

    },
    {
      id: 7,
      label: "Emotional question",
      subtitle:"Seleziona la domanda di emotional",
      isActive: false,
      isCompleted: false,

    }
  ]
};
export interface SidebarState {
  isOpenSidebar: boolean;
  currentStep: Step;
  stepList: Step[];
  currentMenuItem: MenuItem;
  menuItemList: MenuItem[];
  currentCreateEcommerceStep: Step;
  createEcommerceStepList: Step[];
  currentEditSurveyStep: Step;
  editSurveyStepList: Step[];
}

export const SidebarReducer = createReducer(
  initialState,
  on(toggle, (state) => ({
    ...state,
    isOpenSidebar: !state.isOpenSidebar,
  })),
  on(setStep, (state, { currentStep, stepList }) => ({
    ...state,
    currentStep,
    stepList,
  })),
  on(setMenuItem, (state, { currentMenuItem, menuItemList }) => ({
    ...state,
    currentMenuItem,
    menuItemList,
  })),
  on(setStepEcommerce, (state, { currentCreateEcommerceStep, createEcommerceStepList }) => ({
    ...state,
    currentCreateEcommerceStep,
    createEcommerceStepList,
  })),
  on(setStepEditSurvey, (state, { currentEditSurveyStep, editSurveyStepList }) => ({
    ...state,
    currentEditSurveyStep,
    editSurveyStepList,
  })),
  on(refreshSidebar, () => initialState),
);
