<div id="confirm-password-wrapper">
  <form class="base--form-generic row" [formGroup]="confirmPasswordForm" (ngSubmit)="onSubmitConfirmPassword()">
    <div class="form-group col-md-3">
      <label for="password">Password</label>
      <input type="password" class="form-control" formControlName="password" placeholder="Password" autocomplete="new-password" required autofocus>
    </div>
    <div class="form-group col-md-3">
      <label for="password">Confirm password</label>
      <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm password" autocomplete="new-password">
    </div>
    <button type="submit" class="base--btn-generic col-md-3" [disabled]="!this.confirmPasswordForm.valid">Confirm new password </button>
  </form>
</div>
