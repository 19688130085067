<form class="base--form-generic" [formGroup]="selectPeriodForm" (ngSubmit)="onSubmitPeriodList()">
  <div class="form-group">
    <label for="startPeriod">Select period</label>
    <div class="form-row">
        <span class="date-title">Start</span>
        <span class="date-title">End</span>
    </div>

    <div class="form-group" *ngFor="let item  of selectedPeriodsListForm.controls; let i = index">
      <div class="form-row">
        <div class="input-wrapper">
          <input type="text" readonly [value]="item.value.startDate| date :'dd/MM/yyyy'">
        </div>
        <div class="input-wrapper">
          <input type="text" readonly [value]="item.value.endDate| date :'dd/MM/yyyy'">
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="(selectedPeriodsListForm.value.length < 2); else modifyPeriods">
      <div class="form-row">
        <!-- <span>User</span> -->
        <div class="input-wrapper">
          <input id="startPeriod" type="date" formControlName="startPeriod">
        </div>

        <div class="input-wrapper">
          <input id="endPeriod" type="date" formControlName="endPeriod">
        </div>
        <div class="btn-default" *ngIf="(selectedPeriodsListForm.value.length < 2)" (click)="onClickAddNewPeriod()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83001 11.312C5.51467 11.312 5.27101 11.226 5.099 11.054C4.92701 10.8677 4.84101 10.6168 4.84101 10.3015V6.7755H1.3795C1.0785 6.7755 0.842005 6.69667 0.670005 6.539C0.498005 6.367 0.412005 6.1305 0.412005 5.8295C0.412005 5.5285 0.498005 5.29917 0.670005 5.1415C0.842005 4.9695 1.0785 4.8835 1.3795 4.8835H4.84101V1.465C4.84101 1.14967 4.92701 0.905999 5.099 0.733999C5.27101 0.561999 5.52184 0.476 5.85151 0.476C6.16684 0.476 6.40334 0.561999 6.56101 0.733999C6.73301 0.905999 6.81901 1.14967 6.81901 1.465V4.8835H10.2805C10.5958 4.8835 10.8323 4.9695 10.99 5.1415C11.162 5.29917 11.248 5.5285 11.248 5.8295C11.248 6.1305 11.162 6.367 10.99 6.539C10.8323 6.69667 10.5958 6.7755 10.2805 6.7755H6.81901V10.3015C6.81901 10.6168 6.73301 10.8677 6.56101 11.054C6.40334 11.226 6.15967 11.312 5.83001 11.312Z" fill="white"/>
            </svg>
            Add new period
        </div>
        <div class="btn-circle" *ngIf="(selectedPeriodsListForm.value.length > 0)" (click)="onClickRemoveLastPeriod()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83001 11.312C5.51467 11.312 5.27101 11.226 5.099 11.054C4.92701 10.8677 4.84101 10.6168 4.84101 10.3015V6.7755H1.3795C1.0785 6.7755 0.842005 6.69667 0.670005 6.539C0.498005 6.367 0.412005 6.1305 0.412005 5.8295C0.412005 5.5285 0.498005 5.29917 0.670005 5.1415C0.842005 4.9695 1.0785 4.8835 1.3795 4.8835H4.84101V1.465C4.84101 1.14967 4.92701 0.905999 5.099 0.733999C5.27101 0.561999 5.52184 0.476 5.85151 0.476C6.16684 0.476 6.40334 0.561999 6.56101 0.733999C6.73301 0.905999 6.81901 1.14967 6.81901 1.465V4.8835H10.2805C10.5958 4.8835 10.8323 4.9695 10.99 5.1415C11.162 5.29917 11.248 5.5285 11.248 5.8295C11.248 6.1305 11.162 6.367 10.99 6.539C10.8323 6.69667 10.5958 6.7755 10.2805 6.7755H6.81901V10.3015C6.81901 10.6168 6.73301 10.8677 6.56101 11.054C6.40334 11.226 6.15967 11.312 5.83001 11.312Z" fill="white"/>
            </svg>
        </div>
      </div>
    </div>
    <ng-template #modifyPeriods>
      <div class="form-group">
        <div class="form-row">
          <div class="input-wrapper">
            <div id="custom-modify-button" class="btn-default" *ngIf="(selectedPeriodsListForm.value.length > 0)" (click)="onClickRemoveLastPeriod()">
              Modify period
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <small class="error" *ngIf="this.selectPeriodForm.hasError('invalidDates')">La data di inizio periodo non puo' essere maggiore alla data di fine periodo.</small>
  </div>
  <div class="form-actions">
    <!-- <div class="btn-back"> Indietro </div> -->
    <button type="submit" class="btn-next" [ngClass]="[(!this.selectPeriodForm.valid || selectedPeriodsListForm.value.length < 1 )? 'invalid' : '']" [disabled]="!this.selectPeriodForm.valid || selectedPeriodsListForm.value.length < 1"> Ok </button>
  </div>
</form>
