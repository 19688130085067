<div id="dash-questions-overview-wrapper">
  <div class="filter-question-wrapper">
    <form class="base--form-generic" [formGroup]="filterQuestionsOverviewForm">
      <div class="filters-wrapper p-relative">
        <div class="form-group filter-container" *ngFor="let item  of selectedFiltersListForm.controls; let i = index">
          <div class="form-row">
          <div class="form-column">
            <span class="filter-by"></span>
            <div class="select-wrapper"
              [tippy] = "getLabelQuestionById(item.value.filterQuestionId)"
              [lazy]="true"
              >
              <select class="slim readonly"
                [attr.disabled]="'disabled'"
                [value]="item.value.filterQuestionId"
              >
                <option *ngFor="let question of filterQuestions" [ngValue]="question.id" [selected]="question.id === item.value.filterQuestionId">
                  {{ question.description }}
                </option>
              </select>
            </div>
            <div class="select-image-wrapper">
              <div class="image-dropdown"  >
                <div class="select-image slim readonly auto-height" >
                  <ng-container *ngIf="item.value.filterAnswerRangeStart !== null && item.value.filterAnswerRangeEnd !== null; else textImgFilter">
                    {{ item.value.filterAnswerRangeStart + '-' + item.value.filterAnswerRangeEnd }}
                  </ng-container>
                  <ng-template #textImgFilter>
                    <ng-container *ngIf="filterAnswerValue; else selectImage">
                      <ng-container *ngIf="getLabelAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId) !== ''; else imageAnswerFilter">
                        {{ getLabelAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId) }}
                      </ng-container>
                      <ng-template #imageAnswerFilter>
                        <img class="filter-answer" [src]="'https://epathoscdn.it/TEST/img/' + getImageAnswerById(item?.value?.filterQuestionId, item?.value?.filterAnswerId)">
                      </ng-template>
                    </ng-container>
                    <ng-template #selectImage>Select answer</ng-template>
                  </ng-template>
                </div>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="form-group " *ngIf="selectedFiltersListForm.value.length<3 && showFiltersListFormVisible">
          <div class="form-row">
            <div class="form-column">
              <span class="filter-by">Filter by:</span>
              <div class="select-wrapper">
                <select class="slim" formControlName="filterQuestionValue" (change)="onChangeSelectQuestion()">
                  <option value="" selected disabled hidden>Question</option>
                  <option *ngFor="let question of filterQuestions" [ngValue]="question.id">
                    {{ question.description }}
                  </option>
                </select>
                <div class="select-icon slim">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="select-image-wrapper">
                <div class="image-dropdown" *ngIf="(filterQuestionValue?.valid)" >
                  <div class="select-image slim" (click)="showOptionsContainer = !showOptionsContainer">
                    {{filterAnswerValue? getLabelAnswerById(filterQuestionValue?.value, filterAnswerValue.value ) : ' Select answer'}}
                  </div>
                  <div class="select-icon slim">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
                <div class="options-container" *ngIf="showOptionsContainer" [ngClass]="showOptionsContainer?'expanded':''">
                  <ng-container  *ngIf="filteredQuestionType === 'knob' || filteredQuestionType === 'slider'; else answerListWrapper">
                    <form class="answers-range-wrapper" [formGroup]="filterQuestionsOverviewForm">
                      <div class="answers-range">
                        <div class="interval-value">
                          Inizio intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeStart">
                        </div>
                        <div class="interval-value">
                          Fine intervallo:
                          <input class="form-control" type="number" formControlName="filterAnswerRangeEnd">
                        </div>
                      </div>
                      <br>Minimo: {{ answersOptions[0].range_start }}
                      <br>Massimo: {{ answersOptions[0].range_end }}
                    </form>
                  </ng-container>
                  <ng-template #answerListWrapper>
                    <div class="answer-list-wrapper">
                      <div class="answer-list">
                        <div class="answer" [ngClass]="(filterAnswerValue?.value===(answer.id.toString()))?'selected': filterAnswerValue?.value !== '' ?'not-selected':''"  *ngFor="let answer of answersOptions" (click)="setAnswerOption(answer.id.toString())">
                          <div *ngIf="answer.urlImage; else textWrapper" class="image-wrapper" [tippy] = "answer.description">
                            <img [src]="'https://epathoscdn.it/TEST/img/'+ answer.urlImage " alt="Answer Image" loading="lazy"/>
                          </div>
                          <ng-template #textWrapper>
                            <div class="description">
                              {{ answer.description }}
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="answer-controls">
                    <div (click)="onClickCloseOptionsContainer()" class="btn-cancel"> cancel </div>
                    <button (click)="onClickAddNewFilter()" *ngIf="selectedFiltersListForm.value.length<3" [disabled]="!validNewFilter(filteredQuestionType, answersOptions[0].range_start, answersOptions[0].range_end)" type="button" class="btn-ok" > Ok </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="error" *ngIf="filterQuestionValue?.invalid && (filterQuestionValue?.dirty ||filterQuestionValue?.touched)">
            <sup>*</sup>Please provide filter question
          </div>
        </div>
        <div class="form-group justify-content-end">
          <div class="form-row">
            <div class="form-column">
              <div class="btn-default slim"   (click)="onClickShowFiltersListForm()" *ngIf="selectedFiltersListForm.value.length<3 && selectedFiltersListForm.value.length>=1">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_509_2721)">
                    <path d="M9.65117 11.312C9.33584 11.312 9.09217 11.226 8.92017 11.054C8.74817 10.8677 8.66217 10.6168 8.66217 10.3015V6.7755H5.20067C4.89967 6.7755 4.66317 6.69667 4.49117 6.539C4.31917 6.367 4.23317 6.1305 4.23317 5.8295C4.23317 5.5285 4.31917 5.29917 4.49117 5.1415C4.66317 4.9695 4.89967 4.8835 5.20067 4.8835H8.66217V1.465C8.66217 1.14967 8.74817 0.905999 8.92017 0.733999C9.09217 0.561999 9.34301 0.476 9.67267 0.476C9.98801 0.476 10.2245 0.561999 10.3822 0.733999C10.5542 0.905999 10.6402 1.14967 10.6402 1.465V4.8835H14.1017C14.417 4.8835 14.6535 4.9695 14.8112 5.1415C14.9832 5.29917 15.0692 5.5285 15.0692 5.8295C15.0692 6.1305 14.9832 6.367 14.8112 6.539C14.6535 6.69667 14.417 6.7755 14.1017 6.7755H10.6402V10.3015C10.6402 10.6168 10.5542 10.8677 10.3822 11.054C10.2245 11.226 9.98084 11.312 9.65117 11.312Z" fill="white" />
                  </g>
                  <defs>
                    <filter id="filter0_d_509_2721" x="0.233154" y="0.476074" width="18.8359" height="18.8359" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_2721" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_2721" result="shape" />
                    </filter>
                  </defs>
                </svg> Add filter
              </div>
              <div class="btn-default slim" (click)="onClickRemoveLastFilter()" *ngIf="selectedFiltersListForm.value.length>0" >
                cancel
              </div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <div class="btn-default slim white show-survey" (click)="toggleModalKeys()">
            Show survey
          </div>
        </div>


      </div>
      <div class="base--tpanel-periods-wrapper" style="align-items: end;">
        <div class="period purple">
          <div class="left-col-wrapper">
            <div class="info-wrapper">
              <div class="title">
                <div class="icon">
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
                  </svg>
                </div>
                <div class="testo">PERIOD {{ isOnePeriod ? '' : '1' }}</div>
              </div>
              <div class="dates-wrapper">
                {{utils.formatDateToString(periodOneStartDate)}} - {{utils.formatDateToString(periodOneEndDate)}}
              </div>
            </div>
          </div>
          <div class="left-col-wrapper">
            <div class="info-wrapper">
              <div class="title">
                <div>Total respondents</div>
              </div>
              <div class="dates-wrapper">
                {{this.periodsCounters.completed[0]}}
              </div>
            </div>
          </div>
        </div>
        <div class="period azure" *ngIf="!isOnePeriod">
          <div class="left-col-wrapper">
            <div class="info-wrapper">
              <div class="title">
                <div class="icon">
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
                  </svg>
                </div>
                <div class="testo">PERIOD 2</div>
              </div>
              <div class="dates-wrapper">
                {{utils.formatDateToString(periodTwoStartDate)}} - {{utils.formatDateToString(periodTwoEndDate)}}
              </div>
            </div>
          </div>

          <div class="left-col-wrapper">
            <div class="info-wrapper">
              <div class="title">
                <div>Total respondents</div>
              </div>
              <div class="dates-wrapper">
                {{this.periodsCounters.completed[1]}}
              </div>
            </div>
          </div>

          <!-- <div class="right-col-wrapper">
            <div class="top-wrapper">
              <span>Total <br> respondents</span>
            </div>
            <div class="bottom-wrapper">
              <span>{{this.periodsCounters.completed[1]}}</span>
            </div>
          </div> -->




        </div>

        <div class="export-wrapper" (click)="onClickDownloadImage()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17C3 16.7348 3.10536 16.4804 3.29289 16.2929C3.48043 16.1054 3.73478 16 4 16H16C16.2652 16 16.5196 16.1054 16.7071 16.2929C16.8946 16.4804 17 16.7348 17 17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8946 16.2652 18 16 18H4C3.73478 18 3.48043 17.8946 3.29289 17.7071C3.10536 17.5196 3 17.2652 3 17ZM6.293 9.293C6.48053 9.10553 6.73484 9.00021 7 9.00021C7.26516 9.00021 7.51947 9.10553 7.707 9.293L9 10.586V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V10.586L12.293 9.293C12.3852 9.19749 12.4956 9.12131 12.6176 9.0689C12.7396 9.01649 12.8708 8.9889 13.0036 8.98775C13.1364 8.9866 13.2681 9.0119 13.391 9.06218C13.5139 9.11246 13.6255 9.18671 13.7194 9.28061C13.8133 9.3745 13.8875 9.48615 13.9378 9.60905C13.9881 9.73194 14.0134 9.86362 14.0123 9.9964C14.0111 10.1292 13.9835 10.2604 13.9311 10.3824C13.8787 10.5044 13.8025 10.6148 13.707 10.707L10.707 13.707C10.5195 13.8945 10.2652 13.9998 10 13.9998C9.73484 13.9998 9.48053 13.8945 9.293 13.707L6.293 10.707C6.10553 10.5195 6.00021 10.2652 6.00021 10C6.00021 9.73484 6.10553 9.48053 6.293 9.293V9.293Z" fill="#533B83"/>
          </svg>
          <span>
            Export
          </span>
        </div>
        <div class="search-wrapper-container">
          <mat-form-field class="d-flex justify-content-end" appearance="standard">
              <div class="form-group">
                  <div class="form-row search-wrapper">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.7263 13.404L11.8237 10.5013C13.9976 7.59886 13.4065 3.484 10.5034 1.31056C7.6004 -0.862881 3.48474 -0.271959 1.31083 2.63049C-0.863084 5.53295 -0.271983 9.64781 2.63106 11.8212C4.96343 13.5674 8.1678 13.5682 10.501 11.8231L13.4048 14.7264C13.7698 15.0912 14.3614 15.0912 14.7263 14.7264C15.0912 14.3615 15.0912 13.77 14.7263 13.4052V13.404ZM6.5926 11.2628C4.0131 11.2628 1.922 9.17209 1.922 6.59311C1.922 4.01413 4.01313 1.92346 6.5926 1.92346C9.17208 1.92346 11.2632 4.01413 11.2632 6.59311C11.2605 9.17095 9.171 11.26 6.5926 11.2628Z" fill="#A8AAB5"/>
                      </svg>
                      <input matInput (keyup)="applyFilterForm($event)" placeholder="Cerca" #input>
                  </div>
              </div>
          </mat-form-field>
        </div>
      </div>
      <div class="table-wrapper">
        <div class="mat-elevation-z8">
          <div class="table-responsive">
            <table class="base--table-survey" mat-table [dataSource]="dataSourceQuestionsOverview" matSort multiTemplateDataRows>
              <!-- ID Column -->
              <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  ID list </th>
                  <td mat-cell *matCellDef="let row">
                  {{row.domanda_id}}
                </td>
              </ng-container>
              <!-- Domanda Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
                <td mat-cell *matCellDef="let row"> <span class="base--overflowtext" [tippy]="row.description">{{row.description}}</span></td>
              </ng-container>

              <!-- Tipo di domanda Column -->
              <!-- <ng-container matColumnDef="questionType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo di domanda </th>
                <td mat-cell *matCellDef="let row">
                  <mat-form-field appearance="fill">
                    <mat-select  [value]="row.type" [disabled]="true">
                      <mat-option *ngFor="let questiontype of questionTypeList" [value]="questiontype.value">
                        {{questiontype.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container> -->

              <!-- Distribution Column -->
              <ng-container matColumnDef="periodOneDistribution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Distribution</th>

                <td mat-cell *matCellDef="let row">
                  <div class="distributions-container">
                    <!-- <div class="distribution grey">
                      {{row.counters[0].colorsPerc.grey}}%
                    </div> -->
                    <div class="distribution red" [ngClass]="{'is-zero': (row.counters[0].colorsPerc.red === '0.00' && row.counters[0].colorsPerc.grey === '100.00')}">
                      {{row.counters[0].colorsPerc.red}}%
                    </div>
                    <div class="distribution orange" [ngClass]="{'is-zero': (row.counters[0].colorsPerc.orange === '0.00' && row.counters[0].colorsPerc.grey === '100.00')}">
                      {{row.counters[0].colorsPerc.orange}}%
                    </div>
                    <div class="distribution green" [ngClass]="{'is-zero': (row.counters[0].colorsPerc.green === '0.00' && row.counters[0].colorsPerc.grey === '100.00')}">
                      {{row.counters[0].colorsPerc.green}}%
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Threat Column -->
              <ng-container matColumnDef="periodOneThreat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Threat Class</th>
                <td mat-cell *matCellDef="let row">

                  <div class="threat-container">
                    <div class="threat-dot" [ngClass]="[(getThreatClass(row.counters[0].colors.threat, 'red'))? 'red' : 'none']">
                    </div>
                    <div class="threat-dot"  [ngClass]="[(getThreatClass(row.counters[0].colors.threat, 'orange'))? 'orange' : 'none']">
                    </div>
                    <div class="threat-dot"  [ngClass]="[(getThreatClass(row.counters[0].colors.threat, 'green'))? 'green' : 'none']">
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Distribution Column -->
              <ng-container matColumnDef="periodTwoDistribution" *ngIf="!isOnePeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Distribution</th>
                <td mat-cell *matCellDef="let row">
                  <div class="distributions-container">
                    <!-- <div class="distribution grey"  >
                      {{row.counters[1].colorsPerc.grey}}%
                    </div> -->
                    <div class="distribution red" [ngClass]="{'is-zero': (row.counters[1].colorsPerc.red === '0.00' && row.counters[1].colorsPerc.grey === '100.00')}">
                      {{row.counters[1].colorsPerc.red}}%
                    </div>
                    <div class="distribution orange" [ngClass]="{'is-zero': (row.counters[1].colorsPerc.orange === '0.00' && row.counters[1].colorsPerc.grey === '100.00')}">
                      {{row.counters[1].colorsPerc.orange}}%
                    </div>
                    <div class="distribution green" [ngClass]="{'is-zero': (row.counters[1].colorsPerc.green === '0.00' && row.counters[1].colorsPerc.grey === '100.00')}">
                      {{row.counters[1].colorsPerc.green}}%
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Threat Column -->
              <ng-container matColumnDef="periodTwoThreat" *ngIf="!isOnePeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Threat Class</th>
                <td mat-cell *matCellDef="let row">

                  <div class="threat-container">
                    <div class="threat-dot" [ngClass]="[(getThreatClass(row.counters[1].colors.threat, 'red'))? 'red' : 'none']">
                    </div>
                    <div class="threat-dot"  [ngClass]="[(getThreatClass(row.counters[1].colors.threat, 'orange'))? 'orange' : 'none']">
                    </div>
                    <div class="threat-dot"  [ngClass]="[(getThreatClass(row.counters[1].colors.threat, 'green'))? 'green' : 'none']">
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Trend Column -->
              <ng-container matColumnDef="trend" *ngIf="!isOnePeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Trend</th>
                <td mat-cell *matCellDef="let row">
                  <div [ngSwitch]="getThreatDirection(row.counters[0].colors.threat, row.counters[1].colors.threat)">
                    <div *ngSwitchCase="'up'">
                      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.23279 0.789163C7.83716 0.363098 7.16284 0.363097 6.76721 0.789162L0.560421 7.47339C-0.0337242 8.11324 0.420047 9.15385 1.29321 9.15385L5.08333 9.15385C5.63562 9.15385 6.08333 9.60156 6.08333 10.1538L6.08333 16C6.08333 16.5523 6.53105 17 7.08333 17L7.91667 17C8.46895 17 8.91667 16.5523 8.91667 16L8.91667 10.1538C8.91667 9.60156 9.36438 9.15385 9.91667 9.15385L13.7068 9.15385C14.58 9.15385 15.0337 8.11324 14.4396 7.47339L8.23279 0.789163Z" fill="#75C478"/>
                      </svg>
                    </div>
                    <div *ngSwitchCase="'equal'">
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.789165 6.76721C0.3631 7.16284 0.363098 7.83716 0.789164 8.23279L7.47339 14.4396C8.11324 15.0337 9.15385 14.58 9.15385 13.7068L9.15385 9.91667C9.15385 9.36438 9.60156 8.91666 10.1538 8.91666L16 8.91666C16.5523 8.91666 17 8.46895 17 7.91666L17 7.08333C17 6.53104 16.5523 6.08333 16 6.08333L10.1538 6.08333C9.60156 6.08333 9.15385 5.63562 9.15385 5.08333L9.15385 1.29321C9.15385 0.420048 8.11324 -0.0337283 7.4734 0.560418L0.789165 6.76721Z" fill="#949494"/>
                        </svg>
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.2108 8.23279C16.6369 7.83716 16.6369 7.16284 16.2108 6.76721L9.52661 0.56042C8.88676 -0.0337245 7.84616 0.420047 7.84616 1.29321L7.84616 5.08333C7.84616 5.63562 7.39844 6.08333 6.84616 6.08333L1 6.08333C0.447715 6.08333 8.27802e-07 6.53105 7.7952e-07 7.08333L7.06667e-07 7.91667C6.58385e-07 8.46895 0.447716 8.91667 1 8.91667L6.84616 8.91667C7.39844 8.91667 7.84615 9.36438 7.84615 9.91667L7.84615 13.7068C7.84615 14.5799 8.88676 15.0337 9.52661 14.4396L16.2108 8.23279Z" fill="#949494"/>
                        </svg>
                    </div>
                    <div *ngSwitchCase="'down'">
                      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.76721 16.2108C7.16284 16.6369 7.83716 16.6369 8.23279 16.2108L14.4396 9.52661C15.0337 8.88676 14.58 7.84615 13.7068 7.84615L9.91667 7.84615C9.36438 7.84615 8.91667 7.39844 8.91667 6.84615L8.91667 1C8.91667 0.447713 8.46895 -4.13901e-07 7.91667 -3.8976e-07L7.08333 -3.53334e-07C6.53105 -3.29193e-07 6.08333 0.447715 6.08333 1L6.08333 6.84615C6.08333 7.39844 5.63562 7.84615 5.08333 7.84615L1.29321 7.84615C0.420049 7.84615 -0.0337273 8.88676 0.560419 9.52661L6.76721 16.2108Z" fill="#DE6067"/>
                      </svg>
                    </div>
                    <div *ngSwitchDefault> </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                </td>
              </ng-container>
              <ng-container matColumnDef="expandedDetail" >
                <td class="td-row-questions-overview" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand .length" id="{{ 'questions-overview-answers-export-' + element.id }}">
                  <div class="question-element-detail"
                    [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <div class="question-element-description">
                      <div class="cross-tab-question-text">{{ element.description }}</div>
                      <div class="cross-tab-question-container">
                          <div class="cross-tab-question">
                            <span>Cross tab with question</span>
                            <div class="form-group " >
                              <div class="form-row">
                                <div class="form-column">
                                  <div class="select-wrapper">
                                    <select class="slim green" formControlName="filterCrossQuestionValue" (change)="onChangeSelectCrossQuestion(element)" >
                                      <option value="" selected disabled hidden>Question</option>
                                      <option *ngFor="let question of crossQuestions" [ngValue]="question.id">
                                        {{ question.domanda_id }} - {{ question.description }}
                                      </option>
                                    </select>
                                    <div class="select-icon slim">
                                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.04547L5 4.98486L9 1.04547" stroke="#A8AAB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="export-wrapper"  (click)="onClickDownloadImage(element.id)">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17C3 16.7348 3.10536 16.4804 3.29289 16.2929C3.48043 16.1054 3.73478 16 4 16H16C16.2652 16 16.5196 16.1054 16.7071 16.2929C16.8946 16.4804 17 16.7348 17 17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8946 16.2652 18 16 18H4C3.73478 18 3.48043 17.8946 3.29289 17.7071C3.10536 17.5196 3 17.2652 3 17ZM6.293 9.293C6.48053 9.10553 6.73484 9.00021 7 9.00021C7.26516 9.00021 7.51947 9.10553 7.707 9.293L9 10.586V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V10.586L12.293 9.293C12.3852 9.19749 12.4956 9.12131 12.6176 9.0689C12.7396 9.01649 12.8708 8.9889 13.0036 8.98775C13.1364 8.9866 13.2681 9.0119 13.391 9.06218C13.5139 9.11246 13.6255 9.18671 13.7194 9.28061C13.8133 9.3745 13.8875 9.48615 13.9378 9.60905C13.9881 9.73194 14.0134 9.86362 14.0123 9.9964C14.0111 10.1292 13.9835 10.2604 13.9311 10.3824C13.8787 10.5044 13.8025 10.6148 13.707 10.707L10.707 13.707C10.5195 13.8945 10.2652 13.9998 10 13.9998C9.73484 13.9998 9.48053 13.8945 9.293 13.707L6.293 10.707C6.10553 10.5195 6.00021 10.2652 6.00021 10C6.00021 9.73484 6.10553 9.48053 6.293 9.293V9.293Z" fill="#533B83"/>
                              </svg>
                              <span>
                                Export
                              </span>
                            </div>
                          </div>
                      </div>
                      <div class="inner-tabs-wrapper" *ngIf="!(filterCrossQuestionValue?.valid)">
                          <div class="row">
                            <div class="col-9">
                              <div class="inner-tabs">
                                <div class="inner-tab-answer" [ngClass]="{'d-block': element.type === 'slider'}" *ngFor="let answer of element.answers" >
                                  <ng-container [ngSwitch]="element.type">
                                    <ng-container *ngSwitchCase="'knob'">
                                      <div class="knob-visual">
                                        <div class="line-circle">
                                          <div class="line {{ element.counters[0].colors.threat[0] }}" style="--deg: {{ (180 * (element.media[0] / (answer.range_end - answer.range_start)) - 90) + 'deg' }}">
                                            <div class="bg-white"></div>
                                          </div>
                                        </div>
                                        <div class="dot {{ element.counters[0].colors.threat[0] }}" style="--deg: {{ (180 * (element.media[0] / (answer.range_end - answer.range_start))) + 'deg' }}"></div>
                                        <div class="content" style="background-image: url('https://epathoscdn.it/TEST/img/{{ answer.urlImage }}')">
                                          <div class="score">{{ element.media[0] | number : '1.0-2' }}</div>
                                        </div>
                                      </div>
                                      <div class="knob-visual"*ngIf="!isOnePeriod">
                                        <div class="line-circle">
                                          <div class="line {{ element.counters[1].colors.threat[0] }}" style="--deg: {{ (180 * (element.media[1] / (answer.range_end - answer.range_start)) - 90) + 'deg' }}">
                                            <div class="bg-white"></div>
                                          </div>
                                        </div>
                                        <div class="dot {{ element.counters[1].colors.threat[0] }}" style="--deg: {{ (180 * (element.media[1] / (answer.range_end - answer.range_start))) + 'deg' }}"></div>
                                        <div class="content" style="background-image: url('https://epathoscdn.it/TEST/img/{{ answer.urlImage }}')">
                                          <div class="score">{{ element.media[1] | number : '1.0-2' }}</div>
                                        </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'slider'">
                                      <div class="slider-visual">
                                        <div class="image-block">
                                          <img src="https://epathoscdn.it/TEST/img/{{ answer.urlImage }}" alt="{{ answer.urlImage }}">
                                          <span>{{ element.media[0] | number : '1.0-2' }}%</span>
                                        </div>
                                        <div class="image-block">
                                          <img src="https://epathoscdn.it/TEST/img/{{ answer.urlImage2 }}" alt="{{ answer.urlImage2 }}">
                                          <span>{{ 100 - element.media[0] | number : '1.0-2' }}%</span>
                                        </div>
                                      </div>
                                      <div class="slider-visual"*ngIf="!isOnePeriod">
                                        <div class="image-block">
                                          <img src="https://epathoscdn.it/TEST/img/{{ answer.urlImage }}" alt="{{ answer.urlImage }}">
                                          <span>{{ element.media[1] | number : '1.0-2' }}%</span>
                                        </div>
                                        <div class="image-block">
                                          <img src="https://epathoscdn.it/TEST/img/{{ answer.urlImage2 }}" alt="{{ answer.urlImage2 }}">
                                          <span>{{ 100 - element.media[1] | number : '1.0-2' }}%</span>
                                        </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      <div class="answer-wrapper">
                                        <img *ngIf="answer.urlImage; else answerText" [src]="'https://epathoscdn.it/TEST/img/'+answer.urlImage" alt="Answer Image" loading="lazy" [tippy]="answer.description"/>
                                        <ng-template #answerText>
                                          <div class="text">{{ answer.description }}</div>
                                        </ng-template>
                                      </div>
                                      <div class="inner-divisor">
                                      </div>
                                      <div class="threats-wrapper">
                                          <div class="threat purple" [ngClass]="answer.color">
                                            <div class="threat-bar" [style.width.%]="answer.count[0]">
                                              <span [ngClass]="{'small-value': answer.count[0] <= 10}">{{answer.count[0] | number : '1.2-2'}}%</span>
                                            </div>
                                            <div class="threat-dot-wrapper" [style.width.%]="answer.count[0]" [ngClass]="{'one-period': isOnePeriod}">
                                              <div class="dot">
                                              </div>
                                            </div>
                                            <div class="divisor perc-1"></div>
                                            <div class="divisor perc-25"></div>
                                            <div class="divisor perc-50"></div>
                                            <div class="divisor perc-75"></div>
                                            <div class="divisor perc-100"></div>
                                          </div>
                                          <div class="threat-space" *ngIf="!isOnePeriod"></div>
                                          <div class="threat azure" [ngClass]="answer.color" *ngIf="!isOnePeriod">
                                            <div class="threat-bar" [style.width.%]="answer.count[1]">
                                              <span [ngClass]="{'small-value': answer.count[1] <= 10}">{{answer.count[1] | number : '1.2-2'}}%</span>
                                            </div>
                                            <div class="threat-dot-wrapper" [style.width.%]="answer.count[1]">
                                              <div class="dot">
                                              </div>
                                            </div>
                                            <div class="divisor perc-1"></div>
                                            <div class="divisor perc-25"></div>
                                            <div class="divisor perc-50"></div>
                                            <div class="divisor perc-75"></div>
                                            <div class="divisor perc-100"></div>
                                          </div>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>
                                <ng-container *ngIf="element.type !== 'knob' && element.type !== 'slider'">
                                  <div class="inner-tab-percentages">
                                    <div class="empty-wrapper">
                                        <img [src]="'https://epathoscdn.it/TEST/img/CDI_SEDI_2020/D_02_01.png'" alt="Answer Image" loading="lazy"/>
                                    </div>
                                    <div class="percentage-wrapper">
                                          <div class="divisor perc-1"><span class="perc-value">0%</span></div>
                                          <div class="divisor perc-25"><span class="perc-value">25%</span></div>
                                          <div class="divisor perc-50"><span class="perc-value">50%</span></div>
                                          <div class="divisor perc-75"><span class="perc-value">75%</span></div>
                                          <div class="divisor perc-100"><span class="perc-value">100%</span></div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="col-3">
                                <div class="periods-threats-wrapper">
                                  <div class="period purple" [ngClass]="(element.counters[0].colorsPerc.grey === '100.00')? 'hide': ''">
                                    <div class="left-col-wrapper">
                                      <div class="icon">
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
                                        </svg>
                                      </div>
                                      <div class="info-wrapper">
                                        <div class="title">
                                            PERIOD {{ isOnePeriod ? '' : '1' }}
                                        </div>
                                        <div class="dates-wrapper">
                                          <span>{{utils.formatDateToString(periodOneStartDate)}}</span>
                                          <span>{{utils.formatDateToString(periodOneEndDate)}}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="right-col-wrapper">
                                      <div class="top-wrapper">
                                        <span>Total <br> respondents</span>
                                      </div>
                                      <div class="bottom-wrapper">
                                        <span>{{this.periodsCounters.completed[0]}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="threats" [ngClass]="(element.counters[0].colorsPerc.grey === '100.00')? 'hide': ''">
                                    <div class="threat green">
                                      {{element.counters[0].colorsPerc.green}}%
                                    </div>
                                    <div class="threat orange">
                                      {{element.counters[0].colorsPerc.orange}}%
                                    </div>
                                    <div class="threat red">
                                      {{element.counters[0].colorsPerc.red}}%
                                    </div>
                                  </div>
                                  <div class="period azure" *ngIf="!isOnePeriod" [ngClass]="(element.counters[1].colorsPerc.grey === '100.00')? 'hide': ''">
                                    <div class="left-col-wrapper">
                                      <div class="icon">
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8.47917 0.916667H8.25V0.6875C8.25 0.505164 8.17757 0.330295 8.04864 0.201364C7.9197 0.0724328 7.74484 0 7.5625 0V0C7.38016 0 7.2053 0.0724328 7.07636 0.201364C6.94743 0.330295 6.875 0.505164 6.875 0.6875V0.916667H4.125V0.6875C4.125 0.505164 4.05257 0.330295 3.92364 0.201364C3.7947 0.0724328 3.61984 0 3.4375 0V0C3.25516 0 3.0803 0.0724328 2.95136 0.201364C2.82243 0.330295 2.75 0.505164 2.75 0.6875V0.916667H2.52083C1.85227 0.916667 1.21108 1.18225 0.738335 1.655C0.265587 2.12775 0 2.76893 0 3.4375L0 8.47917C0 9.14773 0.265587 9.78892 0.738335 10.2617C1.21108 10.7344 1.85227 11 2.52083 11H8.47917C9.14773 11 9.78892 10.7344 10.2617 10.2617C10.7344 9.78892 11 9.14773 11 8.47917V3.4375C11 2.76893 10.7344 2.12775 10.2617 1.655C9.78892 1.18225 9.14773 0.916667 8.47917 0.916667V0.916667ZM8.47917 9.625H2.52083C2.21694 9.625 1.92549 9.50428 1.71061 9.28939C1.49572 9.07451 1.375 8.78306 1.375 8.47917V4.58333H9.625V8.47917C9.625 8.78306 9.50428 9.07451 9.28939 9.28939C9.07451 9.50428 8.78306 9.625 8.47917 9.625Z" fill="white"/>
                                        </svg>
                                      </div>
                                      <div class="info-wrapper">
                                        <div class="title">
                                            PERIOD 2
                                        </div>
                                        <div class="dates-wrapper">
                                          <span>{{utils.formatDateToString(periodTwoStartDate)}}</span>
                                          <span>{{utils.formatDateToString(periodTwoEndDate)}}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="right-col-wrapper">
                                      <div class="top-wrapper">
                                        <span>Total <br> respondents</span>
                                      </div>
                                      <div class="bottom-wrapper">
                                        <span>{{this.periodsCounters.completed[1]}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="threats" *ngIf="!isOnePeriod" [ngClass]="(element.counters[1].colorsPerc.grey === '100.00')? 'hide': ''">
                                    <div class="threat green">
                                      {{element.counters[1].colorsPerc.green}}%
                                    </div>
                                    <div class="threat orange">
                                      {{element.counters[1].colorsPerc.orange}}%
                                    </div>
                                    <div class="threat red">
                                      {{element.counters[1].colorsPerc.red}}%
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div *ngIf="(filterCrossQuestionValue?.valid)" class="inner-cross-tabs-wrapper">
                        <table >
                          <thead>
                            <tr>
                              <td>
                              </td>
                              <td *ngFor="let crossAnswers of this.dataCrossTab?.crossAnswers">
                                <ng-container *ngIf="crossAnswers.imagePath; else crossAnswerText">
                                  <ng-container [ngSwitch]="crossAnswers.type">
                                    <ng-container *ngSwitchCase="'knob'">
                                      <img [src]="'https://epathoscdn.it/TEST/img/'+ crossAnswers.imagePath" class="knob {{ crossAnswers.color }}" alt="Answer Image" loading="lazy"/>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'slider'">
                                      <div class="slider-cross {{ crossAnswers.color }}">
                                        <img [src]="'https://epathoscdn.it/TEST/img/'+ crossAnswers.imagePath" alt="Answer Image" loading="lazy"/>
                                        <img [src]="'https://epathoscdn.it/TEST/img/'+ crossAnswers.imagePath2" alt="Answer Image" loading="lazy"/>
                                      </div>
                                    </ng-container>
                                    <div *ngSwitchDefault>
                                      <img [src]="'https://epathoscdn.it/TEST/img/'+ crossAnswers.imagePath" alt="Answer Image" loading="lazy" [tippy]="crossAnswers.description"/>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-template #crossAnswerText>
                                  <div class="text">{{ crossAnswers.description }}</div>
                                </ng-template>
                              </td>
                              <td>
                                <img *ngIf="this.dataCrossTab?.crossAnswers" [src]="'/assets/images/total-box.png'" alt="Answer Image" loading="lazy"/>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let rowSelected of this.dataCrossTab?.allRows">
                              <td>
                                <ng-container *ngIf="rowSelected.imagePath; else crossRowText">
                                  <ng-container [ngSwitch]="rowSelected.type">
                                    <ng-container *ngSwitchCase="'knob'">
                                      <img [src]="'https://epathoscdn.it/TEST/img/'+ rowSelected.imagePath" class="knob {{ rowSelected.color }}" alt="Answer Image" loading="lazy"/>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'slider'">
                                      <div class="slider-cross {{ rowSelected.color }}">
                                        <img [src]="'https://epathoscdn.it/TEST/img/'+ rowSelected.imagePath" alt="Answer Image" loading="lazy"/>
                                        <img [src]="'https://epathoscdn.it/TEST/img/'+ rowSelected.imagePath2" alt="Answer Image" loading="lazy"/>
                                      </div>
                                    </ng-container>
                                    <div *ngSwitchDefault>
                                      <img [src]="'https://epathoscdn.it/TEST/img/'+ rowSelected.imagePath" alt="Answer Image" loading="lazy" [tippy]="rowSelected.description"/>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-template #crossRowText>
                                  <div class="text">{{ rowSelected.description }}</div>
                                </ng-template>
                              </td>
                              <td *ngFor="let percentualPeriods of rowSelected?.percentuals" [ngClass]="{'one-period': isOnePeriod}">
                                <div class="cross-couple">
                                  <div class="cross-value">
                                    {{percentualPeriods?.percentCross1 | number : '1.1-1'}}%
                                  </div>
                                  <div class="cross-value" *ngIf="!isOnePeriod">
                                    {{percentualPeriods?.percentCross2 | number : '1.1-1'}}%
                                  </div>
                                </div>
                              </td>
                              <td [ngClass]="{'one-period': isOnePeriod}">
                                <div class="cross-couple">
                                  <div class="cross-value">
                                    {{rowSelected?.totalPercentuals?.sumPercentCross1 | number : '1.1-1'}}%
                                  </div>
                                  <div class="cross-value" *ngIf="!isOnePeriod">
                                    {{rowSelected?.totalPercentuals?.sumPercentCross2 | number : '1.1-1'}}%
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span class="question-element-description-attribution"></span>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand "></tr>
              <tr  mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand ;"
                class="question-element-row"
                id="{{ 'questions-overview-question-export-' + element.id }}"
                [class.question-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element; toggleExpandedElementRow(element); ">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="question-detail-row"></tr>
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>
          </div>
          <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
        </div>
      </div>
    </form>
  </div>

  <!-- <ng-container *ngIf="showModalKeys" >
    <div class="modal-keys">
      <section>
          <div class="top-wrapper">
            <button class="modal-close"type="button" (click)="toggleModalKeys()">X</button>
          </div>
          <table>
            <thead>
              <tr>
                <th align="center">TOUCHPOINT</th>
                <th align="center">ID</th>
                <th align="center" style="width: 75%;">DOMANDA</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let question of questions" >
                <tr>
                  <td align="center">{{question.touch_point}}</td>
                  <td align="center" >{{question.domanda_id}}</td>
                  <td style="width: 70%;" align="center" >{{question.description}}</td>
                </tr>
              </ng-container>

            </tbody>

          </table>
      </section>
    </div>

  </ng-container> -->
</div>
