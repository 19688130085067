import { setPeriods } from '../../../../stores/survey/survey.actions';
import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
// import { setSurvey } from 'src/app/@core/state/survey/survey.actions';
import { Survey } from 'src/app/models/Survey';
import  Utils  from 'src/app/utils/utils';
import { Router  } from '@angular/router';

//Form builders
import { FormArray, FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from "@angular/forms";

//States
import { SurveyState } from '../../../../stores/survey/survey.reducer';

//constants
import { ROUTES } from '../../../../utils/constants'

//Models
import { Period } from 'src/app/models/Period';
import { SidebarState } from 'src/app/stores/sidebar/sidebar.reducer';
import { refreshSidebar } from 'src/app/stores/sidebar/sidebar.actions';


@Component({
  selector: 'app-select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.scss'],
})
export class SelectPeriodComponent {

  surveyState$: Observable<SurveyState>;

  currentSurveyList!: Survey[];
  utils!: Utils;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private store: Store<{ surveyState: SurveyState, sidebarState: SidebarState }>) {
    this.surveyState$ = store.select('surveyState');
  }
  selectPeriodForm = this.fb.group({
    startPeriod:  ["", ],
    endPeriod:  [""],
    selectedPeriodsListForm: this.fb.array([], [Validators.required]) ,
  },{validator: this.checkStartEndDates});

  checkStartEndDates(group: FormGroup) {

    if(new Date(group.controls.endPeriod.value) < new Date(group.controls.startPeriod.value)) {
      return { invalidDates: true }
    }

    return null;
  }

  ngOnInit(): void {
    // this.utils = new Utils();
    // this.surveyState$.subscribe(surveyS => this.currentSurveyList = surveyS.surveyList);
  }

  setStartPeriod(value: any) {
    this.selectPeriodForm.patchValue({
      startPeriod: value
    });
  }

  setEndPeriod(value: any) {
    this.selectPeriodForm.patchValue({
      endPeriod: value
    });
  }



  get startPeriod() {
    return this.selectPeriodForm.get("startPeriod");
  }
  get endPeriod() {
    return this.selectPeriodForm.get("endPeriod");
  }

  get selectedPeriodsListForm() : FormArray {
    return this.selectPeriodForm.get("selectedPeriodsListForm") as FormArray
  }

  onClickAddNewPeriod() {
    const lastIndex = (this.selectedPeriodsListForm.value.length);
    if(this.startPeriod?.value && this.endPeriod?.value) {
      const startDate = new Date(this.startPeriod?.value?.toString());
      const endDate =   new Date(this.endPeriod?.value?.toString());
      const period = new Period (lastIndex, startDate, endDate);
      if(!this.selectPeriodForm.hasError('invalidDates')) {
        this.addSelectedPeriodList(period);
      }
    }

  }

  addSelectedPeriodList(period: Period) {
    this.selectedPeriodsListForm.push(this.newPeriod(period));
    if(this.selectedPeriodsListForm.value.length>=2) {
      this.selectPeriodForm.controls['startPeriod'].reset();
      this.selectPeriodForm.controls['endPeriod'].reset();
    }
  }

  newPeriod(period: Period): FormGroup {
    return this.fb.group({
      id: period.id,
      startDate: period.startDate,
      endDate: period.endDate,
    })
  }

  onClickRemoveLastPeriod() {
    const lastIndex = (this.selectedPeriodsListForm.value.length) -1;
    this.selectedPeriodsListForm.removeAt(lastIndex);
  }

  onSubmitPeriodList() {
    if (this.selectedPeriodsListForm.length == 1) {
      const lastIndex = (this.selectedPeriodsListForm.value.length);
      const startDate = new Date(this.selectedPeriodsListForm.at(0).value.startDate.toString());
      const endDate =   new Date(this.selectedPeriodsListForm.at(0).value.endDate.toString());
      const period = new Period (lastIndex, startDate, endDate);
      this.selectedPeriodsListForm.push(this.newPeriod(period));
    }
    this.store.dispatch(setPeriods({ periodList: this.selectedPeriodsListForm.value}));
    this.router.navigate([ROUTES.ROUTE_EMOTIONAL_OVERVIEW]);
  }
}
