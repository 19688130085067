import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from '../../../../services/auth/authentication.service'
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { login } from '../../../../stores/auth/authentication.actions'
import { LoginRequest } from 'src/app/models/global.request';
import { ToastrService } from 'ngx-toastr';

import { AuthState } from './../../../../stores/auth/authentication.reducer';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/utils/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  authState$: Observable<AuthState>;
  constructor(
    public fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<{ authState: any }>) {
    this.authState$ = store.select('authState');
  }

  signInForm = this.fb.group({
    email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    password: ["", [Validators.required]],
  });

  get email() {
    return this.signInForm.get("email");
  }

  get password() {
    return this.signInForm.get("password");
  }

  ngOnInit() {
    this.authState$.subscribe(authS => {
      if (authS.isAuthenticated) {
        this.router.navigate([ROUTES.ROUTES_SELECT_SURVEY]);
      }
    })
  }

  onSubmitSignIn() {
    const email = this.signInForm.value.email || "";
    const password = this.signInForm.value.password|| "";

    if(!this.validateEmail(email)) {
      this.toastr.error("Email not valid", "Validation");
    }else{
      const signInData = new LoginRequest(email, password)
      this.store.dispatch(login({ request: signInData }));
    }

  }

  validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

}
