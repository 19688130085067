import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { EcommerceRoutingModule } from './ecommerce-routing.module';
import { CreateEcommerceComponent } from './components/create-ecommerce/create-ecommerce.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SafePipe } from 'src/app/utils/pipes';

@NgModule({
  declarations: [
    CreateEcommerceComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    EcommerceRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    SharedModule
  ],
  exports:[
    CreateEcommerceComponent
  ]
})
export class EcommerceModule { }
