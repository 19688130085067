import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { Answer, AnswerRange } from "src/app/models/Answer";
import { Question } from "src/app/models/Question";
import { Step } from "src/app/models/Step";
import { User } from "src/app/models/User";
import { GetSurveyRequest } from "src/app/models/global.request";
import { SurveyService } from "src/app/services/survey/survey.service";
import { UserService } from "src/app/services/user/user.service";
import { refreshSidebar, setStepEditSurvey } from "src/app/stores/sidebar/sidebar.actions";
import { SidebarState } from "src/app/stores/sidebar/sidebar.reducer";
import { saveSurveyParams } from "src/app/stores/survey/survey.actions";
import Formatter from "src/app/utils/formatters";

interface selectDropdownItemMt{
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-edit-survey",
  templateUrl: "./edit-survey.component.html",
  styleUrls: ["./edit-survey.component.scss"],
})
export class EditSurveyComponent implements OnInit {

  survey: any;
  formatter!: Formatter;
  sidebarState$: Observable<SidebarState>;
  currentStepList!: Step[];
  allQuestions: Question[] = [];

  // STEP 1 - Client
  existingUsers: any[] = [];
  existingUsersAll: any[] = [];
  emailAlreadyExists: boolean[] = [];
  isVisibleAddNewUser: (boolean | undefined)[] = [false];
  showPassword: boolean = false;
  passwordRegex: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  // STEP 2 - Select survey
  isVisibleCropImage: boolean | undefined;
  isAllSelected: boolean | undefined;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  // STEP 4 - Questions overview
  isValidStepFour: boolean = false;
  touchPointList: selectDropdownItemMt[] = [
    {value: 'contact', viewValue: 'Contatto'},
    {value: 'path', viewValue: 'Percorso'},
    {value: 'suggestion', viewValue: 'Suggestion'},
    {value: 'other', viewValue: 'Other'},
  ];
  questionTypeList: selectDropdownItemMt[] = [
    {value: 'open', viewValue: 'Aperta'},
    {value: 'close', viewValue: 'Chiusa'},
    {value: 'multiple', viewValue: 'Multipla'},
    {value: 'slider', viewValue: 'Slider'},
    {value: 'knob', viewValue: 'Knob'},
    {value: 'elenco', viewValue: 'Elenco'},
  ];

  // FORMS
  stepOneForm = this.fb.group({
    customerName: ["", [Validators.required]],
    users: this.fb.array([
      this.fb.group({
        id: [0],
        userId: ["", [Validators.required]],
        newUser: this.fb.group({
          newMail: ["", [Validators.required, Validators.email, this.validateUserDoesNotExist(0)]],
          newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
        })
      })
    ])
  });
  stepTwoForm = this.fb.group({
    surveyName: ["", [Validators.required]],
    surveyNewName: ["", [Validators.required, this.noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(50)]],
    surveyDescription: ["", [Validators.required, this.noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(100)]],
    surveyFileSourceCover:[null],
    surveyFileBase64Cover:['', [Validators.required]],
    surveyFileNameCover: [''],
  });
  stepThreeForm = this.fb.group({
    filterQuestionsListForm: this.fb.array([], [Validators.required]),
  });
  stepFourForm = this.fb.group({
    questionOverviewListForm: this.fb.array([], [Validators.required]),
    newTouchPoint:[''],
  });
  stepFiveForm = this.fb.group({
    satisfactionListForm: this.fb.array([]),
  });
  stepSixForm = this.fb.group({
    npsListForm: this.fb.array([]),
  });
  stepSevenForm = this.fb.group({
    endPointListForm: this.fb.array([]),
  });
  stepEightForm = this.fb.group({
    emotionalQuestionListForm: this.fb.array([]),
  });

  validateUserDoesNotExist(index: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newEmail = control.value;
      const emailExists = this.existingUsersAll.filter((user: any) => user.email === newEmail);
      this.emailAlreadyExists[index] = emailExists.length > 0 ? true : false;

      return emailExists.length > 0 ? { forbidden: { message: "Esiste già un utente con questa email" } } : null;
    };
  }

  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length? null : { 'whitespace': true };
  }

  // MATERIAL TABLES
  displayedSourceColumns = ['id', 'description'];
  displayedSelectedColumns = ['id','touchPoint','questionType','description'];
  displayedSelectedSatisfactionColumns = ['id','touchPoint','description'];
  displayedSelectedNPSColumns= ['id','touchPoint','description'];
  displayedSelectedEndPointColumns= ['id','touchPoint','description'];
  displayedSelectedEmotionalQuestionColumns= ['id','touchPoint','description'];

  dataSourceQuestions!: MatTableDataSource<Question>;
  dataSelectedQuestions!: MatTableDataSource<Question>;
  dataSelectedSatisfaction!: MatTableDataSource<Question>;
  dataSelectedNPS!: MatTableDataSource<Question>;
  dataSelectedEndPoint!: MatTableDataSource<Question>;
  dataSelectedEmotionalQuestion!: MatTableDataSource<Question>;

  private paginatorSource!: MatPaginator;
  private sortSource!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortSource(ms: MatSort) {
    this.sortSource = ms;
    if(ms)
    this.setPaginationAndSortSourceQuestions();
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorSource(
    mp: MatPaginator
  ) {
    this.paginatorSource = mp;
    if(mp)
    this.setPaginationAndSortSourceQuestions();
  }

  private paginatorSelected!: MatPaginator;
  private sortSelected!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortSelected(ms: MatSort) {
    this.sortSelected = ms;
    if(ms)
    this.setPaginationAndSortSelectedQuestions();
  }

  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorSelected(
    mp: MatPaginator
  ) {
    this.paginatorSelected = mp;
    if(mp)
    this.setPaginationAndSortSelectedQuestions();
  }

  private paginatorSatisfaction!: MatPaginator;
  private sortSatisfaction!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortSatisfaction(ms: MatSort) {
    this.sortSatisfaction = ms;
    if(ms)
    this.setPaginationAndSortSelectedSatisfaction();
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorSatisfaction(
    mp: MatPaginator
  ) {
    this.paginatorSatisfaction = mp;
    if(mp)
    this.setPaginationAndSortSelectedSatisfaction();
  }

  private paginatorNPS!: MatPaginator;
  private sortNPS!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortNPS(ms: MatSort) {
    this.sortNPS = ms;
    if(ms)
    this.setPaginationAndSortSelectedNPS();
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorNPS(
    mp: MatPaginator
  ) {
    this.paginatorNPS = mp;
    if(mp)
    this.setPaginationAndSortSelectedNPS();
  }

  private paginatorEndPoint!: MatPaginator;
  private sortEndPoint!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortEndPoint(ms: MatSort) {
    this.sortEndPoint = ms;
    if(ms)
    this.setPaginationAndSortSelectedEndPoint();
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorEndPoint(
    mp: MatPaginator
  ) {
    this.paginatorEndPoint = mp;
    if(mp)
    this.setPaginationAndSortSelectedEndPoint();
  }

  private paginatorEmotionalQuestion!: MatPaginator;
  private sortEmotionalQuestion!: MatSort;
  @ViewChild(MatSort, {
    static: false
  }) set matSortEmotionalQuestion(ms: MatSort) {
    this.sortEmotionalQuestion = ms;
    if(ms)
    this.setPaginationAndSortSelectedEmotionalQuestion();
  }
  @ViewChild(MatPaginator, {
    static: false
  }) set matPaginatorEmotionalQuestion(
    mp: MatPaginator
  ) {
    this.paginatorEmotionalQuestion = mp;
    if(mp)
    this.setPaginationAndSortSelectedEmotionalQuestion();
  }

  constructor(
    private store: Store<{ sidebarState: SidebarState }>,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    private userService: UserService,
    private surveyService: SurveyService,
    private toastr: ToastrService,
  ) {
    this.sidebarState$ = store.select("sidebarState");
    this.formatter = new Formatter();

    this.route.params.subscribe(params => {
      this.userService.getUsers().subscribe(data => {
        this.existingUsersAll = data;
        this.existingUsersAll.sort((user1: any, user2: any) => {
          const aValue = user1.name ? user1.name + ' ' + user1.surname : user1.email;
          const bValue = user2.name ? user2.name + ' ' + user2.surname : user2.email;

          return aValue.localeCompare(bValue);
        });
        this.existingUsers = data.filter((user: any) => user.role_name !== 'admin');
      });

      if (params['id']) {
        const request = new GetSurveyRequest(parseInt(params['id']));
        this.surveyService.getSurveyById(request).subscribe(data => {
          this.survey = data;
          const tempQuestions = this.survey.survey.questions;
          tempQuestions.sort((aQuestion: any, bQuestion: any) => aQuestion.domanda_id - bQuestion.domanda_id);
          for (let index = 0; index < tempQuestions.length; index++) {
            this.allQuestions.push(this.getQuestionObject(tempQuestions[index], index));
          }
          this.initForms();
          this.initDataTables();
        });
      }
    })
  }

  ngOnInit(): void {
    this.store.dispatch(refreshSidebar());
    this.sidebarState$.subscribe((sidebarS) => {
      this.currentStepList = sidebarS.editSurveyStepList;
    });
    this.changeStep(0);

    this.stepFourForm.valueChanges.subscribe(form => {
      if (form) {
          const questionsOverviewLength = this.stepFourForm.controls.questionOverviewListForm?.value?.length || 0;
          const questionsJSONLength = this.allQuestions.length;
          this.isValidStepFour = this.stepFourForm.valid && (questionsOverviewLength == questionsJSONLength);
      }
    });
  }

  initDataTables() {
    this.dataSourceQuestions = new MatTableDataSource(this.allQuestions);
    this.dataSelectedQuestions = new MatTableDataSource(this.allQuestions);
    this.dataSelectedSatisfaction = new MatTableDataSource(this.allQuestions);
    this.dataSelectedNPS = new MatTableDataSource(this.allQuestions);
    this.dataSelectedEndPoint = new MatTableDataSource(this.allQuestions);
    this.dataSelectedEmotionalQuestion = new MatTableDataSource(this.allQuestions);
  }

  initForms() {
    // STEP 1 - Client
    for (let index = 0; index < this.survey.users[0].users.length; index++) {
      if (index === 0) {
        this.users.at(0).patchValue({
          userId: this.survey.users[0].users[index].user_id,
        });
      } else {
        this.users.push(this.fb.group({
          id: [index],
          userId: [this.survey.users[0].users[index].user_id, [Validators.required]],
          newUser: this.fb.group({
            newMail: ["", [Validators.required, Validators.email, this.validateUserDoesNotExist(index)]],
            newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
          })
        }));
      }
    }
    this.stepOneForm.patchValue({
      customerName: this.survey.client.full_name ? this.survey.client.full_name : '',
    });

    // STEP 2 - Select survey
    this.croppedImage = this.survey.survey.image_base64;
    this.stepTwoForm.patchValue({
      surveyName: this.survey.survey.name,
      surveyNewName: this.survey.survey.custom_name,
      surveyDescription: this.survey.survey.description,
      surveyFileBase64Cover: this.survey.survey.image_base64,
      surveyFileNameCover: this.survey.survey.custom_name,
    });

    // STEP 3 - Filter questions
    // STEP 4 - Questions overview
    // STEP 5 - Satisfaction
    // STEP 6 - NPS
    // STEP 7 - Satisfaction
    // STEP 8 - Emotional
    for (let index = 0; index < this.survey.touchpoints.length; index++) {
      if (!this.touchPointList.find((tp) => tp.value === this.survey.touchpoints[index])) {
        this.touchPointList.push({value: this.survey.touchpoints[index], viewValue: this.survey.touchpoints[index]});
      }
    }
    for (let index = 0; index < this.allQuestions.length; index++) {
      const question = this.allQuestions[index];
      const questionForm = this.newQuestion(this.allQuestions[index]);

      // STEP 3
      if (question.isFilterQuestion) {
        this.filterQuestionsListForm.push(questionForm);
      }

      // STEP 4
      this.questionOverviewListForm.push(questionForm);

      // STEP 5
      if (question.isSatisfaction) {
        this.addSatisfactionList(question);
      }

      // STEP 6
      if (question.isNps) {
        this.addNPSList(question);
      }

      // STEP 7
      if (question.isEndPoint) {
        this.addEndPointList(question);
      }

      // STEP 8
      if (question.isEmotional) {
        this.addEmotionalQuestionList(question);
      }
    }
    this.isAllSelected = this.filterQuestionsListForm.length === this.allQuestions.length ? true : false;
  }

  getQuestionObject(obj: any, index: number) {
    const answers : any [] = [];
    for (let index = 0; index < obj.answers.length; index++) {
      const tIndex = index;
      const tColor = obj.answers[index].color;
      const tDescription = obj.answers[index].description;
      if (obj.type === 'knob' || obj.type === 'slider') {
        const tMin = obj.answers[index].range_start ? obj.answers[index].range_start : 0;
        const tMax = obj.answers[index].range_end ? obj.answers[index].range_end : 100;
        const xStart = obj.answers[index].x_start;
        const yStart = obj.answers[index].y_start;
        const tUrlImage = obj.answers[index].image_path;
        const tUrlImage2 = obj.answers[index].image_path_2;
        answers.push(new AnswerRange(tIndex,tDescription,tUrlImage, tColor, null, null, tMin, xStart, yStart, tMax, tUrlImage2));
      } else {
        const tUrlImage = obj.answers[index].image_path;
        answers.push(new Answer(tIndex,tDescription,tUrlImage, tColor, null, null));
      }
    }

    return new Question(
      index,
      obj.domanda_id,
      obj.description,
      obj.touch_point,
      obj.type,
      answers,
      null,
      obj.is_filter_question,
      obj.is_question_overview,
      obj.is_satisfaction,
      obj.is_nps,
      obj.is_end_point,
      obj.is_emotional,
      null
    );
  }

  changeStep(idStep: number): void {
    const tempStepsList = this.currentStepList.map((tStep) => {
      tStep = { ...tStep, isActive: false, isCompleted : (idStep > tStep.id)? true : false};

      return tStep;
    });

    const nextStep = tempStepsList.find((tStep) => tStep.id === idStep);
    let index = 0;
    if (nextStep) {
      index = tempStepsList.map((tStep) => tStep.id).indexOf(nextStep?.id);
    }

    if (nextStep) {
      nextStep.isActive = true;
      tempStepsList[index] = nextStep;
      this.store.dispatch(
        setStepEditSurvey({ currentEditSurveyStep: nextStep, editSurveyStepList: tempStepsList })
      );
    }
  }

  onSubmitBackStep(idStep: number): void {
    this.changeStep(idStep - 1);
  }

  onSubmitNextStep(idStep: number): void {
    let isValid = false;
    switch (idStep) {
      case 0:
        const invalidUsers = this.users.value.filter((user: any) => {
          return !(user.userId !== '' || (user.newUser.newMail !== '' && user.newUser.newPassword !== ''));
        });
        if (invalidUsers.length > 0) {
        } else {
          isValid = true;
        }
        break;
      case 1:
        if (this.stepTwoForm.valid) {
          isValid = true;
        }
        break;
      case 2:
        if (this.stepThreeForm.valid) {
          isValid = true;
        }
        break;
      case 3:
        if (this.stepFourForm.valid) {
          isValid = true;
        }
        break;
      case 4:
        if (this.stepFiveForm.valid) {
          isValid = true;
        }
        break;
      case 5:
        if (this.stepSixForm.valid) {
          isValid = true;
        }
      break;
      case 6:
        if (this.stepSevenForm.valid) {
          isValid = true;
        }
        break;
      case 7:
        if (this.stepEightForm.valid) {
          isValid = true;
        }
        break;
      default:
        break;
    }
    if (isValid) {
      if(idStep == 7) {
        this.saveSurveyParams();
      } else {
        this.changeStep(idStep + 1);
      }
    }
  }

  // SAVE SURVEY

  saveSurveyParams () {
    const allUsers = this.getUsersFromUsersListForm();
    const questions = this.getQuestionsFromListForm();
    const users = allUsers.newUsers;
    const userIdsRegistered = allUsers.userIds;
    const customerName = this.stepOneForm.value.customerName || "";
    const surveyName = this.stepTwoForm.value.surveyName || "";
    const surveyCustomName = this.stepTwoForm.value.surveyNewName || "";
    const surveyDescription = this.stepTwoForm.value.surveyDescription || "";
    const surveyFileBase64Cover = this.stepTwoForm.value.surveyFileBase64Cover || "";
    const isEdit = true;
    const params = {
      customerName,
      surveyName,
      surveyDescription,
      surveyCustomName,
      surveyFileBase64Cover,
      users,
      questions,
      userIdsRegistered,
      isEdit,
    }
    // console.log(params);

    this.store.dispatch(saveSurveyParams({ createSurveyParams: params }));
  }

  getUsersFromUsersListForm() {
    const userListForm = this.stepOneForm.value.users;
    let users: any = null;
    if(userListForm) {
      const userIds_full = userListForm.filter((user: any) => user.userId !== '');
      const newUsers_full = userListForm.filter((user: any) => user.newUser.newMail !== '' && user.newUser.newPassword !== '');
      const userIds = userIds_full.map((user: any) => parseInt(user.userId));
      const newUsers = newUsers_full.map((user: any) => (new User(user.newUser.newMail, "", "", user.newUser.newPassword, "")));
      users = {
        userIds: userIds,
        newUsers: newUsers,
      }
    }

    return users;
  }

  getQuestionsFromListForm() {
    const filterQuestionsListFormValue = this.stepThreeForm.value.filterQuestionsListForm as Question[];
    const questionOverviewListFormValue = this.stepFourForm.value.questionOverviewListForm as Question[];
    const satisfactionListFormValue = this.stepFiveForm.value.satisfactionListForm as Question[];
    const npsListFormValue = this.stepSixForm.value.npsListForm as Question[];
    const endPointListFormValue = this.stepSevenForm.value.endPointListForm as Question[];
    const emotionalScoreListFormValue = this.stepEightForm.value.emotionalQuestionListForm as Question[];
    const questions: Question[] =  [];
    if(questionOverviewListFormValue) {
      questionOverviewListFormValue.forEach((tQuestion ) => {
        const isFilterQuestion = this.questionExists(filterQuestionsListFormValue,tQuestion);
        const isQuestionOverview = true;
        const isSatisfaction = this.questionExists(satisfactionListFormValue,tQuestion);
        const isNps = this.questionExists(npsListFormValue,tQuestion);
        const isEndPoint = this.questionExists(endPointListFormValue,tQuestion);
        const isEmotionalQuestion = this.questionExists(emotionalScoreListFormValue,tQuestion);
        questions.push(
          new Question (
            tQuestion.domanda_id,
            tQuestion.domanda_id,
            tQuestion.description,
            tQuestion.touchPoint,
            tQuestion.type,
            tQuestion.answers,
            null,
            isFilterQuestion,
            isQuestionOverview,
            isSatisfaction,
            isNps,
            isEndPoint,
            isEmotionalQuestion,
            ))
      });
    }
    questions.sort((aQuesiton, bQuestion) => aQuesiton.id - bQuestion.id);

    return questions;
  }

  questionExists(arrayList: Question[], question: Question) {
    return arrayList.some((el: any) => {
      return el.description === question.description;
    });
  }

  // PAGINATION

  setPaginationAndSortSourceQuestions() {
    this.dataSourceQuestions.paginator = this.paginatorSource;
    this.dataSourceQuestions.sort = this.sortSource;
  }
  setPaginationAndSortSelectedQuestions() {
    this.dataSelectedQuestions.paginator = this.paginatorSelected;
    this.dataSelectedQuestions.sort = this.sortSelected;
  }
  setPaginationAndSortSelectedSatisfaction() {
    this.dataSelectedSatisfaction.paginator = this.paginatorSatisfaction;
    this.dataSelectedSatisfaction.sort = this.sortSatisfaction;
  }
  setPaginationAndSortSelectedNPS() {
    this.dataSelectedNPS.paginator = this.paginatorNPS;
    this.dataSelectedNPS.sort = this.sortNPS;
  }
  setPaginationAndSortSelectedEndPoint() {
    this.dataSelectedEndPoint.paginator = this.paginatorEndPoint;
    this.dataSelectedEndPoint.sort = this.sortEndPoint;
  }
  setPaginationAndSortSelectedEmotionalQuestion() {
    this.dataSelectedEmotionalQuestion.paginator = this.paginatorEmotionalQuestion;
    this.dataSelectedEmotionalQuestion.sort = this.sortEmotionalQuestion;
  }

  // STEP 1 - Client

  get users() : FormArray {
    return this.stepOneForm.get('users') as FormArray
  }

  onClickCreateNewUser(index: number) {
    this.stepOneForm.controls.users.at(index).controls.userId.patchValue('');
    this.isVisibleAddNewUser[index] = true;
  }

  onClickSelectUserFromList(index: number) {
    this.stepOneForm.controls.users.at(index).controls.newUser.controls.newMail.patchValue('');
    this.stepOneForm.controls.users.at(index).controls.newUser.controls.newPassword.patchValue('');
    this.isVisibleAddNewUser[index] = false;
  }

  onClickAddUser() {
    this.users.push(this.fb.group({
      id: [this.users.length],
      userId: ["", [Validators.required]],
      newUser: this.fb.group({
        newMail: ["", [Validators.required, Validators.email, this.validateUserDoesNotExist(this.users.length)]],
        newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
      })
    }));
  }

  isLastUserValid() {
    const lastIndex = this.users.length - 1;
    if (this.stepOneForm.controls.users.at(lastIndex).controls.userId.valid || (
        this.stepOneForm.controls.users.at(lastIndex).controls.newUser.controls.newMail.valid &&
        this.stepOneForm.controls.users.at(lastIndex).controls.newUser.controls.newPassword.valid)) {
      return true;
    } else {
      return false;
    }
  }

  onClickRemoveUser(user: any) {
    const indexUser = this.users.value.findIndex((tUser: any) => tUser.id === user.id);
    this.users.removeAt(indexUser);

    this.isVisibleAddNewUser.splice(indexUser, 1);
    this.emailAlreadyExists.splice(indexUser, 1);
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  isStepOneValid() {
    const invalidUsers = this.users.controls.filter((user: any) => {
      return !(user.value.userId !== '' || (user.controls.newUser.value.newMail !== '' && user.controls.newUser.controls.newMail.valid && user.controls.newUser.value.newPassword !== '' && user.controls.newUser.controls.newPassword.valid));
    });
    if (invalidUsers.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }

  // STEP 2 - Select survey

  get surveyName() {
    return this.stepTwoForm.get("surveyName");
  }
  get surveyDescription() {
    return this.stepTwoForm.get("surveyDescription");
  }
  get surveyNewName() {
    return this.stepTwoForm.get("surveyNewName");
  }
  get surveyFileSourceCover() {
    return this.stepTwoForm.get("surveyFileSourceCover");
  }
  get surveyFileNameCover() {
    return this.stepTwoForm.get("surveyFileNameCover");
  }
  get surveyFileBase64Cover() {
    return this.stepTwoForm.get("surveyFileBase64Cover");
  }

  toggleCropImage() {
    this.isVisibleCropImage = !this.isVisibleCropImage;
  }

  clearSurveyCover() {
    this.toastr.info("Successfully cleared", "Clear image");
    this.croppedImage = "";
    this.imageChangedEvent = "";
    this.stepTwoForm.controls.surveyFileNameCover.reset();
    this.stepTwoForm.controls.surveyFileBase64Cover.reset();
    this.stepTwoForm.controls.surveyFileSourceCover.reset();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      const fileType = file.name.split('.').pop();
      const allowedTypes = ['jpg', 'jpeg', 'png', 'gif'];
      if (!allowedTypes.includes(fileType)) {
        this.toastr.error("Formato immagine non accettato", "Wrong File Type");
      } else if (fileSize > 0.08) {
        this.toastr.error("Immagine con dimensione massima di 800KB", "Max File Size");
      } else {
        this.stepTwoForm.patchValue({
          surveyFileNameCover: file
        });
        this.toastr.success("Successfully uploaded!", "Upload image");
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.stepTwoForm.patchValue({
        surveyFileBase64Cover: event.base64
      });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  // STEP 3 - Filter questions

  get filterQuestionsListForm() : FormArray {
    return this.stepThreeForm.get("filterQuestionsListForm") as FormArray
  }

  newQuestion(question: Question): FormGroup {
    return this.fb.group({
      id: question.id,
      domanda_id: question.domanda_id,
      description: question.description,
      touchPoint: question.touchPoint,
      type: question.type,
      answers: this.fb.array(question.answers),
    })
  }

  setAllSelected ( isChecked: any) {
    this.isAllSelected = isChecked;
    for (let index = 0; index < this.allQuestions.length; index++) {
      this.removeFilterQuestionList(this.allQuestions[index]);
    }
    for (let index = 0; index < this.allQuestions.length; index++) {
      if (isChecked) {
        this.addFilterQuestionList(this.allQuestions[index]);
      } else if (!isChecked) {
        this.removeFilterQuestionList(this.allQuestions[index])
      }
    }
  }

  addFilterQuestionList(question: Question) {
    this.filterQuestionsListForm.push(this.newQuestion(question));
  }

  removeFilterQuestionList(idQuestion: Question) {
    const tempQuestion = idQuestion.id;
    const index = this.filterQuestionsListForm.value.findIndex((tQuestion: Question) => tQuestion.id === tempQuestion);
    this.filterQuestionsListForm.removeAt(index);
  }

  applyFilterThreeForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceQuestions.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceQuestions.paginator) {
      this.dataSourceQuestions.paginator.firstPage();
    }
  }

  hasBeenCheckedQuestion(question: any) {
    let checked = false;
    checked = this.filterQuestionsListForm.value.some((element: { id: any; }) => {
      if (element.id === question.id) {
        return true;
      }

      return false;
    });

    return checked
  }

  onCheckboxChangeQuestion(event: any, question: Question) {
    if (event.target.checked) {
      this.addFilterQuestionList(question)
    } else {
      this.removeFilterQuestionList(question)
    }
  }

  // STEP 4 - Questions overview

  get questionOverviewListForm() : FormArray {
    return this.stepFourForm.get("questionOverviewListForm") as FormArray
  }
  get newTouchPoint() {
    return this.stepFourForm.get("newTouchPoint");
  }

  onClickAddTouchPoint () {
    const newTP = this.newTouchPoint?.value || "";
    const duplicateTp = this.touchPointList.filter((tp: any) => tp.value === newTP);
    if (newTP && duplicateTp.length === 0) {
      this.touchPointList.push({value: newTP, viewValue: newTP});
      this.newTouchPoint?.patchValue('');
      this.toastr.success("New touchpoint added", "Success");
    } else {
      this.toastr.warning("Touchpoint already present", "Warning");
    }
  }

  applyFilterFourForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSelectedQuestions.filter = filterValue.trim().toLowerCase();
    if (this.dataSelectedQuestions.paginator) {
      this.dataSelectedQuestions.paginator.firstPage();
    }
  }

  onSelectChangePropertyValue(event: {value: any}, propertyName: string, question: Question) {
    const newValue = event.value;
    const tempSelectedQuestion = this.questionOverviewListForm.value.find(((tQuestion : Question)  => tQuestion.id == question.id));
    const tempSelectedIndex = this.questionOverviewListForm.value.findIndex(((tQuestion : Question)  => tQuestion.id == question.id));

    const tempSelectedQuestionAll = this.allQuestions.find(((tQuestion : Question)  => tQuestion.id == question.id));

    if (tempSelectedQuestion) {
      this.questionOverviewListForm.removeAt(tempSelectedIndex);
      tempSelectedQuestion[propertyName] = newValue;
      if (newValue === "close") {
        for (let index = 0; index < tempSelectedQuestion['answers'].length; index++) {
          tempSelectedQuestion['answers'][index].color = 'grey';
        }
      }
      this.questionOverviewListForm.push(this.newQuestion(tempSelectedQuestion));

      if (tempSelectedQuestionAll && propertyName === 'type') {
        tempSelectedQuestionAll.type = newValue;
        if (newValue === "close") {
          for (let index = 0; index < tempSelectedQuestionAll['answers'].length; index++) {
            tempSelectedQuestionAll['answers'][index].color = 'grey';
          }
        }
      }
    } else {
      const tempSourceQuestion = this.allQuestions.find(((tQuestion : Question)  => tQuestion.id == question.id));
      if (tempSourceQuestion) {
        tempSourceQuestion.type = newValue;
        if (newValue === "close") {
          for (let index = 0; index < tempSourceQuestion['answers'].length; index++) {
            tempSourceQuestion['answers'][index].color = 'grey';
          }
        }
        this.questionOverviewListForm.push(this.newQuestion(tempSourceQuestion));
      }
    }
  }

  onSelectChangeAnswerColor(color:string, index:number, question: Question) {
    const tempSelectedQuestion = this.questionOverviewListForm.value.find(((tQuestion : Question)  => tQuestion.id == question.id));
    const tempSelectedIndex = this.questionOverviewListForm.value.findIndex(((tQuestion : Question)  => tQuestion.id == question.id));
    if (tempSelectedQuestion) {
      this.questionOverviewListForm.removeAt(tempSelectedIndex);
      tempSelectedQuestion['answers'][index].color = color;
      this.questionOverviewListForm.push(this.newQuestion(tempSelectedQuestion));
    } else {
      const tempSourceQuestion = this.allQuestions.find(((tQuestion : Question)  => tQuestion.id == question.id));
      if(tempSourceQuestion) {
        tempSourceQuestion['answers'][index].color = color;
        this.questionOverviewListForm.push(this.newQuestion(tempSourceQuestion));
      }
    }
  }

  onChangeUpdateRangeValue(event: any, index: number, question: Question, property: string) {
    const tempSelectedQuestion = this.questionOverviewListForm.value.find(((tQuestion : Question)  => tQuestion.id == question.id));
    const tempSelectedIndex = this.questionOverviewListForm.value.findIndex(((tQuestion : Question)  => tQuestion.id == question.id));
    if (tempSelectedQuestion) {
      this.questionOverviewListForm.removeAt(tempSelectedIndex);
      tempSelectedQuestion['answers'][index][property] = parseInt(event.target.value);
      this.questionOverviewListForm.push(this.newQuestion(tempSelectedQuestion));
    } else {
      const tempSourceQuestion = this.allQuestions.find(((tQuestion : Question)  => tQuestion.id == question.id));
      if(tempSourceQuestion) {
        tempSourceQuestion['answers'][index][property] = parseInt(event.target.value);
        this.questionOverviewListForm.push(this.newQuestion(tempSourceQuestion));
      }
    }
  }

  returnPropertyValueIfExist(propertyName: string, question: Question) {
    const tempSelectedQuestion = this.questionOverviewListForm.value.find(((tQuestion : Question)  => tQuestion.id == question.id));
    if (tempSelectedQuestion) {
      const propertyValue = tempSelectedQuestion[propertyName];

      return propertyValue
    }

    return null
  }

  isValidKnobAndSlider() {
    let valid = true;
    const knobSliderQuestions = this.questionOverviewListForm.value.filter((question: Question) => question.type === 'knob' || question.type === 'slider');
    knobSliderQuestions.forEach((question: Question) => {
      question.answers.forEach((answer: AnswerRange) => {
        if (
          !answer.x_start || !answer.y_start ||   // x_start or y_start are not compiled
          answer.x_start < answer.range_start ||  // x_start is less than range start
          answer.x_start > answer.range_end ||    // x_start is greater than range end
          answer.y_start < answer.range_start ||  // y_start is less than range start
          answer.y_start > answer.range_end ||    // y_start is greater than range end
          answer.x_start >= answer.y_start        // x_start is equal to or greater than y_start
        ) {
          valid = false;
        }
      });
    });

    return valid;
  }

  // STEP 5 - Satisfaction

  get satisfactionListForm() : FormArray {
    return this.stepFiveForm.get("satisfactionListForm") as FormArray
  }

  applyFilterFiveForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSelectedSatisfaction.filter = filterValue.trim().toLowerCase();
    if (this.dataSelectedSatisfaction.paginator) {
      this.dataSelectedSatisfaction.paginator.firstPage();
    }
  }

  hasBeenCheckedSatisfaction(question: any) {
    let checked = false;
    checked = this.satisfactionListForm.value.some((element: { id: any; }) => {
      if (element.id === question.id) {
        return true;
      }

      return false;
    });

    return checked
  }

  onCheckboxChangeSatisfaction(event: any, question: Question) {
    for (let index = 0; index < this.satisfactionListForm.length; index++) {
      this.removeSatisfactionList(this.allQuestions[index]);
    }
    if (event.target.checked) {
      this.addSatisfactionList(question)
    } else {
      this.removeSatisfactionList(question)
    }
  }

  addSatisfactionList(question: Question) {
    this.satisfactionListForm.push(this.newQuestion(question));
  }

  removeSatisfactionList(idQuestion: Question) {
    const tempQuestion = idQuestion.id;
    const index = this.satisfactionListForm.value.findIndex((tQuestion: Question) =>tQuestion.id ===tempQuestion);
    this.satisfactionListForm.removeAt(index);
  }

  // STEP 6 - NPS

  get npsListForm() : FormArray {
    return this.stepSixForm.get("npsListForm") as FormArray
  }

  applyFilterSixForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSelectedNPS.filter = filterValue.trim().toLowerCase();
    if (this.dataSelectedNPS.paginator) {
      this.dataSelectedNPS.paginator.firstPage();
    }
  }

  hasBeenCheckedNPS(question: any) {
    let checked = false;
    checked = this.npsListForm.value.some((element: { id: any; }) => {
      if (element.id === question.id) {
        return true;
      }

      return false;
    });

    return checked
  }

  onCheckboxChangeNPS(event: any, question: Question) {
    for (let index = 0; index < this.npsListForm.length; index++) {
      this.removeNPSList(this.allQuestions[index]);
    }
    if (event.target.checked) {
      this.addNPSList(question)
    } else {
      this.removeNPSList(question)
    }
  }

  addNPSList(question: Question) {
    this.npsListForm.push(this.newQuestion(question));
  }

  removeNPSList(idQuestion: Question) {
    const tempQuestion = idQuestion.id;
    const index = this.npsListForm.value.findIndex((tQuestion: Question) => tQuestion.id === tempQuestion);
    this.npsListForm.removeAt(index);
  }

  // STEP 7 - End point

  get endPointListForm() : FormArray {
    return this.stepSevenForm.get("endPointListForm") as FormArray
  }

  applyFilterSevenForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSelectedEndPoint.filter = filterValue.trim().toLowerCase();
    if (this.dataSelectedEndPoint.paginator) {
      this.dataSelectedEndPoint.paginator.firstPage();
    }
  }

  hasBeenCheckedEndPoint(question: any) {
    let checked = false;
    checked = this.endPointListForm.value.some((element: { id: any; }) => {
      if (element.id === question.id) {
        return true;
      }

      return false;
    });

    return checked
  }

  onCheckboxChangeEndPoint(event: any, question: Question) {
    for (let index = 0; index < this.endPointListForm.length; index++) {
      this.removeEndPointList(this.allQuestions[index]);
    }
    if (event.target.checked) {
      this.addEndPointList(question)
    } else {
      this.removeEndPointList(question)
    }
  }

  addEndPointList(question: Question) {
    this.endPointListForm.push(this.newQuestion(question));
  }

  removeEndPointList(idQuestion: Question) {
    const tempQuestion = idQuestion.id;
    const index = this.endPointListForm.value.findIndex((tQuestion: Question) => tQuestion.id === tempQuestion);
    this.endPointListForm.removeAt(index);
  }

  // STEP 8 - Emotional

  get emotionalQuestionListForm() : FormArray {
    return this.stepEightForm.get("emotionalQuestionListForm") as FormArray
  }

  applyFilterEightForm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSelectedEmotionalQuestion.filter = filterValue.trim().toLowerCase();
    if (this.dataSelectedEmotionalQuestion.paginator) {
      this.dataSelectedEmotionalQuestion.paginator.firstPage();
    }
  }

  hasBeenCheckedEmotionalQuestion(question: any) {
    let checked = false;
    checked = this.emotionalQuestionListForm.value.some((element: { id: any; }) => {
      if (element.id === question.id) {
        return true;
      }

      return false;
    });

    return checked
  }

  onCheckboxChangeEmotionalQuestion(event: any, question: Question) {
    for (let index = 0; index < this.emotionalQuestionListForm.length; index++) {
      this.removeEmotionalQuestionList(this.allQuestions[index]);
    }
    if (event.target.checked) {
      this.addEmotionalQuestionList(question)
    } else {
      this.removeEmotionalQuestionList(question)
    }
  }

  addEmotionalQuestionList(question: Question) {
    this.emotionalQuestionListForm.push(this.newQuestion(question));
  }

  removeEmotionalQuestionList(idQuestion: Question) {
    const tempQuestion = idQuestion.id;
    const index = this.emotionalQuestionListForm.value.findIndex((tQuestion: Question) =>tQuestion.id ===tempQuestion);
    this.emotionalQuestionListForm.removeAt(index);
  }

}
