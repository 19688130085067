import { EcommerceTrigger } from "./global.request";

export class Ecommerce {
  public configId: number;
  public users: any;
  public userProject: string;
  public triggers: EcommerceTrigger[];
  public ecommerceType: string;
  public surveyLink: string;
  public coverDesktop: any;
  public coverMobile: any;
  public surveyDimension: string;
  public backgroundColor: string;
  public closeColor: string;
  public maxDisplay: number;
  public hashFile: string;
  public publicationDate: Date;
  public stat: number;
  constructor(configId: number, users: any, userProject: string, triggers: EcommerceTrigger[], ecommerceType: string, surveyLink: string, coverDesktop: any, coverMobile: any, surveyDimension: string, backgroundColor: string, closeColor: string, maxDisplay: number, hashFile: string, publicationDate: Date, stat: number) {
    this.configId = configId;
    this.users = users;
    this.userProject = userProject;
    this.triggers = triggers;
    this.ecommerceType = ecommerceType;
    this.surveyLink = surveyLink;
    this.coverDesktop = coverDesktop;
    this.coverMobile = coverMobile;
    this.surveyDimension = surveyDimension;
    this.backgroundColor = backgroundColor;
    this.closeColor = closeColor;
    this.maxDisplay = maxDisplay;
    this.hashFile = hashFile;
    this.publicationDate = publicationDate;
    this.stat = stat;
  }
}
