export class Question {
  public id: number;
  public domanda_id: number;
  public description: string;
  public touchPoint: string;
  public type: string;
  public answers: any[];
  public counters: any[]| null;
  public isFilterQuestion: boolean | null;
  public isQuestionOverview: boolean | null;
  public isSatisfaction: boolean | null;
  public isNps: boolean | null;
  public isEndPoint: boolean | null;
  public isEmotional: boolean | null;
  public media: any[] | null;
  constructor (
    id: number,
    domanda_id: number,
    description: string,
    touchPoint: string,
    type: string,
    answers: any[],
    counters: any[]| null,
    isFilterQuestion: boolean | null,
    isQuestionOverview: boolean| null,
    isSatisfaction: boolean| null,
    isNps: boolean| null,
    isEndPoint: boolean| null,
    isEmotional: boolean | null,
    media: any[] | null = null
    ) {
    this.id = id;
    this.domanda_id = domanda_id;
    this.description = description;
    this.touchPoint = touchPoint;
    this.type = type;
    this.answers = answers;
    this.counters = counters;
    this.isFilterQuestion = isFilterQuestion;
    this.isQuestionOverview = isQuestionOverview;
    this.isSatisfaction = isSatisfaction;
    this.isNps = isNps;
    this.isEndPoint = isEndPoint;
    this.isEmotional = isEmotional;
    this.media = media;
  }
}
