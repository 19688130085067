<form class="base--form-generic" [formGroup]="uploadFileForm" (ngSubmit)="onSubmitUploadFile()" enctype="multipart/form-data">
  <div class="form-group">
    <label for="startPeriod">Upload csv</label>
    <div class="form-row">
      <!-- <span>User</span> -->
      <div class="input-wrapper">
        <input
        type="file"
        name="import-csv"
        (change)="onFileChange($event)" />
      </div>


    </div>
  </div>
  <div class="form-actions">
    <!-- <div class="btn-back"> Indietro </div> -->
    <button type="submit" class="btn-next" > Upload </button>
  </div>
</form>
