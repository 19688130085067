import { CreateUserResponse } from './../../models/global.response';
import { CreateSurveyRequest, CreateUserRequest } from './../../models/global.request';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  createUser,
  createUserSuccess,
  createUserFailure,

} from './users.actions';

import { AuthenticationService } from '../../services/auth/authentication.service';

import { ROUTES } from '../../utils/constants'
import { UserService } from 'src/app/services/user/user.service';
import { createSurvey, saveSurveyParams } from '../survey/survey.actions';
import { Store } from '@ngrx/store';
import { SurveyState } from '../survey/survey.reducer';


@Injectable()
export class CreateUserEffects {

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSurveyParams),
      map((action) => action.createSurveyParams),
      exhaustMap((req: any) => {

        return this.userService.createUser(req).pipe(
          map((res) =>createUserSuccess({userIds: res.userIds, createSurveyParams: req})
        ),
          catchError((error) => of(createUserFailure({ error })))
        )}
      )
    )
  );

  createUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createUserSuccess),
        tap((data) => {
          const request : CreateSurveyRequest = {
            userIds: data.userIds,
            userIdsRegistered: data.createSurveyParams.userIdsRegistered,
            customerName: data.createSurveyParams.customerName,
            surveyName: data.createSurveyParams.surveyName,
            surveyCustomName: data.createSurveyParams.surveyCustomName,
            surveyDescription: data.createSurveyParams.surveyDescription,
            surveyFileBase64Cover: data.createSurveyParams.surveyFileBase64Cover,
            questions: data.createSurveyParams.questions,
            isEdit: data.createSurveyParams.isEdit,
          }
          this.store.dispatch(createSurvey({request}));
        })
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router,
    private store: Store<{ surveyState: SurveyState}>
  ) {}
}
