<div id="login-wrapper">
  <form class="base--form-generic row" [formGroup]="signInForm" (ngSubmit)="onSubmitSignIn()">
    <div class="form-group col-md-3">
      <label for="email">Email</label>
      <input type="email"
      email="true"
      class="form-control" formControlName="email" placeholder="Enter email" required autofocus ngModel>
    </div>
    <div class="form-group col-md-3">
      <label for="password">Password</label>
      <input type="password" class="form-control" formControlName="password"  placeholder="Password" required ngModel>
    </div>
    <div class="form-group form-check col-md-3">
      <a [routerLink]="['/reset-password']" >Forgot Password</a>
    </div>
    <button type="submit" class="base--btn-generic col-md-3">Login </button>
  </form>
</div>
