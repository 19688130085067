
import { CreateSurveyComponent } from './components/create-survey/create-survey.component';
import { SelectSurveyComponent } from './components/select-survey/select-survey.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';
import { ImportSurveyFilledComponent } from './components/import-survey-filled/import-survey-filled.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../services/auth/authorization.guards';
import { EditSurveyComponent } from './components/edit-survey/edit-survey.component';

const routes: Routes = [
  {path: 'create-survey', component: CreateSurveyComponent,
  canActivate: [AuthGuard], data: {
    role: 'admin'
  }},
  {path: 'edit-survey/:id', component: EditSurveyComponent,
  canActivate: [AuthGuard], data: {
    role: 'admin'
  }},
  {path: 'select-survey', component: SelectSurveyComponent,
  canActivate: [AuthGuard]},
  {path: 'select-period', component: SelectPeriodComponent,
  canActivate: [AuthGuard]},
  {path: 'import-survey-filled', component: ImportSurveyFilledComponent,
  canActivate: [AuthGuard], data: {
    role: 'admin'
  }},
  {path: '', redirectTo:'/select-survey', pathMatch : 'full',},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]

})
export class SurveyRoutingModule { }
