export class Answer {
  public id: number;
  public description: string;
  public urlImage: string;
  public color: string;
  public countPartial: any | null;
  public count: any | null;

  constructor (id: number, description: string, urlImage: string, color: string, countPartial: any, count: any) {
    this.id = id;
    this.description = description;
    this.urlImage = urlImage;
    this.color = color;
    this.countPartial = countPartial;
    this.count = count;
  }
}

export class AnswerRange extends Answer {
  public range_start: number;
  public x_start: number | null;
  public y_start: number | null;
  public range_end: number;
  public urlImage2: string;

  constructor(id: number, description: string, urlImage: string, color: string, countPartial: any, count: any, range_start: number, x_start: number | null, y_start: number | null, range_end: number, urlImage2: string) {
    super(id, description, urlImage, color, countPartial, count);
    this.range_start = range_start;
    this.x_start = x_start;
    this.y_start = y_start,
    this.range_end = range_end;
    this.urlImage2 = urlImage2;
  }
}
