import { SidebarReducer } from './sidebar/sidebar.reducer';
import { AuthenticationReducer } from  './auth/authentication.reducer';
import { SurveyReducer } from  './survey/survey.reducer';
import { NavbarReducer } from  './navbar/navbar.reducer';
import { CreateUserReducer } from  './users/users.reducer';
import { EcommerceReducer } from './ecommerce/ecommerce.reducer';

// import { RouterReducer } from  './router/router.reducer';


import { MetaReducer } from "@ngrx/store";
import { HydrationMetaReducer } from "./hydratation/hydratation.reducer";


export const reducers= {
  sidebarState: SidebarReducer,
  authState: AuthenticationReducer,
  surveyState: SurveyReducer,
  navbarState: NavbarReducer,
  createUserState: CreateUserReducer,
  ecommerceState: EcommerceReducer,
};
export const metaReducers: MetaReducer[] = [HydrationMetaReducer];


