
//Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//Components e Modules
import { AppComponent } from './app.component';
import { AuthModule } from './pages/auth/auth.module';
import { SurveyModule } from './pages/survey/survey.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { EcommerceModule } from './pages/ecommerce/ecommerce.module';
import { SharedModule } from './shared/shared.module';


import { RouterModule } from '@angular/router';

import { reducers, metaReducers} from './stores/global.reducers';
import { effects } from './stores/global.effects';
//Interceptors

import { JwtInterceptor } from './helpers/jwt.interceptor';
import { HttpErrorInterceptor } from './helpers/httperror.interceptor';
import { LoadingInterceptor } from './helpers/loading.interceptor';

//Ngrx Store
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    SurveyModule,
    DashboardModule,
    EcommerceModule,
    SharedModule,
    AuthModule,
    StoreModule.forRoot(reducers,{metaReducers}),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      autoPause: true,
    }),
    EffectsModule.forRoot(effects),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
