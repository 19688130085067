import { GetSurveyRequest } from './../../models/global.request';
import { Survey } from 'src/app/models/Survey';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { Period } from 'src/app/models/Period';
import { CreateSurveyRequest } from 'src/app/models/global.request';
import { User } from 'src/app/models/User';
import { Question } from 'src/app/models/Question';

// export const toggle = createAction('[Sidebar Component] Toggle');


export const createSurveySuccess = createAction(
  '[Create Survey] Create Survey Success',
  props<{ request: CreateSurveyRequest, response: any }>()
);

export const createSurveyFailure = createAction(
  '[Create Survey] Create Survey Failure',
  props<{ error: any }>()
);

export const createSurvey = createAction(
  '[Create Survey] createSurvey',
  props<{ request: CreateSurveyRequest }>()
);


export const saveSurveyParams = createAction(
  '[Create Survey] Save Questions',
  props<{  createSurveyParams: any }>()
);

export const setSurveyList = createAction('[Survey Store] Set Survey list',
    props<{ surveyList: Survey[]}>()
);

export const setSurvey = createAction('[Survey Store] Change Survey, Update Survey list',
    props<{ currentSurvey: Survey , surveyList: Survey[]}>()
);

export const setPeriods = createAction('[Survey Store] Set Periods',
    props<{ periodList: Period[]}>()
);

export const setPeriodsCounters = createAction('[Survey Store] Set Periods Counters',
    props<{ periodsCounters: any}>()
);

export const getSurveyByIdSuccess = createAction(
  '[Get Survey] Get Survey Success',
  props<{ filterQuestions: any, touchpoints: any, questions: any }>()
);

export const getSurveyByIdFailure = createAction(
  '[Get Survey] Get Survey Failure',
  props<{ error: any }>()
);
export const getSurveyById = createAction(
  '[Get Survey] getSurveyById',
  props<{ request: GetSurveyRequest }>()
);
