import { User } from '../../models/User';

import { CreateUserRequest} from '../../models/global.request';


import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AuthState, getIsAuthenticated } from '../../stores/auth/authentication.reducer';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  authState$: Observable<AuthState>;
  isAuthenticated !: boolean;
  reqHeader!:HttpHeaders;
  private serviceContainer = "users";
  constructor(
    private http: HttpClient,
    private store: Store<{ authState: AuthState }>,
    ) {
      this.authState$ = store.select('authState');
  }

  public createUser(request: CreateUserRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}create-user`, request);
  }

  public getUsers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.serviceContainer}/get-list`);
  }
}
