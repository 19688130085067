import { User} from '../../models/User';
import { Action, createReducer, on } from '@ngrx/store';
import {
  createUser,
  createUserSuccess,
  createUserFailure,

} from './users.actions';


export const initialState: CreateUserState = {
  userIds: null,
  errorMessage: null,
  error: null,
  pending: false
};

export interface CreateUserState {
  userIds: [] | null;
  errorMessage: string | null;
  error: string | null;
  pending: boolean;
}
export const CreateUserReducer = createReducer(
  initialState,
  on(createUser, (state) => { return ({
    ...state,
    error: null,
    pending: true,
  })}),
  on(createUserSuccess, (state, { userIds, createSurveyParams }) => ({
    ...state,
    userIds: userIds,
    createSurveyParams: createSurveyParams,
    error: null,
    pending: false,
  })),
  on(createUserFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  }))
);
export const getError = (state: CreateUserState) => state.error;
export const getPending = (state: CreateUserState) => state.pending;
export const getUsersId = (state: CreateUserState) => state.userIds;
